import React, { useEffect } from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import { selectFiles } from '../../../store/selectors/fileSelectors';
import theme, { dashboardCompanyImageSize } from '../../../theme';
import { calculateTotalPay } from '../../../utils/Candidate/payHelpers';
import { getFile } from '../../../views/app/fileReducer';
import CorporationLogo from '../../CorporationLogo';

const SquareJobCardBrief = ({ itemClickHandler = () => {}, data }) => {
  const { _id, title, corporation, payRateReg, totalPay } = data;
  const corporationId = get('_id', corporation);
  const corporationName = get('name', corporation);
  const logo = get('logo', corporation);
  const dispatch = useDispatch();

  const componentsTheme = get(['candidate', 'components'], theme);

  const files = useSelector(selectFiles);
  const file = get([`${logo}`], files);
  useEffect(() => {
    if (!file && !file?.isLoading && logo && logo !== null) {
      dispatch(getFile({ id: logo }));
    }
  }, [dispatch, logo, files, file]);

  const sxForCard = {
    padding: theme.spacing(1, 3, 1, 3),
    marginBottom: theme.spacing(1),
    backgroundColor: get(['shiftGroupCard', 'backgroundColor', 'default'], componentsTheme),
    borderRadius: theme.spacing(2),
    width: '49%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const sxForTitle = {
    color: get(['jobCard', 'headers', 'fontColor'], componentsTheme),
    fontFamily: get(['jobCard', 'headers', 'font'], componentsTheme),
    fontSize: '16px',
    margin: theme.spacing(1, 0, 1, 0),
  };
  const sxForTotal = {
    color: get(['shiftGroupCard', 'headers', 'fontColor'], componentsTheme),
    fontFamily: get(['shiftGroupCard', 'headers', 'font'], componentsTheme),
    fontSize: '16px',
  };
  const sxForCorporationName = {
    color: get(['jobCard', 'subtext', 'fontColor'], componentsTheme),
    fontFamily: get(['jobCard', 'subtext', 'font'], componentsTheme),
    fontSize: '12px',
  };
  const sxForSubtext = {
    color: get(['jobCard', 'subtext', 'fontColor'], componentsTheme),
    fontFamily: get(['jobCard', 'subtext', 'font'], componentsTheme),
    fontSize: '12px',
  };

  return (
    <Box sx={sxForCard} key={_id} onClick={() => itemClickHandler(data)}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <CorporationLogo
          file={file}
          corporationId={corporationId}
          style={{
            height: dashboardCompanyImageSize.height,
            width: dashboardCompanyImageSize.width,
            marginRight: theme.spacing(1),
          }}
        />
        <Typography sx={sxForCorporationName}>{corporationName}</Typography>
      </Box>

      <Typography sx={sxForTitle}>{title}</Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <Box>
          <Typography sx={sxForTotal}>{calculateTotalPay(totalPay)}</Typography>
          <Typography sx={sxForSubtext}>${payRateReg}/hr</Typography>
        </Box>
      </Box>
    </Box>
  );
};

SquareJobCardBrief.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    positionTemplate: PropTypes.string,
    title: PropTypes.string,
    corporation: PropTypes.shape({}),
    location: PropTypes.shape({}),
    start: PropTypes.number,
    startDate: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    end: PropTypes.number,
    totalPay: PropTypes.shape({}),
    payRateReg: PropTypes.number,
    imageURL: PropTypes.string,
  }),
  itemClickHandler: PropTypes.func,
  user: PropTypes.shape({}),
};

export default SquareJobCardBrief;
