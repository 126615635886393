import React from 'react';
import PropTypes from 'prop-types';

import { linearProgressClasses } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { BLACK, PRIMARY_PURPLE } from '../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../theme/fontConstants';

const FillRatioLinearProgress = styled(LinearProgress)(() => ({
  borderRadius: '8px',
  height: 11,

  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: BLACK[30],
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: PRIMARY_PURPLE[70],
    borderRadius: '8px',
  },
}));

const FillRatioCell = (props) => {
  const { value } = props;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <FillRatioLinearProgress variant="determinate" value={value < 100 ? value : 100} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography sx={{ fontFamily: PRIMARY_FONT[400] }} variant="body2">{`${
          Math.floor(value * 100) / 100
        }%`}</Typography>
      </Box>
    </Box>
  );
};

FillRatioCell.propTypes = {
  value: PropTypes.number,
};
export default FillRatioCell;
