import React, { useEffect, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import searchParamOptions from '@careerstart/wae-common/src/main/constants/searchParams';
import UserRole from '@careerstart/wae-common/src/main/constants/user-role';

import AppTraining from '../../components/AppTraining';
import EmployeeNavigation from '../../components/Candidate/Navigation/EmployeeNavigation';
import NotReadyToWork from '../../components/Candidate/NotReadyToWork';
import GeolocationDemo from '../../components/geolocation/GeolocationDemo';
import Snackbar from '../../components/Snackbar';
import selectUser, { selectIsProcessingAppTraining } from '../../store/selectors/appSelector';
import { getItemFromLocalStorage, LOCAL_STORAGE_KEYS } from '../../store/utils';
import { setLanguage } from '../../utils/internationalization';
import launchDarklyToggles from '../../utils/launchDarklyToggles';
import { history } from '../../utils/navigator';
import Account from '../account/Account';
import Dashboard from '../Candidate/dashboard';
import AllJobsList from '../Candidate/jobs/AllJobsList';
import MyJobs from '../Candidate/jobs/MyJobs';
import MobileDirect from '../Candidate/mobileDirect';
// import EmployeeNavigation from '../Candidate/EmployeeNavigation';
import Payment from '../Candidate/payment/Payment';
import TimeTracking from '../Candidate/timeTracking';
import { isCandidateReadyToWork } from '../Candidate/utils';
import CorporationList from '../corporations/corporationList/CorporationList';
import CreateCorporation from '../corporations/createCorporation/CreateCorporation';
import ForgotMyPassword from '../forgotMyPassword';
import JobsList from '../jobs/jobList/JobsList';
import LongTermJobsList from '../jobs/jobList/LongTermJobsList';
import Login from '../login';
import MaintenancePage from '../maintenance';
import MobileDownloadPage from '../MobileDownloadPage/MobileDownloadPage';
import Notification from '../notification/Notification';
import PasswordUpdate from '../passwordUpdate';
import PasswordVerificationOrResetPage from '../PasswordVerificationOrReset/PasswordVerificationOrResetPage';
import PositionTemplateList from '../positionTemplate/positionTemplateList/PositionTemplateList';
import Register from '../register/Register';
import Reporting from '../reporting';
import Settings from '../settings';
import SkillsAndCerts from '../skillsAndCerts';
import TimekeepingGridView from '../timekeeping/timekeepingGridView/TimekeepingGridView';
import UserList from '../users/userList/UserList';

import Navigation from './Navigation';
import { updateUserData } from './reducer';

const AppRouter = ({ flags }) => {
  const [dictData, setDictData] = useState({});
  const user = useSelector(selectUser);
  const isProcessingAppTraining = useSelector(selectIsProcessingAppTraining);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      const userData = getItemFromLocalStorage(LOCAL_STORAGE_KEYS.userDataKey);

      if (userData) {
        dispatch(updateUserData(userData));
        // TODO: (TICKET PENDING) Longer term, we'll want just the token and we'll do a lookup to get the
        // rest of the user data if it is a valid token. That way, either the token is invalid and
        // user is forced to reauth, or the token is valid and we get fresh data for the user.
      }
    }
  }, [dispatch, user]);

  const isAdminMetricsPageEnabled = launchDarklyToggles(flags, 'isAdminMetricsPageEnabled');
  const maintenance = launchDarklyToggles(flags, 'isMaintenanceModeEnabled');
  const timesheetToggle = launchDarklyToggles(flags, 'isTimesheetEnabled');
  const isCandidateUIDisabled = launchDarklyToggles(flags, 'isCandidateWebUiDisabled');
  const token = get('token', user);
  const role = token && get('role', user);
  const { ADMIN, EMPLOYER, RECRUITER, CANDIDATE } = UserRole;
  const isAdmin = role === ADMIN;
  const isEmployer = role === EMPLOYER;
  const isRecruiter = role === RECRUITER;
  const isEmployee = role === CANDIDATE;

  // Set the language in the internationalization file
  useEffect(() => {
    setLanguage(setDictData);
  }, []);

  if (Object.keys(dictData).length !== 0) {
    return (
      <IntlProvider messages={dictData} locale="en" defaultLocale="en">
        <HistoryRouter history={history}>
          <Snackbar />
          {token && <AppTraining user={user} loading={isProcessingAppTraining} />}
          {maintenance ? (
            <MaintenancePage />
          ) : (
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route
                path="accountverification/:queryinformation"
                element={<PasswordVerificationOrResetPage type="accountVerification" />}
              />
              <Route
                path="passwordReset/:queryinformation"
                element={<PasswordVerificationOrResetPage type="passwordReset" />}
              />
              <Route path="forgotMyPassword" element={<ForgotMyPassword />} />

              {/* Admin, Employer, Recruiter View */}
              {(isAdmin || isEmployer || isRecruiter) && (
                <Route element={<Navigation role={role} />}>
                  <Route path="dashboard" element={<JobsList />} />
                  <Route path="geolocation" element={<GeolocationDemo />} />

                  {!isEmployer && (
                    <Route path="corporations/corporationList" element={<CorporationList />} />
                  )}
                  {isAdmin && (
                    <Route path="corporations/createCorporation" element={<CreateCorporation />} />
                  )}
                  {isAdmin && <Route path="skillsAndCerts" element={<SkillsAndCerts />} />}
                  {isAdmin && isAdminMetricsPageEnabled && (
                    <Route path="reporting" element={<Reporting />} />
                  )}
                  {!isEmployer && <Route path="users/usersList" element={<UserList />} />}

                  <Route path="settings" element={<Settings />} />
                  <Route
                    path={`jobs/jobsList/:${searchParamOptions.JOB_ORDER}?/:${searchParamOptions.START}?/:${searchParamOptions.ACTIVE_TAB}?`}
                    element={<JobsList />}
                  />
                  <Route path="jobs/longTermJobsList" element={<LongTermJobsList />} />

                  <Route path="jobs/positionTemplateList" element={<PositionTemplateList />} />
                  {timesheetToggle && (
                    <Route
                      path={`timekeeping/:${searchParamOptions.START}?/:${searchParamOptions.CANDIDATE}?/:${searchParamOptions.PLACEMENT}?/:${searchParamOptions.DATE}?`}
                      element={<TimekeepingGridView />}
                    />
                  )}
                </Route>
              )}

              {/* Employee View */}
              {isEmployee && isCandidateUIDisabled ? (
                <Route path="dashboard" element={<MobileDirect />} />
              ) : (
                isEmployee && (
                  <Route element={<EmployeeNavigation />}>
                    <Route
                      path="dashboard"
                      element={
                        isCandidateReadyToWork(user) ? (
                          <Dashboard />
                        ) : (
                          <Navigate replace to="/verification" />
                        )
                      }
                    />
                    {isCandidateReadyToWork(user) && (
                      <>
                        <Route
                          path={`jobs/jobsList/:${searchParamOptions.JOB_ORDER}?/:${searchParamOptions.JOB_TYPE}?/:${searchParamOptions.PLACEMENT}?/:${searchParamOptions.INVITED}?/:${searchParamOptions.LOCATION}?/:${searchParamOptions.POSITION}?/:${searchParamOptions.SHIFT_GROUP}?`}
                          element={<AllJobsList />}
                        />
                        <Route
                          path={`jobs/myJobs/:${searchParamOptions.PLACEMENT}?`}
                          element={<MyJobs />}
                        />
                        <Route path="payment" element={<Payment />} />
                        <Route
                          path={`timekeeping/:${searchParamOptions.START}?/:${searchParamOptions.PLACEMENT}?`}
                          element={<TimeTracking />}
                        />
                        <Route path="notification" element={<Notification />} />
                      </>
                    )}
                    <Route path="settings" element={<Settings />} />
                    <Route path="account/passwordUpdate" element={<PasswordUpdate />} />
                    <Route path="account" element={<Account />} />
                    <Route path="verification" element={<NotReadyToWork />} />
                  </Route>
                )
              )}
              <Route path="employee-mobile-app-info" element={<MobileDownloadPage />} />
              <Route path="*" element={<Login />} />
            </Routes>
          )}
        </HistoryRouter>
      </IntlProvider>
    );
  }
  return null;
};

AppRouter.propTypes = {
  flags: PropTypes.shape({}),
};

export default withLDConsumer()(AppRouter);
