import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { DatePicker as ResponsiveDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import theme from '../../theme';
import { PRIMARY_FONT } from '../../theme/fontConstants';

const DatePickerField = ({
  error,
  fullWidth,
  label,
  helperText,
  minDate,
  mode,
  name,
  onChange,
  readOnly,
  renderInput,
  required,
  sx,
  value,
  variant,
}) => {
  let backgroundColor;
  let fontFamily;
  let dateFontColor;
  let dayFontSize;
  let dateFontSize;
  let dateBackgroundColor;
  let dayFontColor;
  let selectedBackgroundColor;
  let headerFontSize;
  let headerFontFamily;
  let textFieldBackground;
  let textFieldBackgroundHover;
  let textFieldFocusedBorder;
  let textFieldErrorBorder;
  let textFieldPrimaryLabel;
  let iconButtonColor;
  switch (mode) {
    case 'dark':
      // FIXME: Replace when dark mode colors are out
      backgroundColor = get(['datePicker', 'bgColor', 'light'], theme);
      dateBackgroundColor = get(['datePicker', 'dateBgColor', 'light'], theme);
      selectedBackgroundColor = get(['datePicker', 'selected', 'backgroundColor', 'light'], theme);
      fontFamily = get(['datePicker', 'font', 'defaultFont'], theme);
      dateFontColor = get(['datePicker', 'font', 'date', 'fontColor'], theme);
      dateFontSize = get(['datePicker', 'font', 'date', 'fontSize'], theme);
      dayFontColor = get(['datePicker', 'font', 'day', 'fontColor'], theme);
      dayFontSize = get(['datePicker', 'font', 'day', 'fontSize'], theme);
      textFieldBackground = get(['textField', 'darkBkColor'], theme);
      textFieldBackgroundHover = get(['palette', 'secondary', 'light'], theme);
      textFieldFocusedBorder = get(['textField', 'borderColor', 'focused'], theme);
      textFieldErrorBorder = get(['textField', 'borderColor', 'error'], theme);
      textFieldPrimaryLabel = get(['textField', 'inputLabel', 'primary', 'light'], theme);
      iconButtonColor = get(['datePicker', 'iconButton', 'light', 'color'], theme);
      break;
    default:
      backgroundColor = get(['datePicker', 'bgColor', 'light'], theme);
      dateBackgroundColor = get(['datePicker', 'dateBgColor', 'light'], theme);
      selectedBackgroundColor = get(['datePicker', 'selected', 'backgroundColor', 'light'], theme);
      fontFamily = get(['datePicker', 'font', 'defaultFont'], theme);
      dateFontColor = get(['datePicker', 'font', 'date', 'fontColor'], theme);
      dateFontSize = get(['datePicker', 'font', 'date', 'fontSize'], theme);
      dayFontColor = get(['datePicker', 'font', 'day', 'fontColor'], theme);
      dayFontSize = get(['datePicker', 'font', 'day', 'fontSize'], theme);
      headerFontSize = get(['datePicker', 'font', 'headerFontSize'], theme);
      headerFontFamily = get(['datePicker', 'font', 'boldFont'], theme);
      textFieldBackground = get(['textField', 'inputLabel', 'primary', 'light'], theme);
      textFieldBackgroundHover = get(['textField', 'background', 'light'], theme);
      textFieldFocusedBorder = get(['textField', 'borderColor', 'focused'], theme);
      textFieldErrorBorder = get(['textField', 'borderColor', 'error'], theme);
      textFieldPrimaryLabel = get(['textField', 'inputLabel', 'primary', 'focused'], theme);
      iconButtonColor = get(['datePicker', 'iconButton', 'light', 'color'], theme);
      break;
  }

  const styledCalenderSx = {
    '& .MuiCalendarPicker-root': {
      backgroundColor,
    },
    '& .MuiPickersDay-dayWithMargin': {
      color: dateFontColor,
      backgroundColor: dateBackgroundColor,
      fontFamily,
      fontSize: dateFontSize,
    },
    '& .MuiPickersDay-root.Mui-selected': {
      backgroundColor: selectedBackgroundColor,
      '&:hover': {
        backgroundColor: selectedBackgroundColor,
      },
      '&:focus': {
        backgroundColor: selectedBackgroundColor,
      },
    },
    '& .MuiDayPicker-weekDayLabel': {
      color: dayFontColor,
      backgroundColor: dateBackgroundColor,
      fontFamily,
      fontSize: dayFontSize,
    },
    '& .MuiPickersCalendarHeader-switchViewIcon': {
      display: 'none',
    },
    '& .MuiPickersCalendarHeader-switchViewButton': {
      display: 'none',
    },
    '& .MuiPickersCalendarHeader-labelContainer': {
      fontFamily: headerFontFamily,
      fontSize: headerFontSize,
    },
    '& .MuiPickersArrowSwitcher-root': {
      display: 'flex',
    },
    '& .MuiPickersArrowSwitcher-button': {
      color: iconButtonColor,
    },
    '& .MuiPaper-root': {
      boxShadow: 0,
    },
    ...sx,
  };

  const styleForTextField = {
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
    '& .MuiOutlinedInput-root': {
      backgroundColor: textFieldBackground,
      borderRadius: '40px',
      fontFamily: PRIMARY_FONT[400],
      '&:hover': {
        backgroundColor: textFieldBackgroundHover,
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      outline: `1px solid ${textFieldFocusedBorder}`,
      backgroundColor: textFieldBackground,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      outline: `1px solid ${textFieldErrorBorder}`,
    },
    '& .MuiInputLabel-root': {
      top: '-8px',
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1, 7, 1, 2),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .MuiFormLabel-asterisk.Mui-error': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputBase-root': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputBase-root.Mui-focused': {
      fontWeight: 500,
    },
    ...sx,
  };

  const renderInputTextField = (params) => (
    <TextField
      {...params}
      error={error}
      fullWidth={fullWidth}
      helperText={helperText}
      required={required}
      sx={styleForTextField}
      variant={variant}
    />
  );
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ResponsiveDatePicker
        desktopModeMediaQuery="@media (min-width: 900px)" // TODO: HB-958 discuss min-width
        label={label}
        minDate={minDate}
        name={name}
        onChange={onChange}
        PopperProps={{
          sx: styledCalenderSx,
        }}
        readOnly={readOnly}
        renderInput={renderInput || renderInputTextField}
        value={value}
        views={['year', 'month', 'day']}
      />
    </LocalizationProvider>
  );
};
DatePickerField.propTypes = {
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  mode: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  renderInput: PropTypes.func,
  required: PropTypes.bool,
  sx: PropTypes.shape({}),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  variant: PropTypes.string,
};
export default DatePickerField;
