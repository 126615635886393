import { get, uniqWith } from 'lodash/fp';

const getUniqueFilters = (prevFilters, newFilters) => {
  let newFilterState = get(['filterModel', 'items'], prevFilters) || [];
  newFilters.forEach((filter) => {
    const { value, field } = filter;
    // Code section below determines the filters being sent to the server.
    newFilterState =
      value !== null
        ? uniqWith(
            (filterItem1, filterItem2) =>
              filterItem1.field === filterItem2.field &&
              filterItem1.operation === filterItem2.operation,
            [filter, ...newFilterState]
          ) // filter with the same field will be replaced by the newer incoming filter.
        : newFilterState.filter((item) => item.field !== field); // if filter value is returning null (it is being unchecked), this will remove it from the filter list.
  });
  return { ...prevFilters, filterModel: { items: newFilterState } };
};
export default getUniqueFilters;
