import React, { useEffect } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { get } from 'lodash/fp';
import find from 'lodash/fp/find';
import map from 'lodash/fp/map';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import BackdropCircularProgress from '../../../components/BackdropCircularProgress';
import Button, { BUTTON_VARIANT } from '../../../components/Button';
import Dialog from '../../../components/Dialog';
import SwitchComponent from '../../../components/SwitchComponent/SwitchComponent';
import selectUser from '../../../store/selectors/appSelector';
import {
  allDataValid,
  corporationOptions,
  jobsList,
  selectedCorporation,
  selectIsLoadingCorporations,
  selectIsLoadingPt,
  selectIsPostingJobs,
  selectPositionTemplateData,
} from '../../../store/selectors/createJobsSelectors';
import theme from '../../../theme';
import launchDarklyToggles from '../../../utils/launchDarklyToggles';

import {
  FormGrid,
  HeaderGrid,
  requireApprovalDescriptionSx,
  requireApprovalTitleSx,
} from './BatchCreateJobsStyling';
import CreateJobsDataGridLS from './CreateJobsDataGridLS';
import {
  clearValidateList,
  getCorporations,
  postJobs,
  setJobCreateInitState,
  updateCorporationSelection,
  updateValidateList,
} from './reducer';

const RootGrid = styled(Grid)(() => ({}));

const ButtonGrid = styled(Grid)(() => ({
  justifyContent: 'flex-end',
}));

const styleForFormControl = {
  '& .MuiOutlinedInput-notchedOutline': {
    border: '0px',
  },
  '&:hover': {
    backgroundColor: get(['searchBoxForFilter', 'bgColor', 'hover'], theme),
  },
  '&:focus-within': {
    backgroundColor: get(['searchBoxForFilter', 'bgColor', 'focus'], theme),
    border: `1px solid ${get(['searchBoxForFilter', 'borderColor', 'focus'], theme)}`,
  },
  backgroundColor: get(['searchBoxForFilter', 'bgColor', 'complete'], theme),

  borderRadius: '40px',
  justifyContent: 'center',
  height: '40px',
  width: '40vw',
  marginBottom: '8px',
};

const CorporationSelection = () => {
  const dispatch = useDispatch();
  const isLoadingCorporations = useSelector(selectIsLoadingCorporations);
  const corporations = useSelector(corporationOptions);

  useEffect(() => {
    dispatch(getCorporations());
  }, [dispatch]);

  return (
    !isLoadingCorporations && (
      <FormControl sx={styleForFormControl}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={corporations}
          onChange={(event, newValue) => {
            dispatch(updateCorporationSelection(newValue));
          }}
          size="small"
          sx={{ marginBottom: '5px', marginTop: '5px' }}
          renderInput={(params) => <TextField {...params} placeholder="Company" />}
        />
      </FormControl>
    )
  );
};

const CreateJobs = ({ open, handleModalClose, flags }) => {
  const dispatch = useDispatch();
  const displayJobsList = useSelector(jobsList);
  const positionTemplateData = useSelector(selectPositionTemplateData);
  const isAllDataValid = useSelector(allDataValid);
  const selectedCorp = useSelector(selectedCorporation);
  const user = useSelector(selectUser);
  const isLoadingPt = useSelector(selectIsLoadingPt);
  const isPostingJobs = useSelector(selectIsPostingJobs);
  const isCorporationRequired = user.role === UserRole.ADMIN || user.role === UserRole.RECRUITER;
  const isJobApplicationSystemEnabled = launchDarklyToggles(flags, 'isJobApplicationSystemEnabled');
  const intl = useIntl();

  const [openPostDialog, setOpenPostDialog] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState('');
  const [dialogDesc, setDialogDesc] = React.useState('');
  const [postData, setPostData] = React.useState([]);
  const [requireApprovalForEmployee, setRequireApprovalForEmployee] = React.useState(false);
  useEffect(() => () => dispatch(setJobCreateInitState()), [dispatch]);

  const handleDialogClose = () => {
    setOpenPostDialog(false);
  };

  const handleDialogPost = () => {
    handleDialogClose();
    if (postData.length > 0) {
      const jobsData = isCorporationRequired
        ? map(
            (pd) => ({
              ...pd,
              company: selectedCorp.id,
              requireEmployerApproval: requireApprovalForEmployee || false,
            }),
            postData
          )
        : postData.map((data) => ({
            ...data,
            requireEmployerApproval: requireApprovalForEmployee || false,
          }));
      dispatch(postJobs({ orders: jobsData }));
    }
  };

  const generateTimeInfo = (positionId, shiftId, startInfo) => {
    const selectedPositionTemplate = find((pt) => pt._id === positionId, positionTemplateData) || {
      shifts: [],
    };
    const selectShift = find((shift) => shift._id === shiftId, selectedPositionTemplate.shifts) || {
      start: 0,
      end: 0,
    };

    const startMinutes = selectShift.start;
    const endMinutes = selectShift.end;

    const startDate = new Date(startInfo.year, startInfo.month - 1, startInfo.day, 0, startMinutes);
    const endDate = new Date(startInfo.year, startInfo.month - 1, startInfo.day, 0, endMinutes);

    if (startMinutes > endMinutes) {
      // startMinutes>endMinutes one more day overnight job
      endDate.setDate(endDate.getDate() + 1);
    }

    const startMillis = startDate.getTime();
    const endMillis = endDate.getTime();

    return {
      start: startMillis,
      end: endMillis,
      shift: selectShift,
    };
  };

  const handlePostButton = () => {
    setOpenPostDialog(true);
    dispatch(clearValidateList());
    const res = [];
    if (displayJobsList) {
      displayJobsList.forEach((job) => {
        if (
          job.startInfo.year &&
          job.numOpenings &&
          job.location &&
          job.location.id &&
          job.shift &&
          job.shift.id
        ) {
          const timeInfo = generateTimeInfo(job.position.id, job.shift.id, job.startInfo);
          res.push({
            frontEndId: job.id,
            start: timeInfo.start,
            end: timeInfo.end,
            shift: timeInfo.shift,
            numOpenings: parseInt(job.numOpenings, 10),
            numOverstaff: job?.numOverstaff,
            location: job.location.id,
            positionTemplate: job.position.id,
          });
        } else {
          // TODO: HB-964 add batch validate update for day labor scheduler
          if (!job.position.id) {
            dispatch(
              updateValidateList({
                id: job.id,
                field: 'position',
                status: false,
              })
            );
          }
          if (!job.location.id) {
            dispatch(
              updateValidateList({
                id: job.id,
                field: 'location',
                status: false,
              })
            );
          }

          if (!job.startInfo.year) {
            dispatch(
              updateValidateList({
                id: job.id,
                field: 'startInfo',
                status: false,
              })
            );
          }

          if (!job.shift.id) {
            dispatch(
              updateValidateList({
                id: job.id,
                field: 'shift',
                status: false,
              })
            );
          }

          if (Number.isNaN(job.numOpenings) || job.numOpenings <= 0) {
            dispatch(
              updateValidateList({
                id: job.id,
                field: 'numOpenings',
                status: false,
              })
            );
          }
        }
      });
    }
    const resLength = res.length;

    let totalOpenNumber = 0;
    res.forEach((job) => {
      totalOpenNumber += job.numOpenings;
    });

    const totalOverstaffNumber = res.reduce(
      (acc, job) => acc + (parseInt(job.numOverstaff, 10) || 0),
      0
    );

    if (resLength > 0) {
      setPostData(res);
      setDialogTitle(`Post all jobs?`);
      setDialogDesc(
        intl.formatMessage(
          { id: 'job_order.batch.create.confirmation' },
          {
            totalOpenings: totalOpenNumber,
            totalOverstaff: totalOverstaffNumber,
          }
        )
      );
    } else {
      setPostData([]);
    }
  };

  const dialogActions = [
    <Button onClick={handleDialogClose} variant={BUTTON_VARIANT.OUTLINED}>
      {LanguageConverter('buttonText.cancel')}
    </Button>,
  ];

  if (postData.length >= 1) {
    dialogActions.push(
      <Button onClick={handleDialogPost} autoFocus>
        {LanguageConverter('buttonText.ok')}
      </Button>
    );
  }

  return (
    <RootGrid container>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ width: 'inherit', margin: '50px' }}
      >
        <Grid
          it="modal-modal-title"
          item
          backgroundColor="#FFFFFF"
          sx={{
            height: '80vh',
            padding: '32px',
            overflowY: 'auto',
            margin: theme.spacing(2, 0, 0, 0),
          }}
        >
          <IconButton color="inherit" aria-label="close" onClick={handleModalClose}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
          {isCorporationRequired && (
            <HeaderGrid item>
              <Typography
                sx={{ ...requireApprovalTitleSx, margin: theme.spacing(0, 0, 4, 0) }}
                align="left"
              >
                Which corporation is this for?
              </Typography>
              <CorporationSelection />
            </HeaderGrid>
          )}
          {((isCorporationRequired && selectedCorp) || !isCorporationRequired) && (
            <>
              <FormGrid item container>
                {isJobApplicationSystemEnabled && (
                  <Grid container item>
                    <Grid container item>
                      <Typography
                        sx={{ ...requireApprovalTitleSx, margin: theme.spacing(4, 0, 0, 0) }}
                        align="left"
                      >
                        Require approvals for employees
                      </Typography>
                    </Grid>
                    <Grid container item>
                      <Grid item xs={6}>
                        <Typography sx={requireApprovalDescriptionSx} align="left">
                          This gives you extra control over who shows up for your shift, but
                          employees will not appear on your roster or for work unless you approve
                          their placement applications.
                        </Typography>
                      </Grid>
                      <Grid container item xs={6} justifyContent="flex-end">
                        <SwitchComponent
                          initialValue={requireApprovalForEmployee}
                          handleOnChange={() =>
                            setRequireApprovalForEmployee(!requireApprovalForEmployee)
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <CreateJobsDataGridLS disableVirtualization={false} />
              </FormGrid>
              <ButtonGrid item container>
                <Button
                  sx={{ marginLeft: 1 }}
                  text={LanguageConverter('buttonText.cancel')}
                  key="create-jobs-cancel"
                  onClick={() => handleModalClose()}
                />
                <Button
                  sx={{ marginLeft: 1 }}
                  text={LanguageConverter('buttonText.post')}
                  key="create-jobs-post"
                  disabled={!isAllDataValid || (isCorporationRequired && !selectedCorp)}
                  onClick={handlePostButton}
                />
                <Dialog
                  open={openPostDialog}
                  onClose={handleDialogClose}
                  dialogTitle={dialogTitle}
                  dialogContentText={dialogDesc}
                  dialogActions={dialogActions}
                  titleStyle={{
                    fontSize: theme.components.dialog.typography.title.fontSize,
                    fontFamily: theme.components.dialog.typography.title.fontFamily,
                  }}
                  textStyle={{
                    fontSize: theme.components.dialog.typography.text.fontSize,
                    color: theme.components.dialog.palette.text.color,
                  }}
                  data-testid="comfirm-dialog"
                />
              </ButtonGrid>
            </>
          )}
        </Grid>
      </Modal>
      {(isPostingJobs || isLoadingPt) && <BackdropCircularProgress />}
    </RootGrid>
  );
};

CreateJobs.propTypes = {
  open: PropTypes.bool,
  handleModalClose: PropTypes.func,
  flags: PropTypes.shape({}),
};
export default withLDConsumer()(CreateJobs);
