import React, { useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import { JOB_CATEGORY } from '@careerstart/wae-common/src/main/constants/jobInformation';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import OpenEyeIcon from '../../assets/icons/openEye.svg';
import YellowAlert from '../../assets/icons/YellowAlert';
import theme from '../../theme';
import { epochToDateInReadableFormat, epochToTimeInReadableFormat } from '../../utils';
import { isUnexcusedAttendance } from '../../utils/jobRosterHelpers';

import CancelledPlacementDetailDrawer from './CancelledPlacementDetailDrawer';
import { Attendance } from './constants';

const styleForCandidateName = {
  color: theme.components.detailView.typography.locationText.fontColor,
  fontFamily: theme.components.detailView.typography.locationText.fontFamily,
};

const CancelledTab = ({
  cancelledCandidates,
  corporation,
  jobType,
  handelOnCancelledPlacementUpdate,
  isCancelledPlacementUpdating,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const cancelledCardStyle = {
    borderRadius: '16px',
    padding: theme.spacing(1, 0, 1, 0),
    justifyContent: 'space-between',
    boxShadow: 0,
    width: '100%',
    minHeight: '42px',
    maxHeight: '64px',
    marginTop: '10px',
    '&:hover, &.Mui-hovered': {
      backgroundColor:
        jobType === JOB_CATEGORY.dayJob
          ? `${theme.palette.background.black}`
          : `${theme.palette.background.dark}`,
    },
  };
  const shouldShowWarningIcon = (candidate) => isUnexcusedAttendance(candidate, Attendance.ABSENT);
  return (
    <Box>
      <List>
        {cancelledCandidates &&
          cancelledCandidates.length > 0 &&
          cancelledCandidates.map((candidate, index) => {
            const favorite =
              corporation.favorite.find((f) => f.waeID === candidate.candidate) || false;
            return (
              <ListItem key={get('candidate', candidate)}>
                <Grid container sx={cancelledCardStyle}>
                  <Grid item>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: theme.spacing(0, 0, 0, 2),
                      }}
                    >
                      {!!favorite && (
                        <FavoriteIcon
                          sx={{
                            color: theme?.button?.palette?.whiteIcon,
                            margin: theme.spacing(0, 1.5, 0, 0),
                          }}
                        />
                      )}
                      <Typography sx={styleForCandidateName}>{candidate?.name}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: theme.spacing(0, 0, 0, 2),
                      }}
                    >
                      {shouldShowWarningIcon(candidate) && (
                        <Box
                          sx={{
                            display: 'flex',
                            margin: theme.spacing(0, 1.5, 0, 0),
                          }}
                        >
                          <YellowAlert />
                        </Box>
                      )}
                      <Typography sx={{ fontSize: '14px' }}>
                        {epochToDateInReadableFormat(candidate?.state?.timestamp)} at{' '}
                        {epochToTimeInReadableFormat(candidate?.state?.timestamp)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      height: 'relative',
                      width: '50px',
                    }}
                  >
                    <Box
                      item
                      component="img"
                      alt="EyeIcon"
                      src={OpenEyeIcon}
                      onClick={() => setSelectedIndex(index)}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            );
          })}
      </List>
      <CancelledPlacementDetailDrawer
        isOpen={selectedIndex !== null}
        candidate={cancelledCandidates?.[selectedIndex]}
        onClose={() => setSelectedIndex(null)}
        handleUpdate={handelOnCancelledPlacementUpdate}
        isCancelledPlacementUpdating={isCancelledPlacementUpdating}
      />
    </Box>
  );
};

CancelledTab.propTypes = {
  cancelledCandidates: PropTypes.arrayOf(PropTypes.shape({})),
  corporation: PropTypes.shape({ favorite: PropTypes.arrayOf(PropTypes.shape({})) }),
  jobType: PropTypes.string,
  handelOnCancelledPlacementUpdate: PropTypes.func,
  isCancelledPlacementUpdating: PropTypes.bool,
};

export default CancelledTab;
