import { combineReducers } from 'redux';

import { notificationsReducer } from '../components/NotificationCenter/reducer';
import { appReducer, logout } from '../views/app';
import { fileReducer } from '../views/app/fileReducer';
import { candidateDashboardReducer } from '../views/Candidate/dashboard/reducer';
import { candidateJobInvitationReducer } from '../views/Candidate/jobInvitation/reducer';
import { candidateJobsListReducer } from '../views/Candidate/jobs/reducer';
import { employeeTimeCardReducer } from '../views/Candidate/timeTracking';
import { corporationListReducer } from '../views/corporations/corporationList';
import { createCorporationReducer } from '../views/corporations/createCorporation';
import { forgotMyPasswordReducer } from '../views/forgotMyPassword';
import { createJobsReducer } from '../views/jobs/createJobs';
import { jobListReducer } from '../views/jobs/jobList';
import { employeeInviteReducer } from '../views/jobs/jobList/detail/SubComponents/reducer';
import { loginReducer } from '../views/login';
import { passwordUpdateReducer } from '../views/passwordUpdate';
import { accountVerificationReducer } from '../views/PasswordVerificationOrReset';
import { createPositionTemplateReducer } from '../views/positionTemplate/createPositionTemplateDrawer';
import { positionTemplateListReducer } from '../views/positionTemplate/positionTemplateList';
import { registerReducer } from '../views/register/reducer';
import { reportingReducer } from '../views/reporting/reducer';
import { settingsReducer } from '../views/settings';
import { skillsAndCertsReducer } from '../views/skillsAndCerts';
import { timekeepingReducer } from '../views/timekeeping';
import { usersReducer } from '../views/users/reducer';

const allReducers = combineReducers({
  accountVerification: accountVerificationReducer,
  app: appReducer,
  corporationList: corporationListReducer,
  createJobs: createJobsReducer,
  createCorporation: createCorporationReducer,
  createPositionTemplate: createPositionTemplateReducer,
  employeeTimeCards: employeeTimeCardReducer,
  employeeInvite: employeeInviteReducer,
  candidateJobsList: candidateJobsListReducer,
  candidateJobInvitation: candidateJobInvitationReducer,
  candidateDashboard: candidateDashboardReducer,
  forgotMyPassword: forgotMyPasswordReducer,
  jobList: jobListReducer,
  login: loginReducer,
  passwordUpdate: passwordUpdateReducer,
  positionTemplateList: positionTemplateListReducer,
  register: registerReducer,
  reporting: reportingReducer,
  timeSheet: timekeepingReducer,
  users: usersReducer,
  files: fileReducer,
  settings: settingsReducer,
  notifications: notificationsReducer,
  skillsAndCerts: skillsAndCertsReducer,
});

const createRootReducer = (state, action) => {
  if (action.type === logout.type) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return allReducers(state, action);
};
export default createRootReducer;
