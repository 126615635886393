import React, { useCallback, useState } from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import Button from '../../../components/Button';
import Dialog from '../../../components/Dialog';
import selectUser from '../../../store/selectors/appSelector';

import { cancelShiftGroup } from './reducer';

const CancelJobModal = ({ isCancelDialogOpen, setIsCancelDialogOpen, selectedJob }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const [reason, setReason] = useState('');
  const jobId = get(['id'], selectedJob);
  const start = get(['start'], selectedJob);

  const cancelWithin4HrDialogButtons = [
    <Button
      color="primary"
      text={LanguageConverter('button.cancel')}
      key="handle-cancel-4HR-cancel-button"
      onClick={() => setIsCancelDialogOpen(false)}
    />,
  ];

  const handleJobCancelJob = useCallback(() => {
    dispatch(
      cancelShiftGroup({
        id: jobId,
        reason,
      })
    );

    setIsCancelDialogOpen(false);
  }, [dispatch, jobId, reason, setIsCancelDialogOpen]);

  const handleCloseOnClick = () => {
    setReason('');
    setIsCancelDialogOpen(false);
  };

  const cancelDialogButtons = [
    <Button
      text={LanguageConverter('button.cancel')}
      variant="text"
      key="handle-cancel-cancel-button"
      onClick={handleCloseOnClick}
    />,

    <Button
      text={LanguageConverter('button.confirm')}
      disabled={reason === ''}
      variant="text"
      key="handle-confirm-cancel-button"
      onClick={handleJobCancelJob}
    />,
  ];

  const handleChange = (event) => {
    setReason(event.target.value);
  };

  const isCancellable = useCallback(() => {
    const timeDifference = start - new Date().getTime();
    // Checks if the difference between job's start time and current time is greater than 4 hours.
    const allowedCancellationTimeWindow =
      user.role === UserRole.ADMIN || user.role === UserRole.RECRUITER ? 0 : 14400000;
    if (timeDifference > allowedCancellationTimeWindow) {
      return true;
    }
    return false;
  }, [start, user.role]);

  const dialogContent = useCallback(() => {
    const menuItems = [
      { id: 1, name: 'Mistakenly Created' },
      { id: 2, name: 'Lack of Work' },
      { id: 3, name: 'Production/Product Down' },
      { id: 4, name: 'Filled Elsewhere' },
    ];

    if (isCancellable()) {
      return (
        <Box sx={{ minWidth: 280 }}>
          <FormControl fullWidth required variant="standard" onClose={() => setReason('')}>
            <InputLabel id="cancel-job-reason-select-label">Reason</InputLabel>
            <Select
              labelId="cancel-job-reason-select-label"
              id="cancel-job-reason-select"
              value={reason}
              label="Reason"
              onChange={handleChange}
            >
              {menuItems.map((item) => (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Please select reason</FormHelperText>
          </FormControl>
        </Box>
      );
    }
    return user.role === UserRole.ADMIN || user.role === UserRole.RECRUITER ? (
      <div>Cannot cancel job start time in the past</div>
    ) : (
      <div>Please call your account manager, cannot cancel job within 4 hours of start time</div>
    );
  }, [user.role, reason, isCancellable]);

  return (
    <Dialog
      open={isCancelDialogOpen}
      dialogTitle="Cancel Up Coming Job?"
      dialogContentText={dialogContent()}
      dialogActions={isCancellable() ? cancelDialogButtons : cancelWithin4HrDialogButtons}
      component="span"
      onBackdropClick={() => setIsCancelDialogOpen(false)}
      onClose={() => setReason('')}
    />
  );
};
CancelJobModal.propTypes = {
  selectedJob: PropTypes.shape({}),
  isCancelDialogOpen: PropTypes.bool,
  setIsCancelDialogOpen: PropTypes.func,
};
export default CancelJobModal;
