import React from 'react';
import PropTypes from 'prop-types';

const Filters = ({ filterDefinitions = [], onFilterChange, updateQueryOptions, wrapperSx }) =>
  filterDefinitions.map((item) => (
    <item.customFilter.type
      disabled={item.customFilter.disabled}
      field={item.customFilter.field}
      conditionalSearchTerm={item.customFilter.conditionalSearchTerm}
      operation={item.customFilter.operation}
      key={item.field || item.customFilter.field}
      onValueChange={updateQueryOptions}
      onValueChangeWithData={onFilterChange}
      options={item.customFilter.options}
      placeholder={item.customFilter.placeholder}
      optionsAPICallback={item.customFilter.optionsAPICallback}
      getOptionLabel={item.customFilter.getOptionLabel}
      background={item.customFilter.background}
      sx={item.customFilter.sx}
      initialValue={item.customFilter.initialValue}
      wrapperSx={wrapperSx}
    />
  ));
Filters.propTypes = {
  filterDefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  onFilterChange: PropTypes.func,
  updateQueryOptions: PropTypes.func,
  wrapperSx: PropTypes.shape({}),
};

export default Filters;
