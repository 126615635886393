import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';

import LeftWhiteArrowIcon from '../../assets/icons/LeftWhiteArrow.svg';
import RightWhiteArrowIcon from '../../assets/icons/RightWhiteArrow.svg';
import theme, {
  drawerWidth,
  drawerWidthHidden,
  drawerWidthInternal,
  navigationBarHeight,
} from '../../theme';

import DrawerMenus from './Drawer/DrawerMenus';

const styleForFooter = {
  height: '28vh',
  maxHeight: '300px',
  paddingLeft: theme.spacing(3.5),
};

const SideBar = ({ closeDrawer, isOpen, role, toggleDrawer }) => (
  <>
    <CssBaseline />
    <Drawer
      sx={{
        width: drawerWidthInternal,
        overflowX: 'hidden',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidthInternal,
          boxSizing: 'border-box',
          backgroundColor: theme.components.nav.backgroundColor.default,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          height: `calc(100% - ${navigationBarHeight})`,
          top: navigationBarHeight,
        },
        '& .MuiPaper-rounded': {
          borderRadius: '200px, 200px, 0, 0',
          borderRight: 0,
        },
      }}
      mode="push"
      open={isOpen}
      onClose={toggleDrawer()}
      onKeyDown={toggleDrawer()}
      variant="permanent"
      PaperProps={{ elevation: 0, square: false, style: { borderRadius: '0 0 0 0' } }}
    >
      <div
        style={
          (isOpen && { width: drawerWidth }) || { overflowX: 'hidden', width: drawerWidthHidden }
        }
        role="presentation"
        onKeyDown={closeDrawer}
      >
        <DrawerMenus
          closeDrawer={closeDrawer}
          role={role}
          isOpen={isOpen}
          toggleDrawer={toggleDrawer}
        />
      </div>
      <div style={styleForFooter}>
        {(isOpen && (
          <Box
            component="img"
            sx={{
              color: theme.components.nav.arrowColor.default,
              cursor: 'pointer',
            }}
            alt="back"
            onClick={toggleDrawer()}
            src={LeftWhiteArrowIcon}
          />
        )) || (
          <Box
            component="img"
            sx={{
              color: theme.components.nav.arrowColor.default,
              cursor: 'pointer',
            }}
            alt="forward"
            onClick={toggleDrawer()}
            src={RightWhiteArrowIcon}
          />
        )}
      </div>
    </Drawer>
  </>
);

SideBar.propTypes = {
  closeDrawer: PropTypes.func,
  isOpen: PropTypes.bool,
  role: PropTypes.string,
  toggleDrawer: PropTypes.func,
};

SideBar.defaultProps = {
  toggleDrawer: () => {},
  closeDrawer: () => {},
  isOpen: false,
};

export default SideBar;
