import { DateTime } from 'luxon';

import { convertHrsAndMinutesToMinutesFromMidnight } from '../../utils/timeConverter';

/**
 * Function that returns a boolean if current time is within 48 hrs of placement end
 * @param    {object} timecard  contains endTime(string, 3:00AM), startTime(string, 1:00AM) and date(string, 11/20/2024)
 * @return   {Boolean}
 */

const isValidDisputeTimeRange = (timecard) => {
  const now = Date.now();
  const end = timecard?.date.split('/');
  const endDate = DateTime.local(parseInt(end[2], 10), parseInt(end[0], 10), parseInt(end[1], 10));
  const endHoursAndMinutes = convertHrsAndMinutesToMinutesFromMidnight(timecard?.endTime);

  const startHoursAndMinutes = convertHrsAndMinutesToMinutesFromMidnight(timecard?.startTime);

  if (endHoursAndMinutes < startHoursAndMinutes) {
    return (
      now <=
      endDate
        .set({
          hour: Math.floor(endHoursAndMinutes / 60),
          minute: endHoursAndMinutes % 60,
        })
        .plus({ days: 3 })
        .toMillis()
    );
  }

  return (
    now <=
    endDate
      .set({
        hour: Math.floor(endHoursAndMinutes / 60),
        minute: endHoursAndMinutes % 60,
      })
      .plus({ days: 2 })
      .toMillis()
  );
};

export default isValidDisputeTimeRange;
