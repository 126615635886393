import React from 'react';
import PropTypes from 'prop-types';
import { Form as RFForm } from 'react-final-form';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import GradientLogo from '../../../assets/WAE_logo_Purple_60.svg';
import Alert from '../../../components/AlertComponent/AlertComponent';
import ButtonActions from '../../../components/Form/ButtonActions';
import { buildFormField } from '../../../components/Form/formFieldFactory';
import { validateRequiredFields } from '../../../components/Form/validations';

import { buildButtonData, formFieldData } from './formFieldData';

const LoginForm = ({ displayLockedAccountWarning, handleOnSubmitLogIn, isLoading, navigate }) => {
  const buttonData = buildButtonData(isLoading, navigate);
  return (
    <RFForm
      onSubmit={handleOnSubmitLogIn}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} id="loginformid">
          <Typography align="center">
            <img src={GradientLogo} alt="WAE" height={250} width={250} />
          </Typography>
          <Grid container spacing={2.5} direction="column">
            {displayLockedAccountWarning && (
              <Grid item>
                <Alert isOpen message="warning.account.mayBeLocked" severity="warning" />
              </Grid>
            )}
            <Grid item>{buildFormField(formFieldData.email)}</Grid>
            <Grid item>{buildFormField(formFieldData.password)}</Grid>
          </Grid>

          {buttonData && (
            <Grid container item>
              <ButtonActions buttonData={buttonData} formValues={values} />
            </Grid>
          )}
        </form>
      )}
      validate={(values) => validateRequiredFields(values, formFieldData)}
    />
  );
};

LoginForm.propTypes = {
  displayLockedAccountWarning: PropTypes.bool,
  handleOnSubmitLogIn: PropTypes.func,
  isLoading: PropTypes.bool,
  navigate: PropTypes.func,
};

export default LoginForm;
