import React from 'react';

import { Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import ConstructionCard from '../../../components/ConstructionCard';

const Payment = () => {
  const phoneScreen = useMediaQuery('(min-width:600px)');

  return (
    <Grid
      sx={{
        height: phoneScreen ? '100vh' : '80vh',
      }}
      container
    >
      <ConstructionCard pageName="Payment" />
    </Grid>
  );
};
export default Payment;
