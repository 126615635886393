import * as React from 'react';
import { useState } from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';

import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

import WaeButton, { BUTTON_VARIANT } from '../../../../../components/Button';
import theme from '../../../../../theme';
import {
  epochToDateInReadableFormat,
  epochToDayOfWeek,
  epochToTimeInReadableFormat,
} from '../../../../../utils';

import CancelShiftDrawer from './CancelShiftDrawer';

const YourShiftCard = ({
  cancelPlacement,
  id,
  start,
  status,
  end,
  rescindApplication,
  shiftPay,
  timecard,
}) => {
  const cardTheme = get(['candidate', 'components', 'groupDetails', 'availableShiftCard'], theme);
  const sxForCard = {
    backgroundColor: get('backgroundColor', cardTheme),
    display: 'flex',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(3),
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  };
  const sxForPrimaryText = {
    color: get(['primaryText', 'color'], cardTheme),
    fontFamily: get(['primaryText', 'font'], cardTheme),
    fontSize: get(['primaryText', 'fontSize'], cardTheme),
  };
  const sxForSecondaryText = {
    color: get(['secondaryText', 'color'], cardTheme),
    fontFamily: get(['secondaryText', 'font'], cardTheme),
    fontSize: get(['secondaryText', 'fontSize'], cardTheme),
  };
  const sxForRow = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.25),
  };

  const startDate = epochToDateInReadableFormat(start);
  const endDate = epochToDateInReadableFormat(start);

  const weekDay = epochToDayOfWeek(start);
  const startTime = epochToTimeInReadableFormat(start);
  const endTime = epochToTimeInReadableFormat(end);

  const ONE_HOUR_IN_MS = 3600000;
  const differenceInHours = (end - start) / ONE_HOUR_IN_MS;

  const hasPunches = timecard?.current?.punches?.length;

  const now = +new Date();

  const isInThePast = end < now;

  const isPending = status === 'pendingEmployerApproval';

  const [isCancelShiftDrawerOpen, setIsCancelShiftDrawerOpen] = useState(false);

  const handleDeleteOpen = () => {
    setIsCancelShiftDrawerOpen(true);
  };

  return (
    <Grid container sx={sxForCard} key={id}>
      <Grid item xs>
        <Box sx={sxForRow}>
          <Typography sx={sxForPrimaryText}>
            {startDate === endDate ? startDate : `${startDate}-${endDate}`}
          </Typography>
          <Typography sx={sxForPrimaryText}>{shiftPay}</Typography>
        </Box>
        <Box sx={sxForRow}>
          <Typography sx={sxForSecondaryText}>
            {weekDay} • {startTime} - {endTime}
          </Typography>
          <Typography sx={sxForSecondaryText}>{differenceInHours.toFixed(1)} Hrs</Typography>
        </Box>
      </Grid>

      {!hasPunches && !isInThePast && (
        <Grid
          item
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '130px',
            marginLeft: '15px',
            cursor: 'pointer',
          }}
          onClick={handleDeleteOpen}
          data-testid="cancel-placement"
          id="cancel-placement"
        >
          <WaeButton text="Cancel Shift" variant={BUTTON_VARIANT.DELETE} />
        </Grid>
      )}
      <CancelShiftDrawer
        isOpen={isCancelShiftDrawerOpen}
        id={id}
        onClose={() => setIsCancelShiftDrawerOpen(false)}
        handleCancelShift={cancelPlacement}
        handleRescindShift={rescindApplication}
        isPending={isPending}
      />
    </Grid>
  );
};

export default YourShiftCard;

YourShiftCard.propTypes = {
  cancelPlacement: PropTypes.func,
  id: PropTypes.string,
  rescindApplication: PropTypes.func,
  start: PropTypes.number,
  status: PropTypes.string,
  end: PropTypes.number,
  shiftPay: PropTypes.string,
  timecard: PropTypes.shape({
    current: PropTypes.shape({
      punches: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};
