import React, { useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { JOB_CATEGORY } from '@careerstart/wae-common/src/main/constants/jobInformation';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import Box from '@mui/material/Box';

import Button, { BUTTON_TYPE, BUTTON_VARIANT } from '../../../../components/Button';
import theme from '../../../../theme';
import { cancelJob } from '../reducer';

import CancelJobDrawer from './SubComponents/CancelJobDrawer';

const detailViewTypographyHeader = ['components', 'detailView', 'typography', 'header'];
const detailViewTypographySemiBoldHeader = [
  'components',
  'detailView',
  'typography',
  'semiBoldHeader',
];

const detailViewTypographySemiBoldText = ['components', 'detailView', 'typography', 'semiBoldText'];
const detailViewTypographyGeneral = ['components', 'detailView', 'typography', 'general'];

const detailViewTypographySubSectionHeader = [
  'components',
  'detailView',
  'typography',
  'subSectionHeader',
];
const Overview = ({ initialValues, user }) => {
  const dispatch = useDispatch();
  const { status, title, description, location, corporation, department = {} } = initialValues;
  const [isCancelJobDrawerOpen, setIsCancelJobDrawerOpen] = useState(false);
  const handleDeleteOpen = () => {
    setIsCancelJobDrawerOpen(true);
  };
  const handelCancelJob = (jobInfo) => {
    dispatch(cancelJob(jobInfo));
  };

  return (
    <Box>
      <Box sx={{ color: 'white' }}> Status: {status}</Box>
      <Box
        sx={{
          fontSize: get([...detailViewTypographyHeader, 'fontSize'], theme),
          fontFamily: get([...detailViewTypographyHeader, 'fontFamily'], theme),
          color: get([...detailViewTypographyHeader, 'fontColor'], theme),
        }}
      >
        {title}
      </Box>
      <Box
        sx={{
          fontSize: get([...detailViewTypographySemiBoldHeader, 'fontSize'], theme),
          fontFamily: get([...detailViewTypographySemiBoldHeader, 'fontFamily'], theme),
          color: get([...detailViewTypographySemiBoldHeader, 'fontColor'], theme),
        }}
      >
        {get('name', corporation)}
      </Box>
      <Box
        sx={{
          margin: '16px 0px',
          fontSize: get([...detailViewTypographyGeneral, 'fontSize'], theme),
          fontFamily: get([...detailViewTypographyGeneral, 'fontFamily'], theme),
          color: get([...detailViewTypographyGeneral, 'fontColor'], theme),
          whiteSpace: 'pre-wrap',
          overflowWrap: 'break-word',
        }}
      >
        {description}
      </Box>
      <Box
        sx={{
          fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
          fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
          color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
        }}
      >
        Job Location
      </Box>
      <Box
        sx={{
          margin: '16px 0px 32px 0px',
          fontSize: get([...detailViewTypographySemiBoldText, 'fontSize'], theme),
          fontFamily: get([...detailViewTypographySemiBoldText, 'fontFamily'], theme),
          color: get([...detailViewTypographySemiBoldText, 'fontColor'], theme),
        }}
      >
        {location
          ? `${location.address},${location.city},${location.state},${location.zipcode}`
          : ''}
      </Box>
      <Box
        sx={{
          fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
          fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
          color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
          marginBottom: '8px',
        }}
      >
        Job Department
      </Box>
      <Box
        sx={{
          fontSize: get([...detailViewTypographySemiBoldText, 'fontSize'], theme),
          fontFamily: get([...detailViewTypographySemiBoldText, 'fontFamily'], theme),
          color: get([...detailViewTypographySemiBoldText, 'fontColor'], theme),
        }}
      >
        {get(['name'], department) ? get(['name'], department) : ''}
      </Box>
      <Box onClick={handleDeleteOpen} marginTop="16px">
        <Button
          text={LanguageConverter('button.job.cancel')}
          type={BUTTON_TYPE.SUBMIT}
          variant={BUTTON_VARIANT.DELETE}
        />
      </Box>
      <CancelJobDrawer
        type="outlined"
        isOpen={isCancelJobDrawerOpen}
        onClose={() => setIsCancelJobDrawerOpen(false)}
        selectedJob={initialValues}
        user={user}
        handleCancelJob={handelCancelJob}
        jobType={JOB_CATEGORY.dayJob}
      />
    </Box>
  );
};

Overview.propTypes = {
  user: PropTypes.shape({}),
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    department: PropTypes.shape({}),
    location: PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipcode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    corporation: PropTypes.shape({}),
    numOpenings: PropTypes.number,
  }),
};

export default Overview;
