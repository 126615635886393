import React from 'react';
import PropTypes from 'prop-types';

import { Box, Grid } from '@mui/material';

import FillRatioCell from '../../views/jobs/jobList/FillRatioCell';

const FormFillRate = (props) => {
  const { sx, input } = props;

  return (
    <Grid item sx={sx}>
      <Box sx={{ color: 'white', width: '80%' }} data-testid="fill-ratio">
        <FillRatioCell value={input.value} />
      </Box>
    </Grid>
  );
};

export default FormFillRate;

FormFillRate.propTypes = {
  sx: PropTypes.shape({}),
  input: PropTypes.shape({
    value: PropTypes.number,
  }),
};
