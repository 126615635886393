import React from 'react';
import PropTypes from 'prop-types';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import WaeButton from '../../../../components/Button';
import { BLACK } from '../../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../../theme/fontConstants';

const NoRosterCard = ({
  onAddEmployeesClick,
  onInviteEmployeesClick,
  isCancelled,
  isRecruiterPlacementBlocked,
  currUserRole,
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box>
      <Typography
        sx={{
          color: BLACK[0],
          textAlign: 'center',
          margin: '0px 0 15px 0',
          fontFamily: PRIMARY_FONT[500],
        }}
      >
        {LanguageConverter(
          currUserRole === UserRole.EMPLOYER ? 'roster.emptyEmployer' : 'roster.emptyAdmin'
        )}
      </Typography>
    </Box>

    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <WaeButton
        disabled={
          isCancelled || (isRecruiterPlacementBlocked && currUserRole === UserRole.RECRUITER)
        }
        sx={{
          color: BLACK[0],
          variant: 'contained',
          margin: '0 auto',
        }}
        onClick={currUserRole === UserRole.EMPLOYER ? onInviteEmployeesClick : onAddEmployeesClick}
        text={LanguageConverter(
          currUserRole === UserRole.EMPLOYER
            ? 'buttonText.inviteEmployees'
            : 'buttonText.addEmployees'
        )}
      />
    </Box>
  </Box>
);
NoRosterCard.propTypes = {
  onAddEmployeesClick: PropTypes.func,
  onInviteEmployeesClick: PropTypes.func,
  isCancelled: PropTypes.bool,
  isRecruiterPlacementBlocked: PropTypes.bool,
  currUserRole: PropTypes.string,
};

export default NoRosterCard;
