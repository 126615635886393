/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { JOB_CATEGORY } from '@careerstart/wae-common/src/main/constants/jobInformation';
import searchParamOptions from '@careerstart/wae-common/src/main/constants/searchParams';
import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Divider, Grid, Link } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import WaeButton, { BUTTON_VARIANT } from '../../../../components/Button';
import CheckBox from '../../../../components/CheckBox/CheckBox';
import TimekeepingEditDrawer from '../../../../components/TimeKeepingEditDrawer';
import selectUser from '../../../../store/selectors/appSelector';
import {
  selectTimeCardIsUpdating,
  selectTimecardUpdateError,
} from '../../../../store/selectors/timeSheetSelector';
import theme from '../../../../theme';
import { BLACK, PRIMARY_COLOR } from '../../../../theme/colorConstants';
import { epochToTimeInReadableFormat } from '../../../../utils';
import { clearTimeCardError, updateTimeCard } from '../../../timekeeping';
import { TIMEKEEPING_STATUSES } from '../../../timekeeping/constants';
import {
  getJobRoster,
  postTimecardApprove,
  postTimecardResolve,
  updatePlacementCheckIn,
} from '../reducer';

import DeletePlacementDrawer from './SubComponents/DeletePlacementDrawer';
import ManageUserDrawer from './SubComponents/ManageUserDrawer';

const rosterCardTheme = theme?.jobOrders?.detail?.roster?.rosterCard;

const mainContainerStyle = { borderRadius: '5px', backgroundColor: rosterCardTheme.bgColorMain };

const headerGridStyles = {
  borderRadius: '5px',
  height: '48px',
  padding: theme.spacing(0, 2, 0, 2),
};

const punchHeaderTextStyles = {
  ...rosterCardTheme.punchHeaderTextStyles,
  width: '100%',
};

const punchDetailTextStyles = {
  ...rosterCardTheme.punchDetailTextStyles,
  width: '100%',
};

const checkBoxStyles = {
  '& .MuiCheckbox-root': {
    color: rosterCardTheme.checkBox.root,
    '&.Mui-checked': {
      color: rosterCardTheme.checkBox.checked,
    },
  },
  margin: 0,
};

const editButtonStyles = {
  borderRadius: '5%',
  height: '30px',
};

const statusUpdateBtnStyles = {
  borderRadius: '5%',
  height: '30px',
  margin: '8px',
};

const dividerStyles = {
  height: '2px',
  width: '100%',
};

const linkButtonStyles = {
  paddingRight: '16px',
  color: rosterCardTheme.linkButtonColor,
  fontSize: '12px',
};

const statusTextStyles = {
  fontFamily: 'Barlow',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '18px',
  alignSelf: 'center',
};

const RosterCard = ({
  jobType,
  jobOrderId,
  fav,
  start,
  end,
  candidate,
  corporationName,
  placement,
  onEmployeeUpdate,
  handleCancelPlacement,
  isRecruiterPlacementBlocked,
}) => {
  const [isDeletePlacementDrawerOpen, setIsDeletePlacementDrawerOpen] = useState(false);
  const [isUserManageDrawerOpen, setIsUserManageDrawerOpen] = useState(false);

  const [workFlowData, setWorkFlowData] = useState({});
  const [isTimeKeepingEditDrawerOpen, setIsTimeKeepingEditDrawerOpen] = useState(false);

  const user = useSelector(selectUser);

  const timecardIsUpdating = useSelector(selectTimeCardIsUpdating);
  const timecardUpdateError = useSelector(selectTimecardUpdateError);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = user?.role;
  const punches = placement?.timecard?.current?.punches;
  const placementStatus = placement?.timecard?.status;
  const isCheckedIn = placement?.timecard?.checkIn?.stamp > 0; // Might need BE fix that when user uncheck, response body checkIn?.stamp : -1
  const FIFTEEN_MINS_IN_MS = 900000;
  const isShiftStarted = start - FIFTEEN_MINS_IN_MS < Date.now(); // 15 min before the start time
  const isShiftCompleted = end + FIFTEEN_MINS_IN_MS < Date.now(); // 15 min after the end time

  const getTotalHours = (totalTimeInMs) =>
    typeof totalTimeInMs === 'number' ? (totalTimeInMs / 60 / 60 / 1000).toFixed(2) : '--';

  const getHours = (startEpoch, endEpoch) => {
    const diff = endEpoch - startEpoch;
    return typeof diff === 'number' ? (diff / 60 / 60 / 1000).toFixed(2) : '--';
  };

  const statusColor =
    (isCheckedIn && rosterCardTheme.headerStatus.checked) ||
    (!isShiftCompleted && rosterCardTheme.headerStatus.uncheckedPrimary) ||
    rosterCardTheme.headerStatus.uncheckedSecondary;

  const buttonActionColor = PRIMARY_COLOR[80];
  const buttonHoverColor = PRIMARY_COLOR[100];

  const statusObject =
    (placementStatus === TIMEKEEPING_STATUSES.APPROVED && {
      textKey: 'timekeeping.status.approved',
      textColor: rosterCardTheme.statusTextColors.approved,
    }) ||
    (placementStatus === TIMEKEEPING_STATUSES.RESOLVED && {
      textKey: 'timekeeping.status.resolved',
      textColor: rosterCardTheme.statusTextColors.resolved,
    }) ||
    (placementStatus === TIMEKEEPING_STATUSES.DISPUTED && {
      textKey: 'timekeeping.status.disputed',
      textColor: rosterCardTheme.statusTextColors.disputed,
    }) ||
    (placementStatus === TIMEKEEPING_STATUSES.FINALIZED && {
      textKey: 'timekeeping.status.finalized',
      textColor: rosterCardTheme.statusTextColors.finalized,
    }) ||
    null;

  const handleDeleteOpen = () => {
    setIsDeletePlacementDrawerOpen(true);
  };

  const handleEditClick = () => {
    setIsUserManageDrawerOpen(true);
  };

  const handleOnCheckBoxChange = (event) => {
    dispatch(
      updatePlacementCheckIn({ placement: placement?._id, isCheckedIn: event.target.checked })
    );
  };

  const handleOnApprove = () => {
    dispatch(postTimecardApprove({ placements: [placement?._id] }));
  };

  const handleOnResolve = () => {
    dispatch(postTimecardResolve({ placements: [placement?._id] }));
  };

  const handleTimeCardClick = () => {
    const navProps = {
      pathname: '/timekeeping',
      search: `?${searchParamOptions.START}=${start}&${searchParamOptions.CANDIDATE}=${candidate.id}&${searchParamOptions.PLACEMENT}=${placement?._id}&${searchParamOptions.DATE}=${placement?.start}`,
    };

    navigate(navProps);
  };

  const handleOnEditTimeClick = useCallback(() => {
    const shift = {
      placement: placement?._id,
      start,
      end,
      timecard: placement?.timecard?.current,
      corporation: corporationName,
      order: candidate.jobOrderName,
      shiftName: candidate.jobOrderShiftName,
    };

    setWorkFlowData(shift);
    setIsTimeKeepingEditDrawerOpen(true);
  }, [start, end, placement, candidate, corporationName]);

  const handleTimecardEditSubmit = useCallback(
    ({ punches: updatedPunches }) => {
      // Add JSON schema validation here. If there is a validation error, return false.
      const payload = {
        placement: workFlowData?.placement,
        punches: updatedPunches.map((punch) => ({
          in: punch?.in?.stamp,
          out: punch?.out?.stamp,
        })),
      };
      dispatch(updateTimeCard(payload));
      return true;
    },
    [dispatch, workFlowData]
  );

  const handleTimecardEditClose = useCallback(() => {
    dispatch(clearTimeCardError());
    const query = { jobOrder: jobOrderId, limit: 250 };
    dispatch(getJobRoster(query));
    setIsTimeKeepingEditDrawerOpen(false);
  }, [dispatch, jobOrderId]);

  const showApproveBtn =
    isShiftCompleted &&
    placementStatus === TIMEKEEPING_STATUSES.PENDING &&
    userRole === UserRole.EMPLOYER ? (
      <WaeButton
        variant={BUTTON_VARIANT.DEFAULT}
        sx={statusUpdateBtnStyles}
        onClick={handleOnApprove}
        actionColor={buttonActionColor}
        borderColor={buttonActionColor}
        hoverColor={buttonHoverColor}
      >
        {LanguageConverter('buttonText.approve')}
      </WaeButton>
    ) : null;

  const showResolveBtn =
    isShiftCompleted &&
    placementStatus === TIMEKEEPING_STATUSES.PENDING &&
    userRole === UserRole.ADMIN ? (
      <WaeButton
        variant={BUTTON_VARIANT.DEFAULT}
        sx={statusUpdateBtnStyles}
        onClick={handleOnResolve}
        actionColor={buttonActionColor}
        borderColor={buttonActionColor}
        hoverColor={buttonHoverColor}
      >
        {LanguageConverter('buttonText.resolve')}
      </WaeButton>
    ) : null;

  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: '50px',
        padding:
          jobType === JOB_CATEGORY.shift ? theme.spacing(0, 1, 2, 1) : theme.spacing(0, 0, 2, 0),
      }}
    >
      <Grid container sx={mainContainerStyle}>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ ...headerGridStyles, backgroundColor: statusColor }}
        >
          <Typography sx={rosterCardTheme.candidateNameTextStyles}>{candidate.name}</Typography>
          <CheckBox
            disabled={userRole !== UserRole.EMPLOYER || !isShiftStarted}
            sx={checkBoxStyles}
            checked={isCheckedIn}
            onChange={handleOnCheckBoxChange}
          />
        </Grid>
        {punches &&
          !!punches?.length &&
          placement?.timecard?.status === TIMEKEEPING_STATUSES.PENDING && (
            <Grid
              container
              sx={{
                alignItems: 'self-end',
                justifyContent: 'space-between',
                padding: theme.spacing(2),
              }}
            >
              <Grid item xs={8}>
                <Box
                  sx={{
                    display: 'flex',
                    direction: 'column',
                    alignItems: 'self-end',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography sx={punchHeaderTextStyles}>Clock In</Typography>
                  <Typography sx={punchHeaderTextStyles}>Clock Out</Typography>
                  <Typography sx={punchHeaderTextStyles}>Hours</Typography>
                </Box>
                {punches.map((punch) => (
                  <Box
                    key={punch?._id}
                    sx={{
                      display: 'flex',
                      direction: 'column',
                      alignItems: 'self-end',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography sx={punchDetailTextStyles}>
                      {epochToTimeInReadableFormat(punch?.in?.stamp)}
                    </Typography>
                    <Typography sx={punchDetailTextStyles}>
                      {punch?.out?.stamp ? epochToTimeInReadableFormat(punch?.out?.stamp) : '--'}
                    </Typography>
                    <Typography sx={punchDetailTextStyles}>
                      {punch?.out?.stamp ? getHours(punch?.in?.stamp, punch?.out?.stamp) : '--'}
                    </Typography>
                  </Box>
                ))}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={punchDetailTextStyles}>{`Total Hours: ${getTotalHours(
                  placement?.timecard?.current?.totalTime
                )}`}</Typography>
              </Grid>
            </Grid>
          )}
        <Divider sx={dividerStyles} />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ minHeight: '48px', width: '100%', padding: theme.spacing(0, 2, 0, 2) }}
        >
          <Box>
            <Link component="button" sx={linkButtonStyles} onClick={handleEditClick}>
              {LanguageConverter('buttonText.commentDNR')}
            </Link>
            <Link component="button" sx={linkButtonStyles} onClick={handleTimeCardClick}>
              {LanguageConverter('buttonText.gotToTimekeeping')}
            </Link>

            {(!isRecruiterPlacementBlocked || userRole === UserRole.ADMIN) && !isShiftStarted && (
              <Link component="button" sx={linkButtonStyles} onClick={handleDeleteOpen}>
                {LanguageConverter('buttonText.remove')}
              </Link>
            )}
          </Box>
          <Box>
            {statusObject ? (
              <Typography sx={{ ...statusTextStyles, color: statusObject?.textColor }}>
                {LanguageConverter(statusObject?.textKey)}
              </Typography>
            ) : (
              <>
                <WaeButton
                  variant={BUTTON_VARIANT.OUTLINED}
                  sx={editButtonStyles}
                  actionColor={BLACK[80]}
                  borderColor={buttonActionColor}
                  hoverColor={buttonHoverColor}
                  onClick={handleOnEditTimeClick} // TODO: this button should be hidden based on role and timecard status (check showTimecardEditButton and ask for requirements)
                >
                  {LanguageConverter('buttonText.edit.time')}
                </WaeButton>
                {showApproveBtn}
                {showResolveBtn}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      <DeletePlacementDrawer
        type="outlined"
        isOpen={isDeletePlacementDrawerOpen}
        onClose={() => setIsDeletePlacementDrawerOpen(false)}
        candidateName={candidate.name}
        handleCancelPlacement={handleCancelPlacement}
      />
      <ManageUserDrawer
        candidateName={candidate.name}
        originalEmployerNote={candidate.employerNote}
        handleOnSave={onEmployeeUpdate}
        fav={fav}
        placementId={placement?._id}
        isOpen={isUserManageDrawerOpen}
        onClose={() => setIsUserManageDrawerOpen(false)}
        isRecruiterBlocked={isRecruiterPlacementBlocked}
      />
      <TimekeepingEditDrawer
        isDrawerOpen={isTimeKeepingEditDrawerOpen}
        shift={workFlowData}
        onSubmit={handleTimecardEditSubmit}
        onClose={handleTimecardEditClose}
        timecardIsUpdating={timecardIsUpdating}
        errorMessage={timecardUpdateError}
      />
    </Box>
  );
};

RosterCard.propTypes = {
  jobType: PropTypes.string,
  jobOrderId: PropTypes.string,
  fav: PropTypes.bool,
  start: PropTypes.number,
  end: PropTypes.number,
  corporationName: PropTypes.string,
  isRecruiterPlacementBlocked: PropTypes.bool,
  candidate: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    employerNote: PropTypes.string,
    jobOrderName: PropTypes.string,
    jobOrderShiftName: PropTypes.string,
  }),
  placement: PropTypes.shape({
    _id: PropTypes.string,
    start: PropTypes.number,
    timecard: PropTypes.shape({
      current: PropTypes.shape({
        punches: PropTypes.arrayOf(PropTypes.shape({})),
        totalTime: PropTypes.number,
      }),
      status: PropTypes.string,
      checkIn: PropTypes.shape({
        stamp: PropTypes.number,
      }),
    }),
  }),
  onEmployeeUpdate: PropTypes.func,
  handleCancelPlacement: PropTypes.func,
};

export default RosterCard;
