import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import asyncService from '../../../../../../datahub/asyncService';
import WaeButton from '../../../../../components/Button';
import theme from '../../../../../theme';
import { showSnackbar } from '../../../../app';

const headerStyle = {
  fontFamily: theme.components.detailView.typography.header.fontFamily,
  fontSize: theme.components.detailView.typography.header.fontSize,
  color: theme.components.detailView.palette.recommendModalHeader,
  marginBottom: 3,
};

const subHeaderStyle = {
  fontFamily: theme.components.detailView.typography.general.fontFamily,
  fontSize: theme.components.detailView.typography.general.fontSize,
  color: theme.components.detailView.palette.recommendModalSubHeader,
  marginBottom: 2,
};

const styleForCheckboxes = {
  '& .MuiSvgIcon-root': {
    color: theme.export.checkbox.default,
  },
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 6,
  p: 4,
};

const checkText = {
  fontFamily: theme.components.detailView.typography.fieldHeaders.fontFamily,
  fontSize: theme.components.detailView.typography.general.fontSize,
  color: theme.components.detailView.palette.defaultTextColor,
};
const ExportRecommendList = ({ jobOrderId, user }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previousWorked, setPreviousWorked] = useState(false);
  const [workedPosition, setWorkedPosition] = useState(false);
  const [workedShift, setWorkedShift] = useState(false);

  const handleExportCsv = () => {
    const token = get('token', user);
    asyncService({
      httpMethod: 'POST',
      route: 'job-orders/recommended-employees',
      data: {
        jobOrderId,
        prioritizeNumShiftsAtCorporation: previousWorked,
        prioritizeNumShiftsOnPositionTemplate: workedPosition,
        prioritizeNumShiftsAtTimeUTC: workedShift,
      },
      onSuccess: (resp) => {
        const documents = resp?.data?.documents ?? [];
        const reformatRecommendEmployee = (employee) => ({
          name: employee?.name ?? 'No Candidate Name Provided',
          phoneNumber: employee?.candidate?.phoneNumber ?? 'No Phone Number Provided',
          waeID: employee?.id ?? employee?._id ?? 'No Wae ID',
          bullhornID: employee?.metadata?.bullhorn?.id ?? 'No Bullhorn ID',
        });
        const updatedData =
          documents?.map((employeeEntry) => reformatRecommendEmployee(employeeEntry)) ?? [];

        const csvHeader = 'Name,PhoneNumber,WaeID,BullhornID\n';

        let csvContent = '';
        updatedData.length > 0 &&
          updatedData.forEach((item) => {
            const row = `${item.name},${item.phoneNumber},${item.waeID},${item.bullhornID}`;
            csvContent += `${row}\n`;
          });

        const blob = new Blob([csvHeader, ...csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'recommendEmployees.csv');
        setIsModalOpen(!isModalOpen);
      },
      onError: (err) => {
        const globalError = err?.response?.data?.errors?.GLOBAL;
        dispatch(showSnackbar({ message: globalError?.messageKey || 'error.recommendEmployee' }));
      },
      dispatch,
      token,
      refreshToken: user?.refreshToken,
    });
  };

  return (
    <Box sx={{ marginTop: 3 }}>
      <WaeButton
        sx={{ marginLeft: '6px' }}
        onClick={() => setIsModalOpen(!isModalOpen)}
        variant="outlined"
        text={LanguageConverter('roster.recommendEmployees.title')}
      />
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(!isModalOpen)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        data-testid="recommend-modal"
      >
        <Box sx={style}>
          <Typography sx={headerStyle} id="modal-modal-title" variant="h6" component="h2">
            {LanguageConverter('roster.recommendEmployees.title')}
          </Typography>
          <Typography sx={subHeaderStyle}>
            {LanguageConverter('roster.recommendEmployees.subHeader')}
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  sx={styleForCheckboxes}
                  inputProps={{ 'data-testid': 'worked2wks-checkbox' }}
                />
              }
              label={LanguageConverter('roster.recommendEmployees.previousWorked2wks')}
              checked={previousWorked}
              onChange={() => setPreviousWorked(!previousWorked)}
              sx={{ '& .MuiTypography-root': checkText }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  sx={styleForCheckboxes}
                  inputProps={{ 'data-testid': 'position2wks-checkbox' }}
                />
              }
              label={LanguageConverter('roster.recommendEmployees.workedPosition2wks')}
              checked={workedPosition}
              onChange={() => setWorkedPosition(!workedPosition)}
              sx={{ '& .MuiTypography-root': checkText }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={styleForCheckboxes}
                  inputProps={{ 'data-testid': 'shift2wks-checkbox' }}
                />
              }
              label={LanguageConverter('roster.recommendEmployees.workedShift2wks')}
              checked={workedShift}
              onChange={() => setWorkedShift(!workedShift)}
              sx={{ '& .MuiTypography-root': checkText }}
            />
          </FormGroup>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <WaeButton
              variant="outlined"
              text={LanguageConverter('generic.goBack')}
              onClick={() => setIsModalOpen(!isModalOpen)}
            />
            <WaeButton
              sx={{ marginLeft: 1 }}
              text={LanguageConverter('button.export')}
              onClick={handleExportCsv}
            />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

ExportRecommendList.propTypes = {
  jobOrderId: PropTypes.string,
  user: PropTypes.shape(),
};

export default ExportRecommendList;
