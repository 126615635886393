import React, { useState } from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import CloseIcon from '@mui/icons-material/Close';
import { Box, TextField } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import Delete from '../../../../../assets/icons/Delete.svg';
import Button, { BUTTON_VARIANT } from '../../../../../components/Button';
import IconButton from '../../../../../components/IconButton/IconButton';
import theme, { navigationBarHeight } from '../../../../../theme';

const RootBox = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  padding: theme.spacing(2, 8),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '20px',
}));

const HeaderBox = styled(Box)(() => ({
  width: '100%',
}));

const HeaderBoxTypography = styled(Box)(() => ({
  fontSize: `${theme.components.drawer.typography.header.fontSize}`,
  fontFamily: `${theme.components.drawer.typography.header.fontFamily}`,
  color: `${theme.components.drawer.palette.header}`,
}));
const DescriptionBoxTypgraphy = styled(Box)(() => ({
  fontSize: `${theme.components.drawer.typography.description.fontSize}`,
  fontFamily: `${theme.components.drawer.typography.description.fontFamily}`,
  color: `${theme.components.drawer.palette.description}`,
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    marginTop: '0 !important',

    color: `${theme.textField.error}`,
    backgroundColor: theme.palette.common.white,
  },
}));

const textFieldStyle = {
  borderRadius: '40px',
  '& .MuiInputBase-root': {
    color: `${theme.palette.secondary.light}`,
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: `${theme.textField.background.grey}`,
    borderRadius: '15px',

    fontFamily: `${theme.textField.typography.input.fontFamily}`,
    fontSize: `${theme.textField.typography.input.fontSize}`,

    padding: theme.spacing(1),
    marginBottom: theme.spacing(0),
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

const CancelShiftDrawer = ({
  id,
  isOpen,
  isPending,
  onClose,
  handleCancelShift,
  handleRescindShift,
}) => {
  const [cancelNote, setCancelNote] = useState('');

  const handleNoteChange = (event) => {
    setCancelNote(event.target.value);
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{ sx: { maxWidth: '576px', paddingTop: navigationBarHeight, height: '100vh' } }}
    >
      <HeaderBox>
        <IconButton
          iconElement={<CloseIcon />}
          onClick={() => onClose()}
          size="small"
          sx={{ marginLeft: 0.5, marginTop: 0.5, position: 'fixed' }}
        />
      </HeaderBox>
      <RootBox>
        <Box
          component="img"
          sx={{
            height: 51.52,
            width: 51.52,
          }}
          alt="Delete"
          src={Delete}
        />
        <HeaderBoxTypography>
          {isPending
            ? LanguageConverter('candidate.myJobs.rescind')
            : LanguageConverter('candidate.myJobs.cancel')}
        </HeaderBoxTypography>
        <DescriptionBoxTypgraphy>
          {isPending
            ? LanguageConverter('candidate.myJobs.rescind.detail')
            : LanguageConverter('candidate.myJobs.cancel.detail')}
        </DescriptionBoxTypgraphy>
        {!isPending && (
          <BootstrapTooltip
            data-testid="error-text"
            title={LanguageConverter('warning.placement.cancelNoteNotBetween15To250Chars')}
            placement="bottom-start"
            open={cancelNote.length < 15 && cancelNote.length !== 0}
          >
            <TextField
              fullWidth
              variant="outlined"
              value={cancelNote}
              onChange={handleNoteChange}
              multiline
              placeholder={LanguageConverter('candidate.placement.cancel.placeholder')}
              rows={3}
              sx={textFieldStyle}
            />
          </BootstrapTooltip>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            alignSelf: 'flex-end',
            marginTop: theme.spacing(1),
          }}
        >
          <Box>
            <Button onClick={onClose} variant={BUTTON_VARIANT.OUTLINED}>
              {LanguageConverter('candidate.placement.button.back')}
            </Button>
          </Box>
          <Box>
            <Button
              disabled={!isPending && cancelNote.length < 15}
              variant={BUTTON_VARIANT.DELETE}
              onClick={() =>
                isPending
                  ? handleRescindShift({ placement: id })
                  : handleCancelShift({ id, cancelNote })
              }
              sx={{ marginLeft: theme.spacing(1) }}
              text={LanguageConverter('candidate.placement.button.cancel')}
              data-testid="cancel-placement"
            />
          </Box>
        </Box>
      </RootBox>
    </Drawer>
  );
};

CancelShiftDrawer.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  isPending: PropTypes.bool,
  onClose: PropTypes.func,
  handleCancelShift: PropTypes.func,
  handleRescindShift: PropTypes.func,
};
export default CancelShiftDrawer;
