import React, { useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import CancelReasons from '@careerstart/wae-common/src/main/constants/Cancel-reasons';
import { JOB_CATEGORY } from '@careerstart/wae-common/src/main/constants/jobInformation';
import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { Box, FormControlLabel, RadioGroup } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import BlueBackgroundRaido from '../../../../../assets/icons/BlueBackgroundRadio';
import Delete from '../../../../../assets/icons/Delete.svg';
import Button, { BUTTON_VARIANT } from '../../../../../components/Button';
import IconButton from '../../../../../components/IconButton';
import theme from '../../../../../theme';

import BasicDrawer from './BasicDrawer';

const detailViewTypographyReasonText = [
  'components',
  'detailView',
  'typography',
  'reasonTextBlack',
];
const FOUR_HOURS_IN_MS = 14400000;

const CancelJobDrawer = ({
  selectedJob,
  isOpen,
  onClose,
  user,
  handleCancelJob,
  jobType,
  upcomingCount,
}) => {
  const [cancelReason, setCancelReason] = useState('');
  const [showMustSelectAReason, setShowMustSelectAReason] = useState(false);
  const start = get(['start'], selectedJob);
  const [cancelJobStep, setCancelJobStep] = useState(() => {
    const timeDifference = start - new Date().getTime();
    const allowedCancellationTimeWindow =
      user.role === UserRole.ADMIN || user.role === UserRole.RECRUITER ? 0 : FOUR_HOURS_IN_MS;
    // Checks if the difference between job's start time and current time is greater than 4 hours.
    if (
      (jobType === 'dayJob' || jobType === 'shift') &&
      timeDifference <= allowedCancellationTimeWindow
    ) {
      return 2;
    }
    return 1;
  });

  const handleGoBackButton = () => {
    if (cancelJobStep === 1 || cancelJobStep === 2) {
      onClose();
      return;
    }
    setCancelJobStep(1);
  };

  const handleContinueButton = () => {
    setCancelJobStep(3);
  };

  const handleCancelJobButton = () => {
    if (!CancelReasons[cancelReason]) {
      setShowMustSelectAReason(true);
      return;
    }
    handleCancelJob({
      id: selectedJob.id,
      reason: CancelReasons[cancelReason],
    });
  };

  const headerText = (step) => {
    switch (step) {
      case 1:
        return `Are you sure you want to cancel this ${
          jobType === JOB_CATEGORY.shift ? 'shift' : 'job'
        } ?`; // TODO support multiple language
      case 2:
        return 'Are you sure you want to cancel this job?';
      case 3:
        return 'Reason for Cancellation?';
      default:
        return '';
    }
  };

  const inlineText = () => {
    switch (jobType) {
      case JOB_CATEGORY.longTermJob:
        return 'job orders';
      case JOB_CATEGORY.dayJob:
        return 'openings';
      case JOB_CATEGORY.shift:
        return 'shifts';
      default:
        return '';
    }
  };

  const subHeaderText = (step) => {
    switch (step) {
      case 1:
        return `Cancelling this ${
          jobType === JOB_CATEGORY.longTermJob ? 'job series' : 'job order'
        } will affect ${selectedJob.numOpenings || upcomingCount} ${inlineText(
          jobType
        )} . This action
            can not be undone,if you would like to proceed please do so by clicking Continue below `;
      case 2:
        return `Since this job is within four hours of clock-in time, relevant fees will be incurred for any workers placed on this scheduled job. In order to cancel this job,please contact support. `;
      case 3:
        return 'Select a reason for Cancellation?';
      default:
        return '';
    }
  };

  const HeaderIcon = () => (
    <Box
      component="img"
      sx={{
        height: 51.52,
        width: 51.52,
      }}
      alt="Delete"
      src={Delete}
    />
  );

  const Content = () => {
    if (cancelJobStep === 1 || cancelJobStep === 2) return null;
    const cancelReasonOptions = [];
    Object.keys(CancelReasons).forEach((key) => {
      cancelReasonOptions.push({ id: key, label: CancelReasons[key] });
    });
    const handleRadioButtonChange = (event, val) => {
      setShowMustSelectAReason(false);
      setCancelReason(val);
    };
    return (
      <Box>
        <FormControl>
          <RadioGroup name="controlled-radio-buttons-group" onChange={handleRadioButtonChange}>
            {cancelReasonOptions.map((reason) => (
              <FormControlLabel
                key={reason.id}
                value={reason.id}
                control={<BlueBackgroundRaido />}
                label={reason.label}
                checked={cancelReason === reason.id}
                sx={{
                  color: get([...detailViewTypographyReasonText, 'fontColor'], theme),
                  '& .MuiTypography-root': {
                    fontSize: get([...detailViewTypographyReasonText, 'fontSize'], theme),
                    fontFamily: get([...detailViewTypographyReasonText, 'fontFamily'], theme),
                  },
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>

        {showMustSelectAReason && (
          <Box
            sx={{
              display: 'flex',
              backgroundColor: 'orange',
              borderRadius: '10px',
              marginTop: '20px',
            }}
          >
            <Box sx={{ paddingRight: '5px' }}>
              <ErrorOutlineRoundedIcon />
            </Box>
            <Box>Please select an cancellation reason to cancel the job order.</Box>
            <Box>
              <IconButton
                iconElement={<CloseIcon />}
                onClick={() => {
                  setShowMustSelectAReason(false);
                }}
                size="small"
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const GoBackButton = () => (
    <Button
      variant={BUTTON_VARIANT.OUTLINED}
      onClick={handleGoBackButton}
      text={LanguageConverter('button.goBack')}
    />
  );

  const ActionButton = () => {
    if (cancelJobStep === 1) {
      // continue
      return (
        <Button
          onClick={handleContinueButton}
          sx={{
            marginLeft: '5px',
          }}
          text={LanguageConverter('button.continue')}
        />
      );
    }

    if (cancelJobStep === 2) {
      // do not show anything
      return null;
    }

    return (
      <Button
        variant={BUTTON_VARIANT.DELETE}
        onClick={handleCancelJobButton}
        sx={{ marginLeft: '5px' }}
        text={LanguageConverter('button.job.cancel')}
      />
    );
  };

  return (
    <BasicDrawer
      goBackButton={<GoBackButton />}
      actionButton={<ActionButton />}
      type={jobType}
      headerIcon={(cancelJobStep !== 3 && <HeaderIcon />) || null}
      header={headerText(cancelJobStep)}
      subHeader={subHeaderText(cancelJobStep)}
      content={<Content cancelJobStep={cancelJobStep} />}
      onClose={onClose}
      isOpen={isOpen}
      selectedJob={selectedJob}
    />
  );
};

CancelJobDrawer.propTypes = {
  selectedJob: PropTypes.shape({
    id: PropTypes.string,
    numOpenings: PropTypes.number,
  }),
  user: PropTypes.shape({
    role: PropTypes.string,
  }),
  handleCancelJob: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  jobType: PropTypes.string,
  upcomingCount: PropTypes.number,
};

export default CancelJobDrawer;
