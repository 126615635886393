import React, { memo } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';

import DeselectButoon from '../../../assets/DeselectButton.svg';
import PlusButton from '../../../assets/PlusButton.svg';
import theme from '../../../theme';
import { minutesFromMidnightToTimeOnlyHrAndMins } from '../../../utils';
import MuiIconButton from '../../IconButton/IconButton';

const StyledAddedShiftBox = styled(Box)(() => ({
  fontFamily: get(['shiftPicker', 'typography', 'assignedShifts'], theme),
  fontSize: get(['shiftPicker', 'typography', 'shiftTimeFont'], theme),
  color: get(['shiftPicker', 'palette', 'assignedShifts', 'textColor'], theme),
  marginBottom: '5px',
}));

const StyledAddedShiftNameBox = styled(Box)(() => ({
  fontFamily: get(['shiftPicker', 'typography', 'assignedShifts'], theme),
  fontSize: get(['shiftPicker', 'typography', 'shiftNameHeader'], theme),
  color: get(['shiftPicker', 'palette', 'assignedShifts', 'shiftNameHeaderColor'], theme),
  marginBottom: '5px',
}));

const componentsShiftSelectorPalette = ['components', 'shiftSelector', 'palette'];
const ShiftSelectorCard = memo(
  ({ shift, onChange, isAnotherOptionSelected, setIsAnotherOptionSelected }) => {
    const [isSelected, setIsSelected] = React.useState(false);

    // Styling
    let cardBackgroundColor;
    switch (isSelected) {
      case false:
        // FIXME: Replace when dark mode colors are out

        cardBackgroundColor = get(
          [...componentsShiftSelectorPalette, 'unselectedBackgroundColor'],
          theme
        );

        break;

      case true:
        // FIXME: Replace when dark mode colors are out
        cardBackgroundColor = get(
          [...componentsShiftSelectorPalette, 'selectedBackgroundColor'],
          theme
        );

        break;
      default:
        cardBackgroundColor = get(
          [...componentsShiftSelectorPalette, 'unselectedBackgroundColor'],
          theme
        );
        break;
    }

    const handleAddOnClick = () => {
      setIsSelected(true);
      setIsAnotherOptionSelected(true);
      onChange(shift);
    };

    const handleDeselectOnClick = () => {
      setIsSelected(false);
      setIsAnotherOptionSelected(false);
      onChange(null);
    };

    const cardSx = {
      backgroundColor: cardBackgroundColor,
      margin: theme.spacing(0, 0, 1, 0),
      borderRadius: '16px',
    };

    return (
      <ListItem sx={cardSx} aria-label="shift-card">
        <Grid container flexDirection="column">
          <StyledAddedShiftNameBox component="span" key={`${get('name', shift)}`}>
            {get('name', shift)}
          </StyledAddedShiftNameBox>

          <StyledAddedShiftBox
            component="span"
            color="text.secondary"
            key={`${get('start', shift)}`}
          >
            {`${minutesFromMidnightToTimeOnlyHrAndMins(
              get('start', shift)
            )}-${minutesFromMidnightToTimeOnlyHrAndMins(get('end', shift))}`}
          </StyledAddedShiftBox>
        </Grid>
        {(!isSelected && !isAnotherOptionSelected && (
          <MuiIconButton
            onClick={handleAddOnClick}
            iconElement={
              <Box
                component="img"
                sx={{
                  height: '32px',
                  width: '32px',
                }}
                alt="Plus"
                src={PlusButton}
              />
            }
          />
        )) ||
          (isSelected && isAnotherOptionSelected && (
            <MuiIconButton
              onClick={handleDeselectOnClick}
              iconElement={
                <Box
                  component="img"
                  sx={{
                    height: '32px',
                    width: '32px',
                  }}
                  alt="Back"
                  src={DeselectButoon}
                />
              }
            />
          ))}
      </ListItem>
    );
  }
);
ShiftSelectorCard.propTypes = {
  shift: PropTypes.shape({}),
  onChange: PropTypes.func,
  isAnotherOptionSelected: PropTypes.bool,
  setIsAnotherOptionSelected: PropTypes.func,
};
export default ShiftSelectorCard;
