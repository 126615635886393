import React, { useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';

import selectUser from '../../store/selectors/appSelector';
import theme from '../../theme';
import { PRIMARY_FONT } from '../../theme/fontConstants';
import launchDarklyToggles from '../../utils/launchDarklyToggles';
import NoRosterCard from '../../views/jobs/jobList/detail/NoRosterCard';
import RosterCard from '../../views/jobs/jobList/detail/RosterCard';
import AddEmployeeDrawer from '../../views/jobs/jobList/detail/SubComponents/AddEmployeeDrawer';
import InviteEmployeeDrawer from '../../views/jobs/jobList/detail/SubComponents/InviteEmployeeDrawer';
import { inviteEmployee } from '../../views/jobs/jobList/detail/SubComponents/reducer';
import {
  cancelPlacement,
  updateJobRosterEmployee,
  updatePlacement,
} from '../../views/jobs/jobList/reducer';
import WaeButton from '../Button';

import ExportRosterToCSV from './ExportRosterToCSV';

const detailViewTypographySectionHeader = [
  'components',
  'detailView',
  'typography',
  'sectionHeader',
];

const detailHeaderStyle = {
  color: get([...detailViewTypographySectionHeader, 'fontColor'], theme),
  fontSize: get([...detailViewTypographySectionHeader, 'fontSize'], theme),
  fontFamily: get([...detailViewTypographySectionHeader, 'fontFamily'], theme),
  margin: '10px 0',
};

const RosterTab = ({
  jobType,
  flags,
  corporation,
  jobOrderId,
  cancelled,
  start,
  end,
  activeEmployees,
}) => {
  const [isAddEmployeeDrawerOpen, setIsAddEmployeeDrawerOpen] = useState(false);
  const [isInviteEmployeeDrawerOpen, setIsInviteEmployeeDrawerOpen] = useState(false);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const isRecruiterAllowedToCreateOrCancelPlacements = launchDarklyToggles(
    flags,
    'isRecruiterForbiddenToCreateCancelPlacements'
  ); // flag false==='forbiden'

  const onInviteEmployeesButtonClick = () => {
    setIsInviteEmployeeDrawerOpen(true);
  };

  const handleEmployeeUpdate = (employeeData) => {
    dispatch(updateJobRosterEmployee(employeeData));
  };

  const handleCancelPlacement = (placementId) => {
    dispatch(cancelPlacement({ id: placementId }));
  };

  const handleAddEmployees = (employee) => {
    setIsAddEmployeeDrawerOpen(true);
    dispatch(updatePlacement(employee));
  };
  const handleInviteEmployees = (employee) => {
    dispatch(inviteEmployee(employee));
    setIsInviteEmployeeDrawerOpen(true);
  };

  return (
    (jobOrderId && (
      <Box sx={{ width: '100%', padding: theme.spacing(1) }}>
        <Box
          sx={{
            ...detailHeaderStyle,
            display: 'flex',
          }}
        >
          {!!activeEmployees?.length && (
            <Box>
              {[UserRole.ADMIN, UserRole.RECRUITER].includes(user?.role) && (
                <WaeButton
                  disabled={
                    user?.role === UserRole.RECRUITER &&
                    !isRecruiterAllowedToCreateOrCancelPlacements
                  }
                  sx={{ marginRight: theme.spacing(1) }}
                  onClick={() => setIsAddEmployeeDrawerOpen(true)}
                  text={LanguageConverter('buttonText.addEmployees')}
                />
              )}
              {user?.role === UserRole.EMPLOYER && (
                <WaeButton
                  onClick={onInviteEmployeesButtonClick}
                  text={LanguageConverter('buttonText.inviteEmployees')}
                />
              )}
              <ExportRosterToCSV corporation={corporation} roster={activeEmployees} />
            </Box>
          )}
          <AddEmployeeDrawer
            isOpen={isAddEmployeeDrawerOpen}
            onClose={() => setIsAddEmployeeDrawerOpen(false)}
            handleAddEmployees={handleAddEmployees}
            jobOrderId={jobOrderId}
            corporation={corporation}
          />
          <InviteEmployeeDrawer
            isOpen={isInviteEmployeeDrawerOpen}
            onClose={() => setIsInviteEmployeeDrawerOpen(false)}
            handleInviteEmployees={handleInviteEmployees}
            jobOrderId={jobOrderId}
            corporation={corporation}
          />
        </Box>

        {activeEmployees?.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: theme.spacing(2),
            }}
          >
            <Typography sx={{ fontFamily: PRIMARY_FONT[800] }}>Employee</Typography>
            <Typography sx={{ fontFamily: PRIMARY_FONT[800] }}>Check In</Typography>
          </Box>
        )}

        {(activeEmployees?.length > 0 &&
          activeEmployees?.map((emp) => {
            const favorite = !!corporation.favorite.find((f) => f.waeID === emp.id);
            const placementId = emp?._id;

            return (
              <ListItem key={placementId} sx={{ padding: '0' }}>
                <RosterCard
                  jobType={jobType}
                  jobOrderId={jobOrderId}
                  fav={favorite}
                  start={start}
                  end={end}
                  candidate={emp}
                  corporationName={corporation.name}
                  placement={{
                    timecard: emp?.timecard,
                    start: emp?.start || emp?.jobOrderStart,
                    _id: emp?._id,
                  }}
                  onEmployeeUpdate={handleEmployeeUpdate}
                  handleCancelPlacement={() => handleCancelPlacement(placementId)}
                  isRecruiterPlacementBlocked={!isRecruiterAllowedToCreateOrCancelPlacements}
                />
              </ListItem>
            );
          })) || (
          <NoRosterCard
            isRecruiterPlacementBlocked={!isRecruiterAllowedToCreateOrCancelPlacements}
            onAddEmployeesClick={() => setIsAddEmployeeDrawerOpen(true)}
            onInviteEmployeesClick={onInviteEmployeesButtonClick}
            isCancelled={cancelled}
            currUserRole={user?.role}
          />
        )}
      </Box>
    )) || <Box>Lack of JobOrderId, no roster data </Box>
  );
};

RosterTab.propTypes = {
  jobType: PropTypes.string,
  activeEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
  flags: PropTypes.shape({}),
  jobOrderId: PropTypes.string,
  cancelled: PropTypes.bool,
  corporation: PropTypes.oneOfType([
    // TODO: Need to fix: by figuring out which type to be used!
    PropTypes.shape({
      favorite: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    PropTypes.string,
  ]),
  start: PropTypes.number,
  end: PropTypes.number,
};

export default withLDConsumer()(RosterTab);
