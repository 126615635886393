import React from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

import theme from '../../../theme';

import EmployeeSidebar from './Sidebar/Navigation';
import PhoneNav from './PhoneNav';

const Navigation = (props) => {
  const mediumScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return mediumScreen ? <EmployeeSidebar {...props} /> : <PhoneNav />;
};

export default Navigation;
