/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import theme from '../../../../theme';

const DailyEarningsBox = ({ timeCardInfo, sx }) => {
  const { day, date, earnings, hours } = timeCardInfo;
  const hasEarnings = earnings > 0;
  const dayCardTheme = get(['candidate', 'components', 'dashboard', 'time', 'dayCard'], theme);

  const sxForCard = {
    padding: theme.spacing(1),
    backgroundColor: hasEarnings
      ? get(['hasEarnings', 'backgroundColor'], dayCardTheme)
      : get(['noEarnings', 'backgroundColor'], dayCardTheme),
    borderRadius: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '15%',
  };

  const sxForDate = {
    color: hasEarnings
      ? get(['hasEarnings', 'fontColor'], dayCardTheme)
      : get(['noEarnings', 'fontColor'], dayCardTheme),
    fontFamily: get(['date', 'font'], dayCardTheme),
    paddingLeft: theme.spacing(1),
  };

  const sxForEarnings = {
    color: hasEarnings
      ? get(['hasEarnings', 'fontColor'], dayCardTheme)
      : get(['noEarnings', 'fontColor'], dayCardTheme),
    fontFamily: get(['earnings', 'font'], dayCardTheme),
  };

  return (
    <Box sx={{ ...sxForCard, ...sx }}>
      <Typography sx={{ ...sxForDate, fontSize: '12px' }}>{day}</Typography>
      <Typography sx={{ ...sxForDate, fontSize: '14px' }}>{date}</Typography>
      <Typography sx={{ ...sxForEarnings, fontSize: '14px' }}>{`$${earnings.toFixed(
        2
      )}`}</Typography>
      <Typography sx={{ ...sxForEarnings, fontSize: '12px' }}>{`${hours.toFixed(2)}`}</Typography>
    </Box>
  );
};

DailyEarningsBox.propTypes = {
  timeCardInfo: PropTypes.shape({
    day: PropTypes.string,
    date: PropTypes.string,
    earnings: PropTypes.number,
    hours: PropTypes.number,
  }),
  sx: PropTypes.shape({}),
};

export default DailyEarningsBox;
