import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';

import DeselectButton from '../../../../../assets/DeselectButton.svg';
import PlusButton from '../../../../../assets/PlusButton.svg';
import MuiIconButton from '../../../../../components/IconButton/IconButton';
import theme from '../../../../../theme/theme';

import FavoriteCard from './FavoriteCard';

const componentsShiftSelectorPalette = ['components', 'shiftSelector', 'palette'];

const SelectableFavoriteCard = ({ cardData, onChange, isSelected }) => {
  // Styling
  let cardBackgroundColor;
  switch (isSelected) {
    case false:
      // FIXME: Replace when dark mode colors are out

      cardBackgroundColor = get(
        [...componentsShiftSelectorPalette, 'unselectedBackgroundColor'],
        theme
      );

      break;

    case true:
      // FIXME: Replace when dark mode colors are out
      cardBackgroundColor = get(
        [...componentsShiftSelectorPalette, 'selectedBackgroundColor'],
        theme
      );

      break;
    default:
      cardBackgroundColor = get(
        [...componentsShiftSelectorPalette, 'unselectedBackgroundColor'],
        theme
      );
      break;
  }

  const cardSx = {
    backgroundColor: cardBackgroundColor,
    margin: theme.spacing(0, 0, 1, 0),
    borderRadius: '16px',
  };

  return (
    <ListItem sx={cardSx} key={get('waeID', cardData)} aria-label="shift-card">
      <Grid container flexDirection="column">
        <FavoriteCard fav={cardData} backgroundColor={cardBackgroundColor} />
      </Grid>
      {(!isSelected && (
        <MuiIconButton
          onClick={() => onChange(cardData)}
          iconElement={
            <Box
              component="img"
              sx={{
                height: '32px',
                width: '32px',
              }}
              alt="Plus"
              src={PlusButton}
            />
          }
        />
      )) ||
        (isSelected && (
          <MuiIconButton
            onClick={() => onChange(cardData)}
            iconElement={
              <Box
                component="img"
                sx={{
                  height: '32px',
                  width: '32px',
                }}
                alt="Back"
                src={DeselectButton}
              />
            }
          />
        ))}
    </ListItem>
  );
};

SelectableFavoriteCard.propTypes = {
  cardData: PropTypes.shape({ _id: PropTypes.string, waeID: PropTypes.string }),
  onChange: PropTypes.func,
  isSelected: PropTypes.bool,
};

export default React.memo(SelectableFavoriteCard);
