import React from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';

import SelectableFavoriteCard from './SelectableFavoriteCard';

const FavoriteListComponent = ({ favoriteList, favorites, onChange }) =>
  favoriteList.map((fav) => (
    <SelectableFavoriteCard
      isSelected={favorites.some((selected) => selected === get('waeID', fav))}
      cardData={fav}
      onChange={onChange}
      key={get('waeID', fav)}
    />
  ));

FavoriteListComponent.propTypes = {
  favorites: PropTypes.arrayOf(PropTypes.string),
  favoriteList: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
};

export default React.memo(FavoriteListComponent);
