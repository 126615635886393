import React, { memo } from 'react';
import { get } from 'lodash/fp';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';

import {
  selectLoadingRevenueMetrics,
  selectRevenueMetrics,
} from '../../../store/selectors/reportingSelectors';
import theme from '../../../theme';
import { ContentLabelCard } from '../metricsCards';
import MetricsHeader from '../metricsHeader';

const roleColorPath = ['views', 'reporting', 'palette', 'role'];

const RevenueMetrics = memo(() => {
  const { total, missing, extra } = useSelector(selectRevenueMetrics);
  const loadingRevenueMetrics = useSelector(selectLoadingRevenueMetrics);
  return (
    <Grid container item spacing={2} paddingBottom="16px">
      <MetricsHeader item text="Revenue" />

      {total !== null && (
        <ContentLabelCard
          sx={{ height: '136px' }}
          data={`$${total}`}
          loading={loadingRevenueMetrics}
          staticData={{
            color: get([...roleColorPath, 'default', 'secondary'], theme),
            label: 'Total',
          }}
        />
      )}
      {missing !== null && (
        <ContentLabelCard
          sx={{ height: '136px' }}
          data={`$${missing}`}
          loading={loadingRevenueMetrics}
          staticData={{
            color: get([...roleColorPath, 'default', 'secondary'], theme),
            label: 'Missing',
          }}
        />
      )}
      {extra !== null && (
        <ContentLabelCard
          sx={{ height: '136px' }}
          data={`$${extra}`}
          loading={loadingRevenueMetrics}
          staticData={{
            color: get([...roleColorPath, 'default', 'secondary'], theme),
            label: 'Extra',
          }}
        />
      )}
    </Grid>
  );
});

export default RevenueMetrics;
