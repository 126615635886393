/* eslint-disable  react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import get from 'lodash/fp/get';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import searchParamOptions from '@careerstart/wae-common/src/main/constants/searchParams';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import AlertBanner from '../../../components/Candidate/AlertBanner';
import DetailView from '../../../components/Candidate/DetailView/DetailView';
import LazyList from '../../../components/LazyList/LazyList';
import ScrollToTopButton from '../../../components/ScrollToTopButton';
import WaeTab from '../../../components/Tab/Tab';
import selectUser from '../../../store/selectors/appSelector';
import {
  selectIsLoadingPathSelectedPlacement,
  selectIsLoadingPlacementList,
  selectMyJobCount,
  selectMyJobsPage,
  selectPlacements,
  selectSelectedPlacement,
} from '../../../store/selectors/candidateJobsListSelectors';
import theme, { mobileViewContentHeight, navigationBarHeight } from '../../../theme';
import { isSearchParamValid } from '../../../utils';

import MyJobsDetail from './Details/MyJobsDetail';
import MyJobSearchBar from './subComponent/MyJobsSearchBar';
import CandidateJobTypeTab from './CandidateJobTypeTab';
import MyJobCard from './MyJobCard';
import MyJobEmptyCard from './MyJobEmptyCard';
import {
  getPathSelectedPlacementForCandidate,
  getPlacementsForCandidate,
  resetCandidateMyJobsList,
  setSelectedPlacement,
} from './reducer';

const MyJobsList = () => {
  const dispatch = useDispatch();
  const selectedPlacement = useSelector(selectSelectedPlacement);
  const isLoadingPlacementList = useSelector(selectIsLoadingPlacementList);
  const isLoadingPathSelectedPlacement = useSelector(selectIsLoadingPathSelectedPlacement);
  const muiTheme = useTheme();
  const mediumScreen = useMediaQuery(muiTheme.breakpoints.up('md'));
  const phoneScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const placements = useSelector(selectPlacements);

  const pageSize = 10;

  const alertBannerTheme = get(['candidate', 'components', 'alertBanner'], theme);
  const myJobCount = useSelector(selectMyJobCount);
  const myJobPage = useSelector(selectMyJobsPage);

  const headerRef = useRef();
  const gridRef = useRef();
  const user = useSelector(selectUser);
  const token = get('token', user);

  const now = +new Date();

  const [searchTerm, setSearchTerm] = useState('');

  const [customFilter, setCustomFilter] = useState({
    pointInTimeFilter: { operation: 'greaterThanEqual', field: 'end', value: now },
    statusFilter: {
      operation: 'equals',
      field: 'state.status',
      value: 'active',
    },
    nameFilter: {},
  });

  const [customSorts, setCustomSorts] = useState([
    {
      field: 'start',
      descending: false,
    },
  ]);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedPlacementId = searchParams.get(searchParamOptions.PLACEMENT);

  const closeDetailView = () => {
    dispatch(setSelectedPlacement({}));
    setSearchParams({});
  };

  const myJobCardClickHandler = (placementData) => {
    dispatch(setSelectedPlacement(placementData));
    setSearchParams({ [searchParamOptions.PLACEMENT]: get('_id', placementData) });
  };

  useEffect(() => {
    if (
      isLoadingPlacementList ||
      isLoadingPathSelectedPlacement ||
      !isSearchParamValid(selectedPlacementId) ||
      !(placements.length > 0)
    ) {
      return;
    }

    const match = placements.filter((row) => get(['_id'], row) === selectedPlacementId);
    if (match.length > 0) {
      dispatch(setSelectedPlacement(match[0]));
      return;
    }
    // Get the data
    dispatch(
      getPathSelectedPlacementForCandidate({
        filters: [{ field: '_id', value: selectedPlacementId, operation: 'equalsID' }],
      })
    );
  }, [dispatch, isLoadingPlacementList]);

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      if (searchTerm === '' && !(placements.length > 0) && !customFilter?.nameFilter?.value) {
        return;
      }

      if (searchTerm.length > 0) {
        setCustomFilter({
          ...customFilter,
          nameFilter: { operation: 'icontains', field: 'jobOrder.name', value: searchTerm },
        });
      } else {
        setCustomFilter({
          ...customFilter,
          nameFilter: {},
        });
      }
      closeDetailView();
    }, 750);
    return () => clearTimeout(delayedSearch);
  }, [searchTerm, dispatch, token]);

  const myJobCard = ({ item }) => (
    <MyJobCard
      key={item._id}
      itemClickHandler={myJobCardClickHandler}
      isConflicting={item?.isConflicting} //  TODO: check this again after backend set isConflicting
      start={item.jobOrder.start}
      end={item.jobOrder.end}
      id={item._id}
      title={item.jobOrder.name}
      corporation={item.corporation}
      payRate={item.jobOrder.payRateReg}
      shiftPay={item.jobOrder.shiftPay}
      placementData={item}
      shiftGroup={item.jobOrder.shiftGroup}
      selected={item._id === selectedPlacement._id}
    />
  );

  const generateFilters = () => {
    const filters = [
      // TODO: allow toggeling of cancelled placements
    ];
    if (get(['pointInTimeFilter', 'operation'], customFilter)) {
      filters.push(customFilter.pointInTimeFilter);
    }
    if (get(['nameFilter', 'operation'], customFilter)) {
      filters.push(customFilter.nameFilter);
    }
    if (get(['statusFilter', 'operation'], customFilter)) {
      filters.push(customFilter.statusFilter);
    }
    return filters;
  };

  const getNextData = () => {
    if (!isLoadingPlacementList) {
      const reqParams = {
        page: myJobPage,
        limit: pageSize,
        filters: generateFilters(),
        sortBy: customSorts,
      };
      dispatch(getPlacementsForCandidate(reqParams));
    }
  };

  useEffect(() => {
    const filters = generateFilters();
    dispatch(resetCandidateMyJobsList());
    if (!isLoadingPlacementList) {
      const reqParams = {
        page: 0,
        limit: pageSize,
        filters,
        sortBy: customSorts,
      };
      dispatch(getPlacementsForCandidate(reqParams));
    }
  }, [customFilter]);

  const tabData = [
    {
      tabLabel: 'Upcoming',
    },
    {
      tabLabel: 'Pending',
    },
    {
      tabLabel: 'Completed',
    },
  ];

  const onTabChange = (newVal) => {
    if (newVal === 0) {
      setCustomSorts([
        {
          field: 'start',
          descending: false,
        },
      ]);
      setCustomFilter({
        ...customFilter,
        pointInTimeFilter: {
          operation: 'greaterThanEqual',
          field: 'end',
          value: now,
        },
        statusFilter: {
          operation: 'equals',
          field: 'state.status',
          value: 'active',
        },
      });
    }
    if (newVal === 1) {
      setCustomSorts([
        {
          field: 'start',
          descending: false,
        },
      ]);
      setCustomFilter({
        ...customFilter,
        pointInTimeFilter: {
          operation: 'greaterThanEqual',
          field: 'end',
          value: now,
        },
        statusFilter: {
          operation: 'equals',
          field: 'state.status',
          value: 'pendingEmployerApproval',
        },
      });
    }
    if (newVal === 2) {
      setCustomSorts([
        {
          field: 'end',
          descending: true,
        },
      ]);
      setCustomFilter({
        ...customFilter,
        pointInTimeFilter: {
          operation: 'before',
          field: 'end',
          value: +new Date(),
          // operation: 'before',
          // field: 'end',
          // value: now,
        },
        statusFilter: {
          operation: 'equals',
          field: 'state.status',
          value: 'active',
        },
      });
    }
  };

  return (
    <Grid
      container
      sx={{
        height: phoneScreen ? `calc(100vh - ${navigationBarHeight})` : mobileViewContentHeight,
        paddingTop: navigationBarHeight,
      }}
      ref={gridRef}
    >
      <Slide
        direction="right"
        unmountOnExit
        in={!(!mediumScreen && !!selectedPlacement._id)}
        timeout={10}
        easing={{ enter: 'step-end', exit: 'step-start' }}
      >
        <Grid
          item
          container
          md
          xs
          sm
          sx={{
            height: 'inherit',
            marginRight: !!selectedPlacement.positionTemplate && !!selectedPlacement.location && 2,
            overflowY: 'auto',
            '&::-webkit-scrollbar': { display: 'none' },
            // display: 'block',
          }}
        >
          <Box>
            <div ref={headerRef}>
              <CandidateJobTypeTab initVal="myJobs" />
            </div>
            <Box sx={{ width: '100%', margin: theme.spacing(2, 0, 2, 0) }}>
              <AlertBanner
                LeftIcon={WarningAmberIcon}
                CloseIcon={CloseIcon}
                message="Earnings displayed are pre-tax and do not include any withhold amounts. The final amount
          received may be different after taxes and other deductions have been taken out."
                backgroundColor={get(['backgroundColor'], alertBannerTheme)}
                messageSx={{
                  color: get(['fontColor'], alertBannerTheme),
                  fontFamily: get(['font'], alertBannerTheme),
                }}
              />
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: theme.spacing(2, 0, 2, 0),
                }}
              >
                <WaeTab data={tabData} onChange={onTabChange} background="default" />
                <MyJobSearchBar placeholder="Job name" onValueChange={setSearchTerm} />
              </Box>
            </Box>
            {isLoadingPlacementList && myJobPage === 0 ? (
              <Grid
                sx={{
                  width: '100%',
                  height: '95%',
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: '15%',
                }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid sx={{ width: '100%' }}>
                <LazyList
                  Card={myJobCard}
                  getNextData={getNextData}
                  items={placements}
                  itemCount={myJobCount}
                  skeletonVariant="Text"
                  skeletonBackgroundColor={theme.components.detailView.palette.lightLazyLoadColor}
                  NoDataCard={MyJobEmptyCard}
                />

                <ScrollToTopButton headerRef={headerRef} />
              </Grid>
            )}
          </Box>
        </Grid>
      </Slide>

      {selectedPlacement._id && <DetailView Content={MyJobsDetail} close={closeDetailView} />}
    </Grid>
  );
};

export default MyJobsList;
