import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import SnackbarBase from '@mui/material/Snackbar';

import selectSnackbarData from '../../store/selectors/snackbarSelector';
import { hideSnackbar } from '../../views/app/reducer';

// TODO: HB-960 add state to props
const Snackbar = () => {
  const dispatch = useDispatch();
  const snackbarData = useSelector(selectSnackbarData);
  const { message, isOpen } = snackbarData;
  const intl = useIntl();
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {message && (
        <SnackbarBase
          autoHideDuration={4000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          message={intl.formatMessage({ id: message })}
          open={isOpen}
          onClose={() => dispatch(hideSnackbar())}
        />
      )}
    </>
  );
};

export default Snackbar;
