import React, { useEffect } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Form as RFForm } from 'react-final-form';
import { useDispatch } from 'react-redux';

import readForJobOrderSchema from '@careerstart/wae-common/schema/placements/read-for-job-order/post.req.json';
import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

import Button, { BUTTON_TYPE, BUTTON_VARIANT } from '../../../../../components/Button';
import { buildFormField } from '../../../../../components/Form/formFieldFactory';
import { validateSchema } from '../../../../../components/Form/validations';
import theme from '../../../../../theme';
import { getJobRoster } from '../../reducer';

import FormFieldDataRoster from './FormFieldDataRoster';

const componentsDetailViewSectionHeaders = [
  'components',
  'detailView',
  'typography',
  'sectionHeader',
];
const detailViewTypographyDetailText = ['components', 'detailView', 'typography', 'detailText'];

const viewHeaderStyling = {
  fontSize: get([...componentsDetailViewSectionHeaders, 'fontSize'], theme),
  fontFamily: get([...componentsDetailViewSectionHeaders, 'fontFamily'], theme),
  color: get([...componentsDetailViewSectionHeaders, 'fontColor'], theme),
};

const subHeaderStyling = {
  fontSize: get([...detailViewTypographyDetailText, 'fontSize'], theme),
  fontFamily: get([...detailViewTypographyDetailText, 'fontFamily'], theme),
  color: get([...detailViewTypographyDetailText, 'fontColor'], theme),
};

const PlacementsForm = ({ initialValues, onUpdate, user }) => {
  const { id } = initialValues;

  const formFieldData = FormFieldDataRoster(user?.role);
  const validRoles = [UserRole.ADMIN, UserRole.RECRUITER];
  const isValidRole = validRoles.includes(user?.role);

  const dispatch = useDispatch();

  useEffect(() => {
    const query = { jobOrder: id, limit: 250 };
    if (Object.keys(validateSchema(query, readForJobOrderSchema)).length === 0) {
      dispatch(getJobRoster(query));
    }
  }, [dispatch, id]);

  return (
    <RFForm
      onSubmit={(values) => {
        onUpdate(values, initialValues);
      }}
      initialValues={initialValues}
      render={({ handleSubmit, pristine }) => (
        <form
          onSubmit={handleSubmit}
          style={{
            width: '100%',
            paddingTop: theme.spacing(2),
          }}
        >
          <Grid container>
            <Typography sx={{ ...viewHeaderStyling }}>Fill Rate</Typography>

            <Grid item sx={{ width: '100%' }}>
              {buildFormField(formFieldData.fillRatio)}
            </Grid>

            <Typography
              sx={{ ...viewHeaderStyling, margin: theme.spacing(2, 0, 0, 0), width: '100%' }}
            >
              Shift Placements
            </Typography>
            <Grid container item spacing={2}>
              <Grid
                item
                xs
                sx={{
                  padding: theme.spacing(1, 0, 0, 0),
                }}
              >
                <Typography
                  sx={{
                    ...subHeaderStyling,
                  }}
                >
                  {LanguageConverter('roster.form.openings')}
                </Typography>
                <Box>{buildFormField(formFieldData.numOpenings)}</Box>
              </Grid>
              <Grid item xs sx={{ paddingTop: theme.spacing(1) }}>
                <Typography sx={{ ...subHeaderStyling }}>
                  {LanguageConverter('roster.form.placements')}
                </Typography>
                <Box>{buildFormField(formFieldData.placementsCount)}</Box>
              </Grid>
              {isValidRole && (
                <Grid item xs sx={{ paddingTop: theme.spacing(1) }}>
                  <Typography sx={{ ...subHeaderStyling }}>
                    {' '}
                    {LanguageConverter('roster.form.overStaffing')}
                  </Typography>
                  <Box>{buildFormField(formFieldData.overStaffOpenings)}</Box>
                </Grid>
              )}

              {pristine ? null : (
                <Grid item container>
                  <Button
                    type={BUTTON_TYPE.SUBMIT}
                    variant={BUTTON_VARIANT.OUTLINED}
                    text={LanguageConverter('roster.button.update')}
                    testid="update-openings"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

PlacementsForm.propTypes = {
  container: PropTypes.shape({}),
  initialValues: PropTypes.shape({
    id: PropTypes.string,
  }),
  onUpdate: PropTypes.func,
  user: PropTypes.shape({ role: PropTypes.string }),
};

export default PlacementsForm;
