import React, { useCallback, useMemo } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import BackdropCircularProgress from '../../../../components/BackdropCircularProgress';
import RosterWidget from '../../../../components/RosterWidget';
import {
  selectIsCancelledPlacementUpdating,
  selectJobRoster,
} from '../../../../store/selectors/jobListSelectors';
import theme from '../../../../theme';
import { BLACK } from '../../../../theme/colorConstants';
import { updateCancelledPlacement } from '../reducer';

const componentsDetailViewSectionHeaders = [
  'components',
  'detailView',
  'typography',
  'sectionHeader',
];

const viewHeaderStyling = {
  fontSize: get([...componentsDetailViewSectionHeaders, 'fontSize'], theme),
  fontFamily: get([...componentsDetailViewSectionHeaders, 'fontFamily'], theme),
  color: get([...componentsDetailViewSectionHeaders, 'fontColor'], theme),
};

const RosterForm = ({ initialValues, jobType }) => {
  const { corporation, cancelled, full, start, end, placements, id } = initialValues;
  const jobRoster = useSelector(selectJobRoster);
  const isCancelledPlacementUpdating = useSelector(selectIsCancelledPlacementUpdating);

  const employees = useMemo(() => jobRoster?.[id]?.employees || [], [jobRoster, id]);

  const dispatch = useDispatch();

  const handelOnCancelledPlacementUpdate = useCallback(
    (val) => {
      dispatch(updateCancelledPlacement(val));
    },
    [dispatch]
  );

  return (
    (
      <Box
        sx={{
          width: '100%',
          overflowY: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
          maxHeight: '80vh',
        }}
      >
        <Typography sx={{ ...viewHeaderStyling, width: '100%' }}>Roster</Typography>
        <Box
          sx={{
            width: '100%',
            marginTop: theme.spacing(1),
            backgroundColor: BLACK[100],
            borderRadius: theme.spacing(2),
            padding: theme.spacing(1, 2),
          }}
        >
          <RosterWidget
            cancelled={cancelled}
            corporation={corporation}
            employees={employees}
            placements={placements}
            jobType={jobType}
            jobOrderId={id}
            full={full}
            handelOnCancelledPlacementUpdate={handelOnCancelledPlacementUpdate}
            isCancelledPlacementUpdating={isCancelledPlacementUpdating}
            start={start}
            end={end}
          />
        </Box>
      </Box>
    ) || <BackdropCircularProgress />
  );
};

RosterForm.propTypes = {
  container: PropTypes.shape({}),
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    corporation: PropTypes.shape({ name: PropTypes.string }),
    cancelled: PropTypes.bool,
    full: PropTypes.bool,
    placements: PropTypes.arrayOf(PropTypes.shape({})),
    start: PropTypes.number,
    end: PropTypes.number,
    title: PropTypes.string,
    shiftName: PropTypes.string,
  }),
  DNR: PropTypes.shape({}),
  DNA: PropTypes.shape({}),
  jobType: PropTypes.string,
  user: PropTypes.shape({ role: PropTypes.string }),
};

export default RosterForm;
