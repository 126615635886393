import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';

import Button, { BUTTON_VARIANT } from '../../../../components/Button';
import ListHeader from '../../../../components/ListHeader';

const TimekeepingPageHeader = ({ user, handleOnExportClick, approveWeek }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    }}
  >
    <ListHeader headerText="Timekeeping" />
    {get('role', user) === UserRole.ADMIN && (
      <Box>
        <Button
          variant={BUTTON_VARIANT.TEXT}
          onClick={handleOnExportClick}
          text={LanguageConverter('button.export.csv')}
        />
      </Box>
    )}

    {user?.role === UserRole.ADMIN || user?.role === UserRole.RECRUITER ? (
      <> </>
    ) : (
      <Grid>
        <Button
          sx={{ height: '40px', width: '173px' }}
          text={LanguageConverter('button.batch.approve')}
          onClick={() => approveWeek()}
        />
      </Grid>
    )}
  </Box>
);
export default TimekeepingPageHeader;

TimekeepingPageHeader.propTypes = {
  user: PropTypes.shape({ role: PropTypes.string }),
  handleOnExportClick: PropTypes.func,
  approveWeek: PropTypes.func,
};
