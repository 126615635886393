import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import theme from '../../../theme';

const JobTypeTab = ({ initVal }) => {
  const [value, setValue] = React.useState(initVal);
  const dayJobsUrl = '/jobs/jobsList';
  const longTermUrl = '/jobs/longTermJobsList';
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 'dayJobs') {
      navigate(dayJobsUrl);
    }
    if (newValue === 'longTermJobs') {
      navigate(longTermUrl);
    }
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      TabIndicatorProps={{ style: { background: theme.dataGrid.bgColor.tabIndicatorColor } }}
      sx={{ minWidth: 'fit-content' }}
    >
      <Tab
        value="dayJobs"
        label="Day Jobs"
        style={{ textTransform: 'none' }}
        sx={{
          '&.Mui-selected': {
            color: `${theme.palette.text.primary}`,
          },
          color: `${theme.palette.subtext.dark}`,
          fontFamily: `${theme.tab.default.boldFontFamily}`,
          fontSize: `${theme.tab.default.boldFontSize}`,
          padding: 0,
        }}
      />
      <Tab
        style={{ textTransform: 'none' }}
        value="longTermJobs"
        label="Long Term Jobs"
        sx={{
          '&.Mui-selected': {
            color: `${theme.palette.text.primary}`,
          },
          color: `${theme.palette.subtext.dark}`,
          fontFamily: `${theme.tab.default.boldFontFamily}`,
          fontSize: `${theme.tab.default.boldFontSize}`,
          padding: theme.spacing(0, 0, 0, 2),
        }}
      />
    </Tabs>
  );
};

JobTypeTab.propTypes = {
  initVal: PropTypes.string,
};

export default JobTypeTab;
