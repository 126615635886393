const FILTERS = {
  UPCOMING: [
    // upcoming jobs
    { field: 'end', operation: 'after', value: +new Date() },
  ],
  COMPLETED: [
    // completed
    { field: 'end', operation: 'before', value: +new Date() },
  ],
};
export default FILTERS;
