import React from 'react';
import { useDispatch } from 'react-redux';

import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { logout } from '../../../../views/app';

const UserAccountWidget = () => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleUserAccountMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserAccountMenuClose = () => {
    setAnchorEl(null);
  };

  const handleUserLogOut = () => {
    dispatch(logout());
    handleUserAccountMenuClose();
  };

  const userAccountMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id="userAccountMenu-id"
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleUserAccountMenuClose}
    >
      <MenuItem onClick={handleUserAccountMenuClose}>
        <Typography>My account</Typography>
      </MenuItem>
      <MenuItem onClick={handleUserLogOut}>
        <Typography>Log out</Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true"
        color="inherit"
        onClick={handleUserAccountMenuOpen}
      >
        <AccountCircle />
      </IconButton>
      {userAccountMenu}
    </>
  );
};

export default UserAccountWidget;
