const status = {
  field: 'status',
  headerName: 'Status',
};

const addressColumn = {
  field: 'address',
  headerName: 'Location',
};

const corporationColumn = {
  field: 'corporation',
  headerName: 'Corporation',
};

const endDate = {
  field: 'endDate',
  headerName: 'End Date',
};

const endTime = {
  field: 'endTime',
  headerName: 'End Time',
};

const name = {
  field: 'jobInfo',
  headerName: 'Job',
};

const fillRatio = {
  field: 'fillRatio',
  headerName: 'Fill Rate',
};

const startDate = {
  field: 'startDate',
  headerName: 'Start Date',
};

const startTime = {
  field: 'startTime',
  headerName: 'Start Time',
};

const getJobListColumnData = ({
  address,
  allColumnsSelected,
  orderStatus,
  corporation,
  position,
  date,
  time,
  fill,
  allowCorporation,
}) => {
  const columns = [];
  if (orderStatus || allColumnsSelected) {
    columns.push(status);
  }
  if ((corporation || allColumnsSelected) && allowCorporation) {
    columns.push(corporationColumn);
  }
  if (position || allColumnsSelected) {
    columns.push(name);
  }
  if (address || allColumnsSelected) {
    columns.push(addressColumn);
  }
  if (date || allColumnsSelected) {
    columns.push(startDate);
    columns.push(endDate);
  }
  if (time || allColumnsSelected) {
    columns.push(startTime);
    columns.push(endTime);
  }
  if (fill || allColumnsSelected) {
    columns.push(fillRatio);
  }

  return columns;
};

export default getJobListColumnData;
