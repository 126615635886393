import React, { useCallback, useEffect, useRef, useState } from 'react';
import { first, get, getOr } from 'lodash/fp';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';

import theme from '../../theme';
import { useDims } from '../../utils/customHooks';
import BackdropCircularProgress from '../BackdropCircularProgress';
import getUniqueFilters from '../Filters/filterHelpers';
import Filters from '../Filters/Filters';
import PopOverFilter from '../Filters/PopoverFilter';

import Toolbar from './Toolbar/Toolbar';
import TablePagination from './TablePagination';

const WaeDataGrid = ({
  additiveFilters,
  columnData,
  columnMenu,
  FilterLeftComponent,
  getRowHeight,
  customFilter,
  sortModel,
  isLoading,
  selectionModel,
  onSelectionModelChange,
  page,
  pageSize,
  pagination,
  paginatedData,
  paginationQuery,
  setPageSize,
  setPage,
  sx,
  toolbar = {},
  totalRowCount = 0,
  treeData,
}) => {
  // Attch these ref to a div, then useDims to get the realtime size
  const mainContainerRef = useRef();
  const rightFiltersRef = useRef();
  const leftFilterRef = useRef();
  const popOverFilterRef = useRef();

  const mainContainerDims = useDims(mainContainerRef);
  const rightFiltersDims = useDims(rightFiltersRef);
  const leftFilterDims = useDims(leftFilterRef || <div />);
  const popoverDims = useDims(popOverFilterRef);

  // Should the left filter be in-line, or put into the popover
  const [leftFilterShowCondition, setLeftFilterShowCondition] = useState(true);

  // Should the filters on the right be a popover or in line
  const [shouldFiltersCollapse, setShouldFiltersCollapse] = useState(true);

  const CustomizedDataGridStyle = {
    border: '0',
    fontFamily: theme.dataGrid.default.textFont,
    color: theme.dataGrid.default.textColor,
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: theme.dataGrid.bgColor.oddRowBgColor,
    },
    [`& .${gridClasses.row}`]: {
      '&:hover, &.Mui-hovered': {
        backgroundColor: theme.dataGrid.hover.defaultHoverColor,
        '@media (hover: none)': {
          backgroundColor: theme.dataGrid.hover.defaultHoverColor,
        },
      },
      '&.Mui-selected': {
        backgroundColor: theme.dataGrid.bgColor.selectedBgColor,
        color: theme.dataGrid.default.selectedTextColor,
        '&:hover, &.Mui-hovered': {
          backgroundColor: theme.dataGrid.hover.selectedHoverColor,
          '@media (hover: none)': {
            backgroundColor: theme.dataGrid.hover.selectedHoverColor,
          },
        },
      },
    },
    [`& .${gridClasses.row}`]: {
      '&:hover, &.Mui-hovered': {
        backgroundColor: theme.dataGrid.hover.defaultHoverColor,
        '@media (hover: none)': {
          backgroundColor: theme.dataGrid.hover.defaultHoverColor,
        },
      },
      '&.Mui-selected': {
        backgroundColor: theme.dataGrid.bgColor.selectedBgColor,
        color: 'white',
        '&:hover, &.Mui-hovered': {
          backgroundColor: theme.dataGrid.hover.selectedHoverColor,
          '@media (hover: none)': {
            backgroundColor: theme.dataGrid.hover.selectedHoverColor,
          },
        },
        '& .info-cell': {
          '& .MuiBox-root': {
            color: theme.dataGrid.default.selectedTextColor,
          },
          '& .info-cell-light': {
            color: theme.dataGrid.jobInfo.light,
          },
        },
      },
    },

    [`& .${gridClasses.cell}`]: {
      borderBottom: '0',

      '&:focus-within': {
        outline: 'none',
      },
    },
    [`& .${gridClasses.columnHeaders}`]: {
      borderBottom: '0',
      borderTop: '0',

      backgroundColor: theme.dataGrid.bgColor.columnHeaderBgColor,
      '&:focus-within': {
        outline: 'none',
      },
    },
    [`& .${gridClasses.columnSeparator}`]: {
      borderBottom: '0',
      borderTop: '0',

      opacity: '0 !important',
      '&:focus-within': {
        outline: 'none',
      },
    },

    [`& .${gridClasses.main}`]: {
      border: `0`,
      borderRadius: '16px',
    },
    '& .theme-header': {
      fontFamily: theme.dataGrid.default.headerFont,
      color: theme.dataGrid.default.headerColor,
    },
  };

  const CustomizedScrollBar = {
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
      width: '0.4em',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
      background: theme.components.scrollBar.trackColor,
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
      backgroundColor: theme.components.scrollBar.thumbColor,
      borderRadius: '99px',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
      background: theme.components.scrollBar.thumbHover,
    },
  };

  const [queryOptions, setQueryOptions] = React.useState({
    filterModel: {
      items: [],
    },
  });
  const [customFilterOptions, setCustomFilterOptions] = React.useState(customFilter || {});

  const updateQueryOptions = useCallback(
    (filters) => {
      setQueryOptions((prevState) => getUniqueFilters(prevState, filters));
      setPage(0);
    },
    [setPage]
  );

  const handleSortModelChange = useCallback(
    (newSortModel) => {
      setQueryOptions((prevState) => ({ ...prevState, sortModel: newSortModel?.[0] }));
      setPage(0);
    },
    [setPage]
  );

  useEffect(() => {
    setQueryOptions((prevState) => ({ ...prevState, sortModel }));
  }, [sortModel]);

  useEffect(() => {
    const sortBy = queryOptions?.sortModel || null;
    paginationQuery({
      filters: [
        ...getOr([], ['filterModel', 'items'], customFilterOptions),
        ...getOr([], ['filterModel', 'items'], queryOptions),
      ],
      limit: pageSize,
      page,
      sortBy:
        (sortBy && [{ field: get('field', sortBy), descending: get('sort', sortBy) === 'desc' }]) ||
        undefined,
    });
  }, [page, pageSize, paginationQuery, queryOptions, customFilterOptions]);

  useEffect(() => {
    if (customFilter) {
      setCustomFilterOptions((prevState) => ({ ...prevState, ...customFilter }));
      setPage(0);
    }
  }, [customFilter, setPage]);

  const onFilterChange = useCallback(() => {
    // TODO: Handle popover filter persist
  }, []);

  useEffect(() => {
    // Check if the page is big enough for the left fitler and the popover button
    // If true (container is bigger), display left fitler in-line. If false display in popover modal
    setLeftFilterShowCondition(
      mainContainerDims.width - popoverDims.width - leftFilterDims.width > 0
    );

    // Check if the page is too small for the left fitler and the buttons in-line
    // If false (container is bigger), display filters inline - if true (smaller), display in popover
    setShouldFiltersCollapse(
      mainContainerDims.width - leftFilterDims.width - rightFiltersDims.width < 0
    );
  }, [leftFilterDims.width, mainContainerDims, popoverDims.width, rightFiltersDims.width]);

  return (
    paginatedData && (
      <>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: theme.dataGridFiltersHeight,
            padding: theme.spacing(2, 0, 2),
          }}
          ref={mainContainerRef}
        >
          <div
            style={{
              display: 'flex',
              width: mainContainerDims.width,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              ref={leftFilterRef}
              style={{
                visibility: leftFilterShowCondition ? 'visible' : 'hidden',
                position: leftFilterShowCondition ? null : 'fixed',
              }}
            >
              {FilterLeftComponent}
            </div>
            <Grid
              container
              justifyContent="flex-end"
              sx={{
                minWidth: 'fit-content',
                maxWidth: 'max-content',
                visibility: shouldFiltersCollapse ? 'hidden' : 'visible',
                position: shouldFiltersCollapse ? 'fixed' : null,
                height: theme.dataGridFiltersHeight,
              }}
              ref={rightFiltersRef}
            >
              <Filters
                filterDefinitions={additiveFilters}
                updateQueryOptions={updateQueryOptions}
                onFilterChange={onFilterChange}
              />
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              sx={{
                minWidth: 'fit-content',
                maxWidth: 'max-content',
                visibility: !shouldFiltersCollapse ? 'hidden' : 'visible',
                position: !shouldFiltersCollapse ? 'fixed' : null,
                height: theme.dataGridFiltersHeight,
              }}
              ref={popOverFilterRef}
            >
              <PopOverFilter
                items={additiveFilters}
                updateQueryOptions={updateQueryOptions}
                leftFilter={leftFilterShowCondition ? null : FilterLeftComponent}
                onFilterChange={onFilterChange}
              />
            </Grid>
          </div>
        </Box>

        <DataGridPro
          columns={columnData.map((col) => ({
            ...col,
            filterable: !!col.filterOperators, // This sets whether or not the column will have a static filter generated for it
            sortable: col.isSortable,
          }))}
          components={{
            Toolbar: () => <Toolbar {...toolbar} />,
            ColumnMenu: columnMenu,
            LoadingOverlay: LinearProgress,
            Pagination: () => (
              <TablePagination
                isLoading={isLoading}
                page={page}
                pageSize={pageSize}
                rowCount={totalRowCount}
                setPage={setPage}
                setPageSize={setPageSize}
                rowsLength={paginatedData.length}
              />
            ),
          }}
          componentsProps={{ pagination: { disabled: true } }}
          disableColumnMenu
          disableVirtualization
          hideFooterSelectedRowCount
          disableMultipleSelection
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          getRowHeight={getRowHeight}
          loading={isLoading}
          onFilterModelChange={updateQueryOptions}
          onSelectionModelChange={(newSelectionModel) => {
            onSelectionModelChange(
              paginatedData.find((row) => get('id', row) === first(newSelectionModel))
            );
          }}
          selectionModel={selectionModel}
          onSortModelChange={handleSortModelChange}
          pagination={pagination}
          paginationMode="server"
          rowCount={totalRowCount}
          rows={paginatedData}
          sortingMode="server"
          filterMode="server"
          sx={{ ...CustomizedDataGridStyle, ...CustomizedScrollBar, sx }}
          treeData={treeData}
        />
        {isLoading && <BackdropCircularProgress />}
      </>
    )
  );
};

WaeDataGrid.propTypes = {
  additiveFilters: PropTypes.arrayOf(PropTypes.shape({})),
  columnData: PropTypes.arrayOf(PropTypes.shape({})),
  columnMenu: PropTypes.func,
  FilterLeftComponent: PropTypes.element,
  customFilter: PropTypes.shape({}),
  sortModel: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  getRowHeight: PropTypes.func,
  onSelectionModelChange: PropTypes.func,
  selectionModel: PropTypes.string,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  pagination: PropTypes.bool,
  paginatedData: PropTypes.arrayOf(PropTypes.shape({})),
  paginationQuery: PropTypes.func,
  setPageSize: PropTypes.func,
  setPage: PropTypes.func,
  sx: PropTypes.shape({}),
  tabProps: PropTypes.shape({
    tabData: PropTypes.arrayOf(PropTypes.shape({})),
    onTabChange: PropTypes.func,
    background: PropTypes.string,
  }),
  toolbar: PropTypes.shape({
    exportCsv: PropTypes.shape({}),
    exportPaginatedCsv: PropTypes.shape({}),
  }),
  totalRowCount: PropTypes.number,
  treeData: PropTypes.bool,
};

export default WaeDataGrid;
