import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import JobUpdateRequestSchema from '@careerstart/wae-common/schema/job-order/update.patch.req.json';
import readForJobOrderSchema from '@careerstart/wae-common/schema/placements/read-for-job-order/post.req.json';
import { JOB_CATEGORY } from '@careerstart/wae-common/src/main/constants/jobInformation';
import searchParamOptions from '@careerstart/wae-common/src/main/constants/searchParams';
import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import BackdropCircularProgress from '../../../components/BackdropCircularProgress';
import WaeButton, { BUTTON_VARIANT } from '../../../components/Button';
import PaginatedDataGrid from '../../../components/DataGrid';
import DetailView from '../../../components/DetailView/DetailView2';
import ExportView from '../../../components/ExportView/ExportView';
import DateRangePickerFilter from '../../../components/Filters/DateRangePickerFilter';
import FreeSearchFilter from '../../../components/Filters/FreeSearchFilter';
import SearchableSelectDropdownFilter from '../../../components/Filters/SearchableSelectDropdownFilter';
import { validateSchema } from '../../../components/Form/validations';
import Tab from '../../../components/Tab';
import selectUser from '../../../store/selectors/appSelector';
import {
  selectIsCancelProcessing,
  selectIsCreateJobsDialogOpen,
  selectIsLoading,
  selectIsLoadingPt,
  selectIsUpdateProcessing,
  selectJobListRowData,
  selectPositionTemplateData,
  selectSelectedJobId,
  selectTotalRowCount,
} from '../../../store/selectors/jobListSelectors';
import theme, {
  dataGridFiltersHeight,
  listHeaderHeight,
  navigationBarHeight,
} from '../../../theme';
import { getAllSearchParams, isSearchParamValid } from '../../../utils';
import { useDims } from '../../../utils/customHooks';
import launchDarklyToggles from '../../../utils/launchDarklyToggles';
import { showSnackbar } from '../../app';
import CreateJobsModal from '../createJobs';

import CreateJobDrawer from './CreateJobDrawer/CreateJobDrawer';
import Overview from './detail/Overview';
import RosterForm from './detail/RosterForm';
import ShiftDetails from './detail/ShiftDetails';
import CancelJobModal from './CancelJobModal';
import filters from './filters';
import getJobListColumnData from './getJobListColumnData';
import JobTypeTab from './JobTypeTab';
import {
  getCorporationsForJobsList,
  getJobRoster,
  getJobsList,
  getPathSelectedJob,
  getPositionTemplate,
  setIsCreateJobsDialogOpen,
  setSelectedJob,
  setSelectedJobType,
  updateJob,
} from './reducer';

const RootGrid = styled(Grid)(() => ({
  height: `calc(100vh - ${navigationBarHeight})`,
  position: 'relative',
  top: navigationBarHeight,
  padding: theme.spacing(2, 0),
}));

const additiveFilters = [
  {
    customFilter: {
      field: 'corporation',
      operation: 'equalsID',
      optionsAPICallback: {
        httpMethod: 'POST',
        route: 'corporations/read',
        generateBody: (searchTerm) => ({
          filters: [{ operation: 'icontains', field: 'name', value: searchTerm }],
        }),
      },
      getOptionLabel: (option) => option?.name,
      placeholder: 'Corporation',
      type: SearchableSelectDropdownFilter,
    },
  },
  {
    customFilter: {
      field: { start: 'start', end: 'end' },
      operation: { start: 'onOrAfter', end: 'onOrBefore' },
      placeholder: 'Date Range',
      type: DateRangePickerFilter,
    },
  },
  {
    customFilter: {
      field: 'name',
      operation: 'icontains',
      placeholder: 'Name',
      type: FreeSearchFilter,
    },
  },
];

const API_PATH = 'job-orders/read';

const JobsList = ({ flags }) => {
  const dispatch = useDispatch();
  const muiTheme = useTheme();
  const mediumScreen = useMediaQuery(muiTheme.breakpoints.up('md'));
  const largeScreen = useMediaQuery('(min-width:1910px)');

  const [searchParams, setSearchParams] = useSearchParams();
  const pathSelectedJobId = searchParams.get(searchParamOptions.JOB_ORDER);
  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector(selectUser);
  const isLoading = useSelector(selectIsLoading);
  const isCancelProcessing = useSelector(selectIsCancelProcessing);
  const enterpriseJobs = useSelector(selectJobListRowData);
  const totalRowCount = useSelector(selectTotalRowCount);
  const isUpdateProcessing = useSelector(selectIsUpdateProcessing);
  const positionTemplateData = useSelector(selectPositionTemplateData);
  const isLoadingPt = useSelector(selectIsLoadingPt);
  const isCreateJobsDialogOpen = useSelector(selectIsCreateJobsDialogOpen);
  const selectedJobId = useSelector(selectSelectedJobId);

  const jobListColumnData = useMemo(() => getJobListColumnData(user.role), [user]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(10);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [isExportOpen, setIsExportOpen] = useState(false);
  const [createJobType, setCreateJobType] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [customFilters, setCustomFilters] = useState({
    // initial display joblist is upcoming joblist
    filterModel: {
      items: filters.UPCOMING_JOBS,
    },
  });
  const [sortModel, setSortModel] = useState({ field: 'start', sort: 'asc' });
  const isAdmin = useMemo(() => user?.role === UserRole.ADMIN, [user]);

  const selectedJob = useMemo(
    () => enterpriseJobs.find((job) => job?.id === selectedJobId),
    [enterpriseJobs, selectedJobId]
  );

  const longTermToggle = launchDarklyToggles(flags, 'isLongTermJobCreationEnabled');

  const handleDayJobClick = useCallback(() => {
    if (positionTemplateData.length === 0) {
      dispatch(showSnackbar({ message: 'Please Create Position Template' }));
    } else {
      dispatch(setIsCreateJobsDialogOpen(true));
    }
  }, [dispatch, positionTemplateData]);

  const handleLongTermJobClick = useCallback(() => {
    if (positionTemplateData.length === 0) {
      dispatch(showSnackbar({ message: 'Please Create Position Template' }));
    } else {
      setIsDrawerOpen(true);
      setCreateJobType('LongTermJob');
    }
  }, [dispatch, positionTemplateData]);

  const handleJobUpdate = useCallback(
    (formData) => {
      /*
       * FIXME: This hardcodes for all users what fields they
       * can send. This will fail once some user types are allowed
       * to send data that others are not.
       */
      const data = {
        id: selectedJob?.id,
        fields: {
          numOpenings: Number(formData.numOpenings),
          ...(isAdmin ? { numOverstaff: Number(formData.numOverstaff) } : {}),
        },
      };

      const schemaErrors = validateSchema(data, JobUpdateRequestSchema);
      if (Object.keys(schemaErrors).length === 0) {
        dispatch(updateJob(data));
      }
    },
    [dispatch, selectedJob, isAdmin]
  );

  const onSelectionModelChange = useCallback(
    (job) => {
      const newSearchParams = getAllSearchParams(searchParams);
      if (job) {
        dispatch(setSelectedJob(job?.id));
        const query = { jobOrder: job?.id, limit: 250 };
        if (Object.keys(validateSchema(query, readForJobOrderSchema)).length === 0) {
          dispatch(getJobRoster(query));
        }
        if (job?.id) {
          newSearchParams[searchParamOptions.JOB_ORDER] = job.id;
        }

        if (location.pathname === '/dashboard') {
          navigate({
            pathname: '/jobs/jobsList',
            search: `?${createSearchParams(newSearchParams)}`,
          });
        } else {
          setSearchParams(newSearchParams);
        }
      }
    },
    [dispatch, navigate, setSearchParams, location.pathname, searchParams]
  );

  const onDetailClose = useCallback(() => {
    dispatch(setSelectedJob(null));
    const { [searchParamOptions.JOB_ORDER]: _, ...newParams } = searchParams;
    setSearchParams(newParams);
  }, [dispatch, setSearchParams, searchParams]);

  useEffect(() => {
    dispatch(setSelectedJobType(JOB_CATEGORY.dayJob));
    dispatch(getCorporationsForJobsList());
    dispatch(getPositionTemplate());
  }, [dispatch]);

  const jobsListPaginationQuery = useCallback(
    (params) => {
      dispatch(getJobsList(params));
    },
    [dispatch]
  );

  const tabData = [
    {
      tabLabel: 'Upcoming',
    },
    {
      tabLabel: 'Completed',
    },
  ];

  const onTabChange = (newVal) => {
    if (newVal === 0) {
      setSortModel({ field: 'start', sort: 'asc' });
      setCustomFilters({
        filterModel: {
          items: filters.UPCOMING_JOBS,
        },
      });
    }
    if (newVal === 1) {
      setSortModel({ field: 'start', sort: 'desc' });
      setCustomFilters({
        filterModel: {
          items: filters.COMPLETED_JOBS,
        },
      });
    }
  };

  const toggleExportOpen = useCallback(() => {
    setIsExportOpen(true);
  }, []);

  const detailTabData = (container) => [
    {
      tabContent: (
        <RosterForm
          onUpdate={handleJobUpdate}
          initialValues={selectedJob}
          user={user}
          jobType={JOB_CATEGORY.dayJob}
        />
      ),
      tabLabel: 'Roster',
    },
    {
      tabContent: (
        <ShiftDetails initialValues={selectedJob} onUpdate={handleJobUpdate} user={user} />
      ),
      tabLabel: 'Shift Details',
    },
    {
      tabContent: <Overview container={container} initialValues={selectedJob} user={user} />,
      tabLabel: 'Overview',
    },
  ];

  const mainContainerRef = useRef();
  const rightButtonRef = useRef();
  const leftTabRef = useRef();

  const mainContainerDims = useDims(mainContainerRef);
  const rightButtonDims = useDims(rightButtonRef);
  const leftTabDims = useDims(leftTabRef);

  useEffect(() => {
    if (
      isSearchParamValid(pathSelectedJobId) &&
      enterpriseJobs.length > 0 &&
      selectedJob?.id !== pathSelectedJobId
    ) {
      dispatch(
        getPathSelectedJob({
          filters: [{ field: '_id', value: pathSelectedJobId, operation: 'equalsID' }],
        })
      );
      const query = { jobOrder: pathSelectedJobId, limit: 250 };
      if (Object.keys(validateSchema(query, readForJobOrderSchema)).length === 0) {
        dispatch(getJobRoster(query));
      }
    }
  }, [dispatch, pathSelectedJobId, selectedJob?.id, enterpriseJobs]);

  return (
    <RootGrid container>
      <Slide
        direction="right"
        unmountOnExit
        in={!!mediumScreen || (!mediumScreen && !selectedJob)}
        timeout={10}
        easing={{ enter: 'step-end', exit: 'step-start' }}
        ref={mainContainerRef}
      >
        <Grid
          item
          container
          md
          xs
          sm
          sx={{
            height: `calc(100% - ${listHeaderHeight} - ${dataGridFiltersHeight}  )`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: listHeaderHeight,
              // If the container is smaller than the tabs + buttons, move the buttons down
              flexDirection:
                mainContainerDims.width - rightButtonDims.width - leftTabDims.width < 0
                  ? 'column'
                  : 'row',
              rowGap: theme.spacing(1),
            }}
          >
            <div ref={leftTabRef}>
              <JobTypeTab initVal="dayJobs" isDetailOpen={!!selectedJob} />
            </div>

            <Box
              sx={{
                display: 'flex',
                height: `${dataGridFiltersHeight}`,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              ref={rightButtonRef}
            >
              <Box>
                <WaeButton variant={BUTTON_VARIANT.TEXT} onClick={toggleExportOpen}>
                  {mainContainerDims.width - rightButtonDims.width - leftTabDims.width < 0
                    ? 'Export'
                    : 'Export to CSV'}
                </WaeButton>
              </Box>
              {!isLoadingPt && (
                <WaeButton
                  variant={BUTTON_VARIANT.OUTLINED}
                  text="New Bulk Order"
                  onClick={handleDayJobClick}
                  sx={{
                    marginLeft: 1,
                  }}
                />
              )}
              {!isLoadingPt && (
                <WaeButton
                  text="New Job Order"
                  onClick={handleLongTermJobClick}
                  disabled={!longTermToggle}
                  sx={{
                    marginLeft: 1,
                  }}
                />
              )}
            </Box>
          </Box>
          <Grid
            container
            sx={{
              width: '100%',
              height:
                !largeScreen && !!selectedJob
                  ? `calc(100% - ${listHeaderHeight} - ${dataGridFiltersHeight})`
                  : `calc(100% - ${listHeaderHeight})`,
            }}
          >
            <PaginatedDataGrid
              additiveFilters={additiveFilters}
              apiPath={API_PATH}
              columnData={jobListColumnData}
              customFilter={customFilters}
              sortModel={sortModel}
              FilterLeftComponent={
                <Tab data={tabData} onChange={onTabChange} background="default" />
              }
              hideFilterBreakpoint={selectedJob ? '1700px' : '0'}
              hideFooterSelectedRowCount
              isLoading={isLoading}
              onSelectionModelChange={onSelectionModelChange}
              pageSize={pageSize}
              page={page}
              paginatedData={enterpriseJobs}
              pagination
              paginationQuery={jobsListPaginationQuery}
              setPageSize={setPageSize}
              setPage={setPage}
              setRowCount={setRowCount}
              sx={{
                border: 0,
                width: '100%',
                paddingLeft: 1,
              }}
              totalRowCount={totalRowCount}
              selectionModel={selectedJob?.id}
              rowCount={rowCount}
              style={{ marginRight: theme.spacing(2) }}
            />
            {selectedJob && (
              <DetailView
                close={() => onDetailClose()}
                tabData={detailTabData}
                onUpdate={handleJobUpdate}
                sx={{
                  marginLeft: theme.spacing(1.5),
                  height:
                    !largeScreen && !!selectedJob
                      ? `calc(100% - ${listHeaderHeight} - ${dataGridFiltersHeight})`
                      : `calc(100% - ${listHeaderHeight})`,
                }}
              />
            )}
          </Grid>
        </Grid>
      </Slide>

      {!mediumScreen && !!selectedJob && (
        <DetailView
          close={() => onDetailClose()}
          tabData={detailTabData}
          onUpdate={handleJobUpdate}
          sx={{ marginLeft: theme.spacing(1.5) }}
        />
      )}

      <ExportView open={isExportOpen} close={() => setIsExportOpen(false)} />

      <CancelJobModal
        selectedJob={selectedJob}
        isCancelDialogOpen={isCancelDialogOpen}
        setIsCancelDialogOpen={setIsCancelDialogOpen}
      />

      {(isCancelProcessing || isUpdateProcessing) && <BackdropCircularProgress />}
      <CreateJobDrawer
        anchor="right"
        isOpen={isDrawerOpen}
        createJobType={createJobType}
        onClose={() => setIsDrawerOpen(false)}
      />
      {isCreateJobsDialogOpen && (
        <CreateJobsModal
          open={isCreateJobsDialogOpen}
          handleModalClose={() => dispatch(setIsCreateJobsDialogOpen(false))}
        />
      )}
    </RootGrid>
  );
};

JobsList.propTypes = {
  flags: PropTypes.shape({}),
};

export default withLDConsumer()(JobsList);
