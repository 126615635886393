import React from 'react';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';

import DownloadAppOnAppleStore from '../../assets/AppleStore.svg';
import DownloadAppOnAndroidStore from '../../assets/google-play-badge.svg';
import MobileRedirectPage from '../../assets/ReDirectPage.jpg';
import { androidStoreLink, appleStoreLink } from '../../utils';
import { navigator } from '../../utils/navigator';

const MobileDownloadPage = () => (
  <Grid container direction="row" justifyContent="center">
    <Box
      component="img"
      sx={{
        height: '30%',
        width: '60%',
      }}
      alt="MobileRedirect"
      src={MobileRedirectPage}
    />
    <Grid
      container
      item
      direction="row"
      justifyContent="center"
      sx={{
        height: '100%',
        marginTop: 5,
      }}
    >
      <Box
        component="img"
        sx={{
          height: '10%',
          width: '19%',
          cursor: 'pointer',
          marginRight: 5,
        }}
        alt="Apple"
        src={DownloadAppOnAppleStore}
        onClick={() => navigator(appleStoreLink)}
      />
      <Box
        component="img"
        sx={{
          height: '10%',
          width: '20%',
          cursor: 'pointer',
        }}
        alt="Android"
        src={DownloadAppOnAndroidStore}
        onClick={() => navigator(androidStoreLink)}
      />
    </Grid>
  </Grid>
);

export default MobileDownloadPage;
