import React from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

import CorporationLogo from '../../../components/CorporationLogo';
import {
  selectCorporationById,
  selectFiles,
} from '../../../store/selectors/candidateJobsListSelectors';
import theme, { groupListCompanyImageSize } from '../../../theme';
import { epochToDateInReadableFormat, epochToTimeInReadableFormat } from '../../../utils';

import PlacementsHasConflictIcon from './subComponent/PlacementsHasConflictIcon';

const MyJobCard = ({
  corporation,
  end,
  id,
  itemClickHandler,
  isConflicting,
  placementData,
  payRate,
  shiftPay,
  start,
  title,
  shiftGroup,
  selected,
}) => {
  const corpID = get('_id', corporation);

  const { logo: logoID } = useSelector((state) => selectCorporationById(state, corpID)) || {};
  const corpName = get('name', corporation);

  const files = useSelector(selectFiles);
  const file = get([`${logoID}`], files);

  const startDate = epochToDateInReadableFormat(start);
  const startTime = epochToTimeInReadableFormat(start);
  const endTime = epochToTimeInReadableFormat(end);

  const sxForCard = {
    padding: theme.spacing(1, 3, 1, 3),
    marginBottom: theme.spacing(3),
    backgroundColor: selected
      ? theme.candidate.components.shiftGroupCard.backgroundColor.selected
      : theme.candidate.components.shiftGroupCard.backgroundColor.default,
    borderRadius: theme.spacing(2),
    display: 'flex',
  };
  const sxForTitle = {
    color: selected
      ? theme.candidate.components.shiftGroupCard.headers.selectedFontColor
      : theme.candidate.components.shiftGroupCard.headers.fontColor,
    fontFamily: theme.candidate.components.shiftGroupCard.headers.font,
    fontSize: '18px',
  };
  const sxForTotal = {
    color: selected
      ? theme.candidate.components.shiftGroupCard.headers.selectedContrastColor
      : theme.candidate.components.shiftGroupCard.headers.contrastColor,
    fontFamily: theme.candidate.components.shiftGroupCard.headers.font,
  };
  const sxForSubtext = {
    color: selected
      ? theme.candidate.components.shiftGroupCard.subtext.selectedFontColor
      : theme.candidate.components.shiftGroupCard.subtext.fontColor,
    fontFamily: theme.candidate.components.shiftGroupCard.subtext.font,
    fontSize: '14px',
  };
  const sxForDate = {
    color: selected
      ? theme.candidate.components.shiftGroupCard.subtext.selectedFontColor
      : theme.candidate.components.shiftGroupCard.subtext.fontColor,
    fontFamily: theme.candidate.components.shiftGroupCard.headers.font,
    fontSize: '14px',
    marginRight: theme.spacing(1),
  };
  const sxForMultipleShift = {
    backgroundColor: get(
      ['candidate', 'components', 'jobCard', 'pill', 'backgroundColor', 'default'],
      theme
    ),
    borderRadius: '20px',
    color: get(['candidate', 'components', 'jobCard', 'pill', 'typography', 'color'], theme),
    fontFamily: get(['candidate', 'components', 'jobCard', 'pill', 'typography', 'font'], theme),
    fontSize: '12px',
    padding: '2px 6px',
    display: 'inline-block',
    margin: theme.spacing(0, 0.25, 0, 0),
  };

  return (
    <Box sx={sxForCard} key={id} onClick={() => itemClickHandler(placementData)}>
      <CorporationLogo
        file={file}
        corporationId={corpID}
        style={{
          height: groupListCompanyImageSize.height,
          width: groupListCompanyImageSize.width,
          marginRight: theme.spacing(1),
        }}
      />
      <Box sx={{ justifyContent: 'space-between', width: '100%' }}>
        <Grid container key={id} direction="column" justifyContent="space-between">
          <Box sx={{ display: 'flex' }}>
            <Typography sx={sxForDate}>{startDate}</Typography>
            <Typography sx={sxForSubtext}>
              {startTime}-{endTime}
            </Typography>
            {isConflicting && <PlacementsHasConflictIcon />}
            {/* placements does not have isConflicting property, kept PlacementsHasConflictIcon component on its place just in case we will need it in the future */}
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography sx={sxForTitle}>{title}</Typography>
            <Typography variant="h5" sx={sxForTotal}>
              {shiftPay}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              <Typography variant="subtitle2" sx={sxForSubtext}>
                {corpName}
                {shiftGroup && <span style={sxForMultipleShift}> Multiple Shift Commitment</span>}
              </Typography>
            </Box>
            <Typography variant="subtitle2" sx={sxForSubtext}>
              ${payRate}/hr
            </Typography>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

MyJobCard.propTypes = {
  corporation: PropTypes.shape({}),
  end: PropTypes.number,
  id: PropTypes.string,
  itemClickHandler: PropTypes.func,
  isConflicting: PropTypes.bool,
  placementData: PropTypes.shape({}),
  payRate: PropTypes.number,
  shiftPay: PropTypes.string,
  start: PropTypes.number,
  title: PropTypes.string,
  shiftGroup: PropTypes.string,
  selected: PropTypes.bool,
};

export default MyJobCard;
