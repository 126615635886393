import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { JOB_CATEGORY } from '@careerstart/wae-common/src/main/constants/jobInformation';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { selectIsRevokingInvite } from '../../store/selectors/jobListSelectors';
import theme from '../../theme';
import { BLACK } from '../../theme/colorConstants';
import { epochToDateInReadableFormat } from '../../utils';
import BackdropCircularProgress from '../BackdropCircularProgress/BackdropCircularProgress';
import { BUTTON_VARIANT, WaeButton } from '../Button/Button';

const styleForCandidateName = {
  color: theme.components.detailView.typography.locationText.fontColor,
  fontFamily: theme.components.detailView.typography.locationText.fontFamily,
};

const InvitationCard = ({ inviteData, fav, handleRevokeClick, jobType }) => {
  const invitationCardStyle = {
    borderRadius: '16px',
    padding: theme.spacing(2),
    justifyContent: 'space-between',
    boxShadow: 0,
    width: '100%',
    '&:hover, &.Mui-hovered': {
      backgroundColor: `${BLACK[80]}`,
    },
    backgroundColor:
      jobType === JOB_CATEGORY.dayJob
        ? theme.components.invitationCard.dayJobs.backgroundColor
        : theme.components.invitationCard.longJobs.backgroundColor,
  };

  const isRevokingInvite = useSelector(selectIsRevokingInvite);

  const revokingInvite = isRevokingInvite && get('_id', inviteData) === isRevokingInvite;

  const styleForInvitation = {
    color: theme.components.detailView.typography.fieldHeaders.fontColor,
    fontFamily: theme.components.detailView.typography.fieldHeaders.fontFamily,
    fontSize: theme.components.detailView.typography.fieldHeaders.fontSize,
  };

  const timestampString = get(['metadata', 'created'], inviteData);

  return (
    <Box sx={invitationCardStyle} data-testid="invitationCard">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: theme.spacing(0, 0, 0, 2),
          marginBottom: theme.spacing(1),
        }}
      >
        {fav && (
          <FavoriteIcon
            sx={{
              color: get(['button', 'palette', 'whiteIcon'], theme),
              margin: theme.spacing(0, 1.5, 0, 0),
            }}
          />
        )}
        <Typography sx={styleForCandidateName}>{inviteData.name}</Typography>
      </Box>

      {timestampString && (
        <Box sx={{ marginBottom: theme.spacing(1) }}>
          <Typography sx={styleForInvitation}>
            Invitation sent: {epochToDateInReadableFormat(Date.parse(timestampString))}
          </Typography>
        </Box>
      )}

      {revokingInvite && <BackdropCircularProgress />}
      <Box>
        <WaeButton
          text={LanguageConverter('buttonText.revokeInvite')}
          variant={BUTTON_VARIANT.OUTLINED}
          onClick={() => handleRevokeClick({ placement: get('_id', inviteData) })}
        />
      </Box>
    </Box>
  );
};

InvitationCard.propTypes = {
  corporation: PropTypes.shape({}),
  fav: PropTypes.bool,
  handleRevokeClick: PropTypes.func,
  inviteData: PropTypes.shape({
    name: PropTypes.string,
    invitationDate: PropTypes.string,
  }),
  jobType: PropTypes.string,
};

export default InvitationCard;
