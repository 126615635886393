import React from 'react';
import PropTypes from 'prop-types';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import theme, { tabHeight } from '../../theme';

const BACKGROUND = {
  DEFAULT: 'default',
  DARK: 'dark',
};
const WaeTab = ({ data, onChange, background }) => {
  let bgColor;
  let bgText;
  let selectedText;
  let selected;

  switch (background) {
    case BACKGROUND.DARK:
      bgColor = `${theme.tab.dark.bgColor}`;
      bgText = `${theme.tab.dark.bgText}`;
      selected = `${theme.tab.dark.selected}`;
      selectedText = `${theme.tab.dark.selectedText}`;
      break;
    default:
      bgColor = `${theme.tab.default.bgColor}`;
      bgText = `${theme.tab.default.bgText}`;
      selected = `${theme.tab.default.selected}`;
      selectedText = `${theme.tab.default.selectedText}`;
  }

  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
    onChange(newValue);
  };

  return (
    <Tabs
      value={tabIndex}
      onChange={handleChange}
      aria-label="basic tabs example"
      sx={{
        backgroundColor: `${bgColor}`,
        borderRadius: '20px',
        display: 'inline-grid', // Dev Note: This is important. It keeps the background around the tab limited to the tab content.
        height: `${tabHeight}`,
        minHeight: `${tabHeight}`,
        minWidth: 'max-content',
      }}
      TabIndicatorProps={{ style: { display: 'none' } }}
    >
      {data.map((tab) => (
        <Tab
          style={{ textTransform: 'none' }}
          key={tab.tabLabel}
          label={tab.tabLabel}
          sx={{
            '&.Mui-selected': {
              backgroundColor: `${selected}`,
              color: `${selectedText}`,
            },
            height: `${tabHeight}`,
            minHeight: `${tabHeight}`,
            color: `${bgText}`,
            borderRadius: '20px',
            fontFamily: `${theme.tab.default.fontFamily}`,
          }}
        />
      ))}
    </Tabs>
  );
};

WaeTab.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      tabLabel: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  background: PropTypes.string,
};
export default WaeTab;
