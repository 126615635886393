import get from 'lodash/fp/get';
import map from 'lodash/fp/map';

import { apiPost } from '../../../datahub/axios';
import { epochToDateInReadableFormat, epochToTimeInReadableFormat } from '../../utils';

let count = 25;

export const getPageData = async ({ apiPath, filters, page, pageSize, token }) => {
  const rowData = [];
  await apiPost(
    apiPath,
    {
      page,
      limit: pageSize,
      filters,
    },
    token
  ).then((webResponse) => {
    const { documents } = webResponse.data;
    const updatedData = map(
      (jobEntry) => ({
        id: get('_id', jobEntry),
        jobInfo: get('name', jobEntry),
        address: get(['location', 'address'], jobEntry),
        corporation: get(['corporation', 'name'], jobEntry),
        fillRatio: get(['fillRatio'], jobEntry),
        startDate: epochToDateInReadableFormat(get('start', jobEntry)),
        startTime: epochToTimeInReadableFormat(get('start', jobEntry)),
        endDate: epochToDateInReadableFormat(get('end', jobEntry)),
        endTime: epochToTimeInReadableFormat(get('end', jobEntry)),
        numOpenings: get(['numOpenings'], jobEntry),
        name: get(['name'], jobEntry),
        status: get(['status'], jobEntry),
        description: get(['description'], jobEntry),
        externalId: get(['metadata', 'bullhorn', 'id'], jobEntry),
      }),
      documents
    );
    count = get(['data', 'count'], webResponse);
    rowData.push(updatedData);
  });
  return rowData;
};

export const getSetData = async ({ columns, apiPath, filters, pageSize, token, setProgress }) => {
  const CHUNK_SIZE = 4;
  const returnArray = [[]];

  await getPageData({
    page: 0,
    apiPath,
    filters,
    pageSize: 1,
    token,
  });
  const maxPages = Math.ceil(count / pageSize);

  let currentChunk = 0;

  do {
    let currentMax = CHUNK_SIZE * currentChunk + CHUNK_SIZE;
    if (currentMax > maxPages) {
      currentMax = maxPages;
    }
    const promises = [];
    const rows = [];
    let currentPage = 0 + currentChunk * CHUNK_SIZE;
    do {
      const pageData = getPageData({
        page: currentPage,
        apiPath,
        filters,
        pageSize,
        token,
      });
      promises.push(pageData);
      currentPage += 1;
    } while (currentPage < currentMax);

    const response = Promise.all(promises).then((values) => {
      // eslint-disable-next-line array-callback-return
      values.flat(Infinity).map((rowData) => {
        const currentRowData = [];
        columns.map((colData) => currentRowData.push(get(colData.field, rowData)));
        rows.push(currentRowData);
      });
    });

    // eslint-disable-next-line no-await-in-loop
    await response;
    returnArray.push(...rows);
    if (response) {
      currentChunk += 1;
      const progress = (currentChunk * CHUNK_SIZE) / maxPages;
      setProgress(progress);
    }
  } while (currentChunk <= maxPages / CHUNK_SIZE);
  return returnArray;
};

export const handleCSVConversion = ({ rows, fileName }) => {
  const processRow = (row) => {
    let rowVal = '';
    for (let j = 0; j < row.length; j += 1) {
      let innnerVal = row[j] === null ? '' : row[j]?.toString() || 'Error';
      if (row[j] instanceof Date) {
        innnerVal = row[j].toLocaleString();
      }
      let result = innnerVal.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = `"${result}"`;
      if (j > 0) rowVal += ',';
      rowVal += result;
    }
    return `${rowVal}\n`;
  };

  let csvFile = '';
  for (let i = 0; i < rows.length; i += 1) {
    csvFile += processRow(rows[i]);
  }

  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
