import * as React from 'react';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Grid, Link, Paper, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';

import MaintenanceSign from '../../../assets/icons/Maintenance.svg';
import theme from '../../../theme';

const NotReadyToWork = () => {
  const phoneScreen = useMediaQuery('(min-width:600px)');
  const notReadyToWorkPageTheme = theme?.candidate?.components?.notReadyToWorkPage;
  return (
    <Grid
      sx={{
        height: phoneScreen ? '100vh' : '80vh',
      }}
      container
      data-testid="notReadyToWorkComponent"
    >
      <Box
        sx={{
          width: '100%',
          margin: '15% auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          component="img"
          sx={{
            height: 100,
            width: 200,
          }}
          alt="Construction"
          src={MaintenanceSign}
        />
        <Typography sx={notReadyToWorkPageTheme?.typography?.headerText} align="center">
          {LanguageConverter('generic.verificationInProgress')}
        </Typography>
        <Grid item container xs={12} padding={theme.spacing(1, 1, 0)} justifyContent="center">
          <Paper sx={{ width: '100%', backgroundColor: '#F2F2F5' }}>
            <Typography
              align="center"
              paddingBottom={theme.spacing(1)}
              sx={notReadyToWorkPageTheme?.typography?.subHeaderText}
            >
              WAE staff are still verifying your account information
            </Typography>
            <Typography
              paddingBottom={theme.spacing(1)}
              sx={notReadyToWorkPageTheme?.typography?.body}
              align="center"
            >
              If you have not already completed your onboarding documentation and need assistance,
              please refer to the support page found below:
            </Typography>
            <Typography
              paddingBottom={theme.spacing(1)}
              sx={notReadyToWorkPageTheme?.typography?.body}
              align="center"
            >
              <Link href="https://mywae.zendesk.com/hc/en-us" underline="hover">
                Click Here
              </Link>
            </Typography>
            <Typography sx={notReadyToWorkPageTheme?.typography?.body} align="center">
              WAE staff may reach out if they encounter any issues when processing you onboarding
              documentation once you have submitted everything. Please be sure to respond to any
              further questions in a timely manner to ensure that you can gain full access to WAE as
              soon as possible
            </Typography>
          </Paper>
        </Grid>
      </Box>
    </Grid>
  );
};

export default NotReadyToWork;
