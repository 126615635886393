import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';

import selectUser from '../../store/selectors/appSelector';
import theme from '../../theme';
import { revokeJobInvitations } from '../../views/jobs/jobList';
import ExportRecommendList from '../../views/jobs/jobList/detail/SubComponents/ExportRecommendList';

import InvitationCard from './InvitationCard';

const InvitationTab = ({ selectedJobInvitations, corporation, jobType, jobOrderId }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isAdmin = get('role', user) === UserRole.ADMIN;
  const handleRevokeClick = (reqBody) => {
    dispatch(revokeJobInvitations(reqBody));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: theme.spacing(0, 2, 0, 0) }}>
        {isAdmin && <ExportRecommendList jobOrderId={jobOrderId} user={user} />}
      </Box>

      {selectedJobInvitations &&
        selectedJobInvitations.length > 0 &&
        selectedJobInvitations.map((jobInvite) => {
          const favorite =
            corporation.favorite.find((f) => f.waeID === jobInvite.candidate) || false;

          return (
            <ListItem key={get('candidate', jobInvite)}>
              <InvitationCard
                inviteData={jobInvite}
                fav={!!favorite}
                jobType={jobType}
                handleRevokeClick={handleRevokeClick}
              />
            </ListItem>
          );
        })}
    </Box>
  );
};

InvitationTab.propTypes = {
  selectedJobInvitations: PropTypes.arrayOf(PropTypes.shape()),
  corporation: PropTypes.shape({ favorite: PropTypes.arrayOf(PropTypes.shape()) }),
  jobOrderId: PropTypes.string,
  jobType: PropTypes.string,
};

export default InvitationTab;
