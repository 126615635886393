import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import theme from '../../../../../theme';

const styleForSubtitle = {
  fontSize: `${theme.components.detailView.typography.cancelledShiftSubtitle.fontSize}`,
  fontFamily: `${theme.components.detailView.typography.cancelledShiftSubtitle.fontFamily}`,
  color: `${theme.components.detailView.palette.cancelledShiftSubtitleColor}`,
  marginLeft: theme.spacing(2.5),
};

const styleForTimeText = {
  fontSize: `${theme.components.detailView.typography.cancelledShiftTime.fontSize}`,
  fontFamily: `${theme.components.detailView.typography.cancelledShiftTime.fontFamily}`,
  color: `${theme.components.detailView.palette.cancelledShiftTimeColor}`,
  margin: theme.spacing(2.5),
};

const styleForShiftCard = {
  width: '100%',
  backgroundColor: `${theme.components.detailView.palette.cancelledShiftCardBgColor}`,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  borderRadius: '16px',
};

const styleForShiftTitle = {
  fontSize: `${theme.components.detailView.typography.cancelledShiftTitle.fontSize}`,
  fontFamily: `${theme.components.detailView.typography.cancelledShiftTitle.fontFamily}`,
  color: `${theme.components.detailView.palette.cancelledShiftTitleColor}`,
  paddingLeft: theme.spacing(2.5),
};

const CancellationShiftCard = ({ job }) => (
  <Box sx={styleForShiftCard}>
    <Typography sx={styleForShiftTitle}>{job.name}</Typography>
    <Typography sx={styleForSubtitle}> {get(['corporation', 'name'], job)}</Typography>

    <Box display="flex" justifyContent="space-between" sx={{ marginTop: '20px' }}>
      <Box>
        <Box sx={styleForSubtitle}>Shift Date</Box>
        <Box sx={styleForTimeText}>{get('startDate', job)}</Box>
      </Box>
      <Box>
        <Box sx={styleForSubtitle}>Shift Time</Box>
        <Box sx={styleForTimeText}>
          {get('startTime', job)}-{get('endTime', job)}
        </Box>
      </Box>
    </Box>
  </Box>
);

CancellationShiftCard.propTypes = {
  job: PropTypes.shape({
    name: PropTypes.string,
  }),
};
export default CancellationShiftCard;
