/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import { Autocomplete, InputAdornment, Paper, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

import KeyboardArrowDownIcon from '../../../../assets/icons/KeyboardArrowDownIcon.svg';
import Search from '../../../../assets/icons/Search.svg';
import theme from '../../../../theme';

const SEARCHBAR_BACKGROUND = {
  DARK: 'dark',
  DEFAULT: 'default',
  LIGHT: 'light',
};

const MyJobSearchBar = ({ placeholder, onValueChange, optionDisplayField, background, sx }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = React.useState(false);
  switch (background) {
    case SEARCHBAR_BACKGROUND.DARK:
      // Dark mode style here
      break;
    case SEARCHBAR_BACKGROUND.LIGHT:
      // Light mode style here
      break;
    default:
      // Default mode style here
      break;
  }

  const styleForFormControl = {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px',
    },
    '&:hover': {
      backgroundColor: !searchTerm && get(['searchBoxForFilter', 'bgColor', 'hover'], theme),
    },
    '&:focus-within': {
      backgroundColor: !searchTerm && get(['searchBoxForFilter', 'bgColor', 'focus'], theme),
      border: `1px solid ${get(['searchBoxForFilter', 'borderColor', 'focus'], theme)}`,
    },
    backgroundColor: searchTerm && get(['searchBoxForFilter', 'bgColor', 'complete'], theme),

    borderRadius: '40px',
    justifyContent: 'center',
    height: '40px',
    width: '200px',
    marginLeft: '8px',
  };

  return (
    <FormControl sx={styleForFormControl}>
      <Autocomplete
        freeSolo
        popupIcon={
          <Box
            component="img"
            sx={{
              height: 22,
              width: 22,
            }}
            alt="Stars"
            src={KeyboardArrowDownIcon}
          />
        }
        PaperComponent={(props) => (
          <Paper
            {...props}
            style={{
              borderRadius: '16px',
            }}
            placement="auto-start"
          />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={sx}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: open && (
                <InputAdornment position="start">
                  <Box
                    component="img"
                    sx={{
                      height: 12,
                      width: 12,
                    }}
                    alt="Search"
                    src={Search}
                  />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              onValueChange(e.target.value);
            }}
          />
        )}
        isOptionEqualToValue={(option, val) => option._id === val._id}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={[]}
        getOptionLabel={(option) => option[optionDisplayField] || ''}
      />
    </FormControl>
  );
};

MyJobSearchBar.propTypes = {
  placeholder: PropTypes.string,
  onValueChange: PropTypes.func,
  optionsAPICallback: PropTypes.shape({}),
  customAPIData: PropTypes.shape({}),
  optionDisplayField: PropTypes.string,
  sx: PropTypes.shape({}),
  background: PropTypes.string,
};

export default MyJobSearchBar;
