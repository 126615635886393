import React, { useEffect, useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import { Autocomplete, InputAdornment, Paper, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

import Search from '../../../assets/icons/Search.svg';
import theme from '../../../theme';

const SEARCHBAR_BACKGROUND = {
  DARK: 'dark',
  DEFAULT: 'default',
  LIGHT: 'light',
};

const FreeSearchFilter = ({
  placeholder,
  onValueChange,
  background,
  field,
  operation,
  sx,
  initialValue,
}) => {
  const [searchTerm, setSearchTerm] = useState(initialValue?.value || '');
  const [open, setOpen] = useState(false);
  const [hasHadValue, setHasHadValue] = useState(false);
  switch (background) {
    case SEARCHBAR_BACKGROUND.DARK:
      // Dark mode style here
      break;
    case SEARCHBAR_BACKGROUND.LIGHT:
      // Light mode style here
      break;
    default:
      // Default mode style here
      break;
  }

  const styleForFormControl = {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px',
    },
    '&:hover': {
      backgroundColor: !searchTerm && get(['searchBoxForFilter', 'bgColor', 'hover'], theme),
    },
    '&:focus-within': {
      backgroundColor: !searchTerm && get(['searchBoxForFilter', 'bgColor', 'focus'], theme),
      border: `1px solid ${get(['searchBoxForFilter', 'borderColor', 'focus'], theme)}`,
    },
    backgroundColor: searchTerm && get(['searchBoxForFilter', 'bgColor', 'complete'], theme),

    borderRadius: '40px',
    justifyContent: 'center',
    height: '40px',
    width: '200px',
    marginLeft: '8px',
  };

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      if (searchTerm || hasHadValue) {
        const newVal = [
          {
            value: searchTerm || '',
            field,
            operation,
          },
        ];
        onValueChange(newVal);

        if (!hasHadValue) {
          setHasHadValue(true);
        }
      }
    }, 750);
    return () => clearTimeout(delayedSearch);
  }, [searchTerm, operation, field, onValueChange, hasHadValue]);

  return (
    <FormControl sx={styleForFormControl}>
      <Autocomplete
        freeSolo
        inputValue={searchTerm}
        disableClearable
        PaperComponent={(props) => (
          <Paper
            {...props}
            style={{
              borderRadius: '16px',
            }}
            placement="auto-start"
          />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={sx}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <Box
                    component="img"
                    sx={{
                      height: 24,
                      width: 24,
                    }}
                    alt="Stars"
                    src={Search}
                  />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        )}
        isOptionEqualToValue={(option, val) => option._id === val._id}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={[]}
      />
    </FormControl>
  );
};

FreeSearchFilter.propTypes = {
  placeholder: PropTypes.string,
  onValueChange: PropTypes.func,
  field: PropTypes.string,
  operation: PropTypes.string,
  sx: PropTypes.shape({}),
  background: PropTypes.string,
  initialValue: PropTypes.shape({ value: PropTypes.string }),
};

export { FreeSearchFilter, SEARCHBAR_BACKGROUND };
