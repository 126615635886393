import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import theme from '../../../theme';

const JobsMenu = ({ closeDrawer, isOpen, Icon, testId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState(false);
  const dayJobsURL = 'jobs/jobsList';
  const longTermJobURL = 'jobs/longTermJobsList';

  useEffect(() => {
    if (location.pathname === `/${dayJobsURL}` || location.pathname === `/${longTermJobURL}`) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [location]);
  const backgroundColor = active ? theme.components.nav.activeBackgroundColor.default : null;

  const sx = {
    pl: 2,
    margin: theme.spacing(2),
    borderRadius: '9999px',
    color: theme.components.nav.textColor.default,
    backgroundColor,
  };

  const sxForCollapsedButton = {
    pl: 2,
    margin: theme.spacing(2),
    borderRadius: '9999px',
    backgroundColor,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
  };

  const sxForIcon = {
    diplay: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '100%',
    minHeight: '36px',
  };

  if (!isOpen) {
    return (
      <List component="nav" sx={sxForCollapsedButton} data-testid={testId}>
        <ListItemIcon
          onClick={() => {
            navigate(dayJobsURL);
            closeDrawer();
          }}
          sx={sxForIcon}
        >
          <Icon sx={{ color: theme.components.nav.textColor.default }} fontSize="small" />
        </ListItemIcon>
      </List>
    );
  }

  return (
    <List component="nav" disablePadding data-testid={testId}>
      <ListItemButton
        onClick={() => {
          navigate(dayJobsURL);
          closeDrawer();
        }}
        sx={sx}
      >
        <ListItemIcon>
          <Icon sx={{ color: theme.components.nav.textColor.default }} fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Job Orders" />
      </ListItemButton>
    </List>
  );
};

JobsMenu.propTypes = {
  closeDrawer: PropTypes.func,
  isOpen: PropTypes.bool,
  Icon: PropTypes.shape({}),
  testId: PropTypes.string,
};

export default JobsMenu;
