import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import WaeButton from '../../components/Button';
import PaginatedDataGrid from '../../components/DataGrid/PaginatedDataGridV2';
import FreeSearchFilter from '../../components/Filters/FreeSearchFilter';
import {
  selectCertsList,
  selectCertsTotalRowCount,
  selectIsCertsLoading,
  selectIsSkillsLoading,
  selectSkillsList,
  selectSkillTotalRowCount,
} from '../../store/selectors/skillsAndCertsSelector';
import theme, { dataGridFiltersHeight, listHeaderHeight, navigationBarHeight } from '../../theme';
import { useDims } from '../../utils/customHooks';

import AddNewSkillOrCertModal from './subComponents/AddNewSkillOrCertModal';
import DeleteSkillOrCertsModal from './subComponents/DeleteSkillOrCertsModal';
import certColumnData from './certColumnData';
import { getCerts, getSkills } from './reducer';
import skillColumnData from './skillColumnData';

const RootGrid = styled(Grid)(() => ({
  height: `calc(100vh - ${navigationBarHeight})`,
  position: 'relative',
  top: navigationBarHeight,
  padding: theme.spacing(2, 0),
}));
const additiveFilters = [
  {
    customFilter: {
      field: 'name',
      operation: 'icontains',
      placeholder: 'Search skills...',
      type: FreeSearchFilter,
    },
  },
];

const certsAdditiveFilters = [
  {
    customFilter: {
      field: 'name',
      operation: 'icontains',
      placeholder: 'Search certs...',
      type: FreeSearchFilter,
    },
  },
];

const SkillsAndCerts = () => {
  const skillsList = useSelector(selectSkillsList);
  const certsList = useSelector(selectCertsList);
  const dispatch = useDispatch();

  const largeScreen = useMediaQuery('(min-width:1910px)');

  const mainContainerRef = useRef();
  const rightButtonRef = useRef();
  const leftTabRef = useRef();

  const mainContainerDims = useDims(mainContainerRef);
  const rightButtonDims = useDims(rightButtonRef);
  const leftTabDims = useDims(leftTabRef);

  // Skills
  const isSkillsLoading = useSelector(selectIsSkillsLoading);
  const skillTotalRowCount = useSelector(selectSkillTotalRowCount);
  const isCertsLoading = useSelector(selectIsCertsLoading);

  const certsTotalRowCount = useSelector(selectCertsTotalRowCount);

  const [skillPageSize, setSkillPageSize] = useState(25);
  const [certPageSize, setCertPageSize] = useState(25);
  const [skillPage, setSkillPage] = useState(0);
  const [certPage, setCertPage] = useState(0);

  const [skillRowCount, setSkillRowCount] = useState(25);
  const [certRowCount, setCertRowCount] = useState(25);
  const [skillsListData, setSkillsListData] = useState(skillsList);
  const [certsListData, setCertsListData] = useState(certsList);

  const [addSkillModalOpen, setAddSkillModalOpen] = React.useState(false);
  const [deleteSkillModalOpen, setDeleteSkillModalOpen] = React.useState(false);
  const [addCertModalOpen, setAddCertModalOpen] = React.useState(false);
  const [deleteCertModalOpen, setDeleteCertModalOpen] = React.useState(false);
  const [skillId, setSkillId] = React.useState('');
  const [certId, setCertId] = React.useState('');

  const skillListColumnData = useMemo(
    () =>
      skillColumnData((id) => {
        setDeleteSkillModalOpen(true);
        setSkillId(id);
      }),
    []
  );

  const certListColumnData = useMemo(
    () =>
      certColumnData((id) => {
        setDeleteCertModalOpen(true);
        setCertId(id);
      }),
    []
  );

  useEffect(() => {
    setSkillsListData(skillsList);
  }, [skillsList]);

  const skillsListPaginationQuery = React.useCallback(
    (params) => dispatch(getSkills(params)),
    [dispatch]
  );

  useEffect(() => {
    setCertsListData(certsList);
  }, [certsList]);

  const certsListPaginationQuery = React.useCallback(
    (params) => dispatch(getCerts(params)),
    [dispatch]
  );

  const handleAddSkill = () => {
    setAddSkillModalOpen(true);
  };

  const handleAddCert = () => {
    setAddCertModalOpen(true);
  };

  return (
    <RootGrid>
      <Box>
        {' '}
        <Typography sx={{ fontSize: '24px', fontFamily: `${theme.dataGrid.default.boldFont}` }}>
          Skills & Certifications
        </Typography>
        <AddNewSkillOrCertModal
          type="skill"
          isOpen={addSkillModalOpen}
          setIsOpen={setAddSkillModalOpen}
        />
        <DeleteSkillOrCertsModal
          type="skill"
          isOpen={deleteSkillModalOpen}
          setIsOpen={setDeleteSkillModalOpen}
          skillOrCertId={skillId}
        />
        {/* skills */}
        <Box sx={{ marginBottom: `${theme.spacing(8)}` }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: 500,
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                // If the container is smaller than the tabs + buttons, move the buttons down
                flexDirection:
                  mainContainerDims.width - rightButtonDims.width - leftTabDims.width < 0
                    ? 'column'
                    : 'row',
                rowGap: theme.spacing(1),
              }}
            />
            <Grid
              container
              sx={{
                width: '100%',
                height: !largeScreen
                  ? `calc(100% - ${listHeaderHeight} - ${dataGridFiltersHeight} )`
                  : `calc(100% - ${listHeaderHeight})`,
              }}
            >
              <PaginatedDataGrid
                additiveFilters={additiveFilters}
                columnData={skillListColumnData}
                FilterLeftComponent={<WaeButton text="New Skill" onClick={handleAddSkill} />}
                isLoading={isSkillsLoading}
                onSelectionModelChange={() => {}}
                page={skillPage}
                pageSize={skillPageSize}
                paginatedData={skillsListData}
                pagination
                paginationQuery={skillsListPaginationQuery}
                setPage={setSkillPage}
                setPageSize={setSkillPageSize}
                setRowCount={setSkillRowCount}
                sx={{
                  border: 0,
                  width: '100%',
                  paddingLeft: 1,
                }}
                totalRowCount={skillTotalRowCount}
                rowCount={skillRowCount}
                style={{ marginRight: theme.spacing(2) }}
              />
            </Grid>
          </Box>
        </Box>
        <AddNewSkillOrCertModal
          type="cert"
          isOpen={addCertModalOpen}
          setIsOpen={setAddCertModalOpen}
        />
        <DeleteSkillOrCertsModal
          type="cert"
          isOpen={deleteCertModalOpen}
          setIsOpen={setDeleteCertModalOpen}
          skillOrCertId={certId}
        />
        <Box sx={{ marginBottom: `${theme.spacing(8)}` }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: 500,
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                // If the container is smaller than the tabs + buttons, move the buttons down
                flexDirection:
                  mainContainerDims.width - rightButtonDims.width - leftTabDims.width < 0
                    ? 'column'
                    : 'row',
                rowGap: theme.spacing(1),
              }}
            />
            <Grid
              container
              sx={{
                width: '100%',
                height: !largeScreen
                  ? `calc(100% - ${listHeaderHeight} - ${dataGridFiltersHeight})`
                  : `calc(100% - ${listHeaderHeight})`,
              }}
            >
              <PaginatedDataGrid
                additiveFilters={certsAdditiveFilters}
                columnData={certListColumnData}
                FilterLeftComponent={<WaeButton text="New Cert" onClick={handleAddCert} disabled />}
                isLoading={isCertsLoading}
                onSelectionModelChange={() => {}}
                pageSize={certPageSize}
                page={certPage}
                paginatedData={certsListData}
                pagination
                paginationQuery={certsListPaginationQuery}
                setPageSize={setCertPageSize}
                setPage={setCertPage}
                setRowCount={setCertRowCount}
                sx={{
                  border: 0,
                  width: '100%',
                  paddingLeft: 1,
                }}
                totalRowCount={certsTotalRowCount}
                rowCount={certRowCount}
                style={{ marginRight: theme.spacing(2) }}
              />
            </Grid>
          </Box>
        </Box>
      </Box>
    </RootGrid>
  );
};

export default SkillsAndCerts;
