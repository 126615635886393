import { flow, get, getOr, map } from 'lodash/fp';
import { useIntl } from 'react-intl';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';

import FormTypographyBox from '../../../../../components/Form/FormTypographyBox';
import SearchBox, { SEARCHBAR_BACKGROUND } from '../../../../../components/SearchBar';
import theme from '../../../../../theme';
import {
  minutesFromMidnightToTimeOnlyHrAndMins,
  reformatDateMMMMDDYYYY,
} from '../../../../../utils';

import {
  confirmationSection,
  dateSection,
  locationSection,
  numOpeningsSection,
  positionTemplateSection,
  shiftSection,
  singleEmployeeSection,
} from './CreateJobDrawerCommonFormFields';

const componentWorkFlowFont = ['components', 'workflow', 'typography'];
const workFlowHeaderStyling = {
  fontSize: get([...componentWorkFlowFont, 'defaultTitleFontSize'], theme),
  fontFamily: get([...componentWorkFlowFont, 'boldFont'], theme),
  color: get([...componentWorkFlowFont, 'defaultLastPageFontColor', 'default'], theme),
};

const predefinedLocationOptions = (createJobValues) =>
  flow(
    getOr([], ['positionTemplate', 'locations']),
    map((location) => ({
      address: location.address,
      _id: location._id,
      description: location.description,
    }))
  )(createJobValues);

export const LongTermJobFormFieldDataForAdminAndRecruiter = (
  createJobValues,
  JobCreateShiftGroupToggle,
  role
) => {
  const intl = useIntl();

  return [
    [
      {
        ComponentType: FormTypographyBox,
        key: 'corporationHeader',
        name: 'corporationHeader',
        sx: workFlowHeaderStyling,
      },
      {
        ComponentType: SearchBox,
        background: SEARCHBAR_BACKGROUND.LIGHT,
        preDefinedOptions: [],
        data: null,
        name: 'corporation',
        multiSelect: false,
        required: true,
        variant: 'standard',
        optionLabel: 'name',
        placeholder: 'Enter Corporation',
        fullWidth: true,
        onSubmitApiCallData: {
          httpMethod: 'POST',
          route: 'corporations/read',
        },
        optionDisplayField: 'name',
      },
    ],
    positionTemplateSection(createJobValues),
    [
      {
        ComponentType: FormTypographyBox,
        key: 'locationHeader',
        name: 'locationHeader',
        sx: workFlowHeaderStyling,
      },
      ...locationSection(createJobValues, predefinedLocationOptions(createJobValues)),
    ],
    shiftSection(createJobValues),
    dateSection(createJobValues, intl),
    singleEmployeeSection(JobCreateShiftGroupToggle, createJobValues),
    numOpeningsSection(role),
    confirmationSection(role),
  ];
};

export const LongTermJobFormFieldDataForEmployer = (
  createJobValues,
  employerCorporationName,
  employerCorporationId,
  JobCreateShiftGroupToggle,
  role
) => {
  const intl = useIntl();
  return [
    positionTemplateSection(createJobValues, employerCorporationName),
    [
      {
        ComponentType: FormTypographyBox,
        key: 'employerLocationHeader',
        name: 'employerLocationHeader',
        sx: workFlowHeaderStyling,
      },
      ...locationSection(
        createJobValues,
        predefinedLocationOptions(createJobValues),
        employerCorporationId
      ),
    ],
    shiftSection(createJobValues),
    dateSection(createJobValues, intl),
    singleEmployeeSection(JobCreateShiftGroupToggle, createJobValues),
    numOpeningsSection(role),
    confirmationSection(role),
  ];
};

const workDayLabel = (workDaysValues) => {
  let workDay = [];
  const keys = workDaysValues && Object.keys(workDaysValues);
  keys && keys.forEach((key) => workDaysValues[key] && workDay.push(key));
  workDay = workDay.map((work) => work.slice(0, 2).toUpperCase());
  return workDay.join();
};

export const readOnlyValues = (createJobValues, employerCorporationName) => ({
  corporationHeader: 'What corporation is this job for?',
  confirmationPageHeader:
    'Please verify that all details of your job order are correct before posting.',
  corporationTitle: 'Corporation',
  corporationValue: get(['corporation', 'name'], createJobValues)
    ? `${get(['corporation', 'name'], createJobValues)}`
    : `${employerCorporationName}`,

  daysOfWeek: {
    sunday: false,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
  },
  dateHeader: 'When will this job take place?',
  calendarDate: 'Calendar Date',
  calendarDateDescription:
    'Select a date from the calendar below. If the shift is overnight, select the date on which the shift time begins (ex: 11:30 PM on 01/02 would have a start date of 01/02)',
  corporationLocationTitle: 'Location',
  corporationLocationValue: `${get(['location', 'description'], createJobValues)} - ${get(
    ['location', 'address'],
    createJobValues
  )}`,
  corporationDepartmentTitle: 'Department',
  corporationDepartmentValue: `${get(['location', 'description'], createJobValues)} - ${get(
    ['department', 'name'],
    createJobValues
  )}`,
  jobDateTitle: 'Job Date',
  jobDateValue: `${reformatDateMMMMDDYYYY(
    get(['startDate'], createJobValues)
  )} - ${reformatDateMMMMDDYYYY(get(['endDate'], createJobValues))}`,
  positionTemplateHeader: 'What position would you like to fill for this job order?',
  positionTemplateSubHeader: 'Select a position from the list below.',
  positionTitle: 'Position',
  positionValue: `${get(['positionTemplate', 'name'], createJobValues)}`,
  employerCorporationValue: `${employerCorporationName}`,
  employerLocationHeader: `Which ${employerCorporationName} location and department is this new order for?`,
  locationHeader: `Which ${get(
    ['corporation', 'name'],
    createJobValues
  )} location is this new order for?`,
  locationSubHeader: 'Select the location and department from the list below.',
  numOpeningHeader: `How many openings are available for ${get(
    ['shift', 'name'],
    createJobValues
  )} at ${minutesFromMidnightToTimeOnlyHrAndMins(get(['shift', 'start'], createJobValues))}?`,
  numOpeningSubHeader: LanguageConverter('jobOrder.create.openings.header'),
  overStaffOpeningsSubHeader: LanguageConverter('jobOrder.create.overStaff.header'),
  overStaffTitle: LanguageConverter('jobOrder.overStaff.openings'),
  overStaffValue: `${createJobValues?.numOverstaff || '-'}`,
  openingsTitle: LanguageConverter('jobOrder.openings'),
  openingsValue: `${get(['numOpenings'], createJobValues)}`,
  shiftHeader: 'Which shifts should be included in this job order?',
  shiftSubHeader: 'Select shifts from the list below.',
  shiftTitle: 'Shifts',
  shiftValue: `${get(
    ['shift', 'name'],
    createJobValues
  )} - ${minutesFromMidnightToTimeOnlyHrAndMins(
    get(['shift', 'start'], createJobValues)
  )} -${minutesFromMidnightToTimeOnlyHrAndMins(get(['shift', 'end'], createJobValues))}`,
  shiftRequirementsHeader: 'Shift Requirements',
  shiftRequirementsSubHeader:
    'If this is not necessary for your job, proceed to the next step to finish creating your job.',
  singleEmployeeShift: true,
  singleEmployeeOptionHeader: 'Single employee for all shifts',
  singleEmployeeOptionSubHeader:
    'Require employee sign up to apply to all available shifts within a job order',
  requireApprovalOptionHeader: 'Require Approvals for employee placements',
  requireApprovalOptionSubHeader:
    'This gives you extra control over who shows up for your shift,but employees will not appear on your roster or for work unless you approve their placement applications',
  workDayHeader: 'Work Day(s)',
  workDaySubHeader: 'Select the day(s) of the week for this job order.',
  workdayLabelValue: workDayLabel(get(['daysOfWeek'], createJobValues)),
  approvalRequired: get(['positionTemplate', 'allowEmployerApproval'], createJobValues),
  requireApprovalTitle: 'Require Employer Approval',
  requireApprovalValue: get('approvalRequired', createJobValues) ? 'Yes' : 'No',
});
