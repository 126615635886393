import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import {
  PLACEMENT_STATUS_ACTIVE,
  PLACEMENT_STATUS_APPLIED,
  PLACEMENT_STATUS_CANCELLED,
  PLACEMENT_STATUS_INVITED,
} from '@careerstart/wae-common/src/main/constants/constants';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';

import theme from '../../theme';

import ApplicantsTab from './ApplicantsTab';
import CancelledTab from './CancelledTab';
import InvitationTab from './InvitationTab';
import RosterTab from './RosterTab';

const rosterWidgetPaletteTabDarkSelected = get(
  ['components', 'rosterWidget', 'palette', 'selected'],
  theme
);

const rosterWidgetTypographyTabSelected = get(
  ['components', 'rosterWidget', 'typography', 'selected'],
  theme
);

const tabStyle = {
  '&.Mui-selected': {
    color: get('fontColor', rosterWidgetPaletteTabDarkSelected),
  },
  color: get(['components', 'rosterWidget', 'palette', 'tabTextColor'], theme),
  fontFamily: get('fontFamily', rosterWidgetTypographyTabSelected),
  fontSize: get('fontSize', rosterWidgetTypographyTabSelected),
  textTransform: 'none',
};

const tabPanelStyle = {
  color: get(['components', 'rosterWidget', 'palette', 'tabWhiteTextColor'], theme),
  padding: 0,
};

const RosterWidget = ({
  cancelled,
  corporation,
  employees,
  jobType,
  jobOrderId,
  full,
  handelOnCancelledPlacementUpdate,
  isCancelledPlacementUpdating,
  start,
  end,
}) => {
  const [value, setValue] = React.useState('0');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const activeEmployees = employees?.filter(
    (employee) => employee?.state?.status === PLACEMENT_STATUS_ACTIVE
  );

  const invitedEmployees = employees?.filter(
    (employee) => employee?.state?.status === PLACEMENT_STATUS_INVITED
  );
  const appliedEmployees = employees?.filter(
    (employee) => employee?.state?.status === PLACEMENT_STATUS_APPLIED
  );
  const cancelledCandidates = employees?.filter(
    (employee) => employee?.state?.status === PLACEMENT_STATUS_CANCELLED
  );

  const tabData = [
    {
      tabPanel: (
        <RosterTab
          activeEmployees={activeEmployees}
          corporation={corporation}
          jobType={jobType}
          cancelled={cancelled}
          jobOrderId={jobOrderId}
          start={start}
          end={end}
        />
      ),
      tabLabel: `Roster (${activeEmployees?.length || 0})`,
      tabValue: '0',
    },
    {
      tabPanel: (
        <InvitationTab
          selectedJobInvitations={invitedEmployees}
          corporation={corporation}
          jobType={jobType}
          jobOrderId={jobOrderId}
        />
      ),
      tabLabel: `Invitations (${invitedEmployees?.length || 0})`,
      tabValue: '1',
    },
    {
      tabLabel: `Applications (${appliedEmployees?.length || 0})`,
      tabPanel: <ApplicantsTab jobApplicants={appliedEmployees} jobType={jobType} full={full} />,
      tabValue: '2',
    },
    {
      tabLabel: `Canceled (${cancelledCandidates?.length || 0})`,
      tabPanel: (
        <CancelledTab
          cancelledCandidates={cancelledCandidates}
          corporation={corporation}
          jobType={jobType}
          handelOnCancelledPlacementUpdate={handelOnCancelledPlacementUpdate}
          isCancelledPlacementUpdating={isCancelledPlacementUpdating}
        />
      ),
      tabValue: '3',
    },
  ];

  return (
    <TabContext value={value}>
      <Box sx={{ display: 'flex' }}>
        <TabList onChange={handleChange} aria-label="lab API tabs example" sx={{ width: '100%' }}>
          {tabData.map((tab) => (
            <Tab
              style={{ textTransform: 'none' }}
              key={get('tabValue', tab)}
              label={get('tabLabel', tab)}
              sx={tabStyle}
              value={get('tabValue', tab)}
            />
          ))}
        </TabList>
      </Box>
      {tabData.map((tab) => (
        <TabPanel key={get('tabValue', tab)} value={get('tabValue', tab)} sx={tabPanelStyle}>
          {get('tabPanel', tab)}
        </TabPanel>
      ))}
    </TabContext>
  );
};

RosterWidget.propTypes = {
  jobType: PropTypes.string,
  employees: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
  start: PropTypes.number,
  end: PropTypes.number,
  flags: PropTypes.shape({}),
  full: PropTypes.bool,
  jobOrderId: PropTypes.string,
  cancelled: PropTypes.bool,
  corporation: PropTypes.oneOfType([
    PropTypes.shape({
      favorite: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    PropTypes.string,
  ]),
  handelOnCancelledPlacementUpdate: PropTypes.func,
  isCancelledPlacementUpdating: PropTypes.bool,
};

RosterWidget.propTypes = {};

export default RosterWidget;
