import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { get, map } from 'lodash/fp';
import { batch, useDispatch, useSelector } from 'react-redux';

import UpdatePositionRequestSchema from '@careerstart/wae-common/schema/positionTemplates/update.patch.req.json';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import BackdropCircularProgress from '../../../components/BackdropCircularProgress';
import WaePaginatedDataGrid from '../../../components/DataGrid';
import DetailView from '../../../components/DetailView/DetailView2';
import { validateSchema } from '../../../components/Form/validations';
import ListHeader from '../../../components/ListHeader';
import selectUser from '../../../store/selectors/appSelector';
import {
  selectCertifications,
  selectIsDeleteProcessing,
  selectIsLoading,
  selectIsLoadingCertifications,
  selectIsLoadingCorporations,
  selectIsLoadingLocations,
  selectIsUpdateProcessing,
  selectLocations,
  selectPositionTemplateListRowData,
  selectTotalRowCount,
} from '../../../store/selectors/positionTemplateListSelectors';
import theme, {
  dataGridFiltersHeight,
  listHeaderHeight,
  navigationBarHeight,
} from '../../../theme';
import { decimalToPercentageString, percentageStringToDecimal } from '../../../utils';
import { postPositionTemplate } from '../createPositionTemplateDrawer';
import CreatePositionTemplateDrawer from '../createPositionTemplateDrawer/CreatePositionTemplateDrawer';

import DeleteDrawer from './detail/DeleteDrawer';
import additionalFilters from './additionalFilters';
import {
  AdminOverview,
  AdminShiftDetail,
  EmployerOverview,
  EmployerShiftDetail,
  RecruiterOverview,
  RecruiterShiftDetail,
} from './detail';
import getPositionTemplateListColumnData from './positionTemplateListColumnData';
import {
  getCertificationsForPositionTemplateList,
  getCorporationsForPositionTemplateList,
  getLocationsForPositionTemplateList,
  getPositionTemplateList,
} from './reducer';
import { deletePositionTemplate, updatePositionTemplate } from '.';

const RootGrid = styled(Grid)(() => ({
  height: `calc(100vh - ${navigationBarHeight})`,
  position: 'relative',
  top: navigationBarHeight,
  padding: theme.spacing(2, 0),
}));

const PositionTemplateList = () => {
  const dispatch = useDispatch();
  const muiTheme = useTheme();
  const user = useSelector(selectUser);
  const isLoading = useSelector(selectIsLoading);
  const positionTemplateListRowData = useSelector(selectPositionTemplateListRowData);
  const isLoadingLocations = useSelector(selectIsLoadingLocations);
  const locations = useSelector(selectLocations);
  const isLoadingCertifications = useSelector(selectIsLoadingCertifications);
  const certifications = useSelector(selectCertifications);
  const isLoadingCorporations = useSelector(selectIsLoadingCorporations);
  const isUpdateProcessing = useSelector(selectIsUpdateProcessing);
  const isDeleteProcessing = useSelector(selectIsDeleteProcessing);
  const mediumScreen = useMediaQuery(muiTheme.breakpoints.up('md'));
  const [positionTemplateId, setPositionTemplateId] = useState(null);
  const totalRowCount = useSelector(selectTotalRowCount);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = React.useState(10);
  const [isDeleteDrawerOpen, setIsDeleteDrawerOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const apiPath = 'position-templates/read';

  const positionTemplateListColumnData = useMemo(
    () => getPositionTemplateListColumnData(user.role),
    [user]
  );

  const selectedPosition = useMemo(
    () => positionTemplateListRowData.find(({ id }) => id === positionTemplateId),
    [positionTemplateListRowData, positionTemplateId]
  );

  const selectedData = useMemo(
    () =>
      selectedPosition
        ? {
            ...selectedPosition,
            locations: selectedPosition.locations.map((location) => ({
              ...location,
              name: `${location.description} - ${location.address}`,
            })),
            corporationName: get(['corporation', 'name'], selectedPosition),
            gpsStrategy: get(['gps', 'strategy'], selectedPosition),
            maxPunchDistance: get(['gps', 'maxDistance'], selectedPosition),
            overstaffingPercent: decimalToPercentageString(
              selectedPosition?.overstaffingPercent || 0
            ),
          }
        : null,
    [selectedPosition]
  );

  const allCorporationLocations = useMemo(() => {
    if (!selectedData) return [];
    const { corporation } = selectedData;

    return locations.filter(
      (location) => location.corporation && location.corporation.id === corporation._id
    );
  }, [locations, selectedData]);

  const positionTemplateListPaginationQuery = React.useCallback(
    (params) => dispatch(getPositionTemplateList(params)),
    [dispatch]
  );

  const onSelectionModelChange = (selectedPositionTemplateObject) => {
    setPositionTemplateId(
      (selectedPositionTemplateObject && selectedPositionTemplateObject.id) || null
    );
  };

  const handleClose = () => {
    setPositionTemplateId(null);
  };

  const handlePositionTemplateUpdate = useCallback(
    (formData, initialValues) => {
      const getFormValueButDefaultToGlobalInitialValue = (fieldName) => {
        /*
         * DevNote: Be really careful with this undefined check. Undefined in this case means
         * that the form does NOT have the value. Not that the value is being set as null by the user.
         */
        if (get(fieldName, formData) !== undefined) {
          return get(fieldName, formData);
        }
        return get(fieldName, initialValues);
      };

      const currencyHelper = (fieldName) => {
        const rawValue = getFormValueButDefaultToGlobalInitialValue(fieldName);
        return (
          (rawValue &&
            typeof rawValue === 'string' &&
            Number(rawValue.replace(/[^0-9.-]+/g, ''))) ||
          rawValue
        );
      };

      const fields = {
        name: getFormValueButDefaultToGlobalInitialValue('name'),
        description: getFormValueButDefaultToGlobalInitialValue('description'),
        approved: getFormValueButDefaultToGlobalInitialValue('approved'),
        benefitsPack: getFormValueButDefaultToGlobalInitialValue('benefitsPack'),
        billRateDT: currencyHelper('billRateDT'),
        billRateOT: currencyHelper('billRateOT'),
        billRateReg: currencyHelper('billRateReg'),
        branch: getFormValueButDefaultToGlobalInitialValue('branch'),
        certifications: map(
          (cert) => cert._id,
          getFormValueButDefaultToGlobalInitialValue('certifications')
        ),
        departments: map(
          (dept) => dept._id,
          getFormValueButDefaultToGlobalInitialValue('departments')
        ),
        skills: map((skill) => skill._id, getFormValueButDefaultToGlobalInitialValue('skills')),
        legalBusEntry: getFormValueButDefaultToGlobalInitialValue('legalBusEntry'),
        locations: map((loc) => loc._id, getFormValueButDefaultToGlobalInitialValue('locations')),
        payGroup: getFormValueButDefaultToGlobalInitialValue('payGroup'),
        overstaffingPercent: percentageStringToDecimal(
          getFormValueButDefaultToGlobalInitialValue('overstaffingPercent')
        ),
        payRateDT: currencyHelper('payRateDT'),
        payRateOT: currencyHelper('payRateOT'),
        payRateReg: currencyHelper('payRateReg'),
        saleRep: getFormValueButDefaultToGlobalInitialValue('saleRep'),
        shifts: getFormValueButDefaultToGlobalInitialValue('shifts'),
        workersCompCode: getFormValueButDefaultToGlobalInitialValue('workersCompCode'),
      };

      const data = {
        id: positionTemplateId,
        fields,
      };

      if (get('FORM_ERROR', validateSchema(UpdatePositionRequestSchema, data))) {
        return;
      }

      dispatch(updatePositionTemplate(data));
    },
    [dispatch, positionTemplateId]
  );

  const handlePositionTemplateDelete = useCallback(() => {
    dispatch(deletePositionTemplate({ id: positionTemplateId }));
    handleClose();
  }, [dispatch, positionTemplateId]);

  const createPositionTemplateOnClick = () => {
    setIsDrawerOpen(true);
  };

  useEffect(() => {
    onSelectionModelChange(null);
  }, [positionTemplateListRowData]);

  useEffect(() => {
    batch(() => {
      dispatch(getCertificationsForPositionTemplateList());
      dispatch(getCorporationsForPositionTemplateList());
      dispatch(getLocationsForPositionTemplateList());
    });
  }, [dispatch]);

  const tabData = (container) => [
    {
      tabContent: (
        <Grid sx={{ width: '100%' }}>
          {(get('role', user) === 'admin' && (
            <AdminOverview
              availableLocations={allCorporationLocations} // TODO: Remove available locations once searchbar supports API filters!
              container={container}
              initialValues={selectedData}
              onDelete={setIsDeleteDrawerOpen}
              onUpdate={handlePositionTemplateUpdate}
            />
          )) ||
            (get('role', user) === 'recruiter' && (
              <RecruiterOverview
                availableLocations={allCorporationLocations} // TODO: Remove available locations once searchbar supports API filters!
                container={container}
                initialValues={selectedData}
                onDelete={setIsDeleteDrawerOpen}
                onUpdate={handlePositionTemplateUpdate}
              />
            )) ||
            (get('role', user) === 'employer' && (
              <EmployerOverview
                availableLocations={allCorporationLocations} // TODO: Remove available locations once searchbar supports API filters!
                container={container}
                initialValues={selectedData}
                onDelete={setIsDeleteDrawerOpen}
                onUpdate={handlePositionTemplateUpdate}
              />
            ))}
        </Grid>
      ),
      tabLabel: 'Overview',
    },
    {
      tabContent: (
        <Grid sx={{ width: '100%' }}>
          {(get('role', user) === 'admin' && (
            <AdminShiftDetail
              certifications={certifications}
              container={container}
              initialValues={selectedData}
              onUpdate={handlePositionTemplateUpdate}
            />
          )) ||
            (get('role', user) === 'recruiter' && (
              <RecruiterShiftDetail
                certifications={certifications}
                container={container}
                initialValues={selectedData}
                onUpdate={handlePositionTemplateUpdate}
              />
            )) ||
            (get('role', user) === 'employer' && (
              <EmployerShiftDetail
                certifications={certifications}
                container={container}
                initialValues={selectedData}
                onUpdate={handlePositionTemplateUpdate}
              />
            ))}
        </Grid>
      ),
      tabLabel: 'Shift Details',
    },
  ];

  const Detail = () => (
    <DetailView
      close={handleClose}
      onUpdate={handlePositionTemplateUpdate}
      tabData={tabData}
      sx={{ marginLeft: theme.spacing(1.5), height: `calc(100% - ${listHeaderHeight})` }}
    />
  );

  return (
    <RootGrid container>
      <Slide
        direction="right"
        unmountOnExit
        in={!(!mediumScreen && positionTemplateId)}
        timeout={10}
        easing={{ enter: 'step-end', exit: 'step-start' }}
      >
        <Grid
          item
          container
          md
          xs
          sm
          sx={{
            height: `calc(100% - ${listHeaderHeight} - ${dataGridFiltersHeight} )`,
            marginRight: positionTemplateId && 2,
          }}
        >
          <ListHeader
            headerText="Positions"
            buttonText="New Position"
            handleOnClick={createPositionTemplateOnClick}
          />

          <Grid
            container
            sx={{
              height: `calc(100% - ${listHeaderHeight})`,
              width: '100%',
            }}
          >
            {positionTemplateListRowData && (
              <>
                <WaePaginatedDataGrid
                  apiPath={apiPath}
                  additiveFilters={additionalFilters}
                  columnData={positionTemplateListColumnData}
                  onSelectionModelChange={onSelectionModelChange}
                  loading={isLoading}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  pageSize={pageSize}
                  page={page}
                  paginatedData={positionTemplateListRowData}
                  pagination
                  paginationQuery={positionTemplateListPaginationQuery}
                  setPageSize={setPageSize}
                  setPage={setPage}
                  setRowCount={setRowCount}
                  rowCount={rowCount}
                  totalRowCount={totalRowCount}
                  sx={{
                    border: 'none',
                    height: '100%',
                    width: '100%',
                  }}
                />
                {positionTemplateId && <Detail />}
              </>
            )}
          </Grid>
        </Grid>
      </Slide>
      <DeleteDrawer
        anchor="right"
        handlePositionTemplateDelete={() => handlePositionTemplateDelete()}
        isDeleteProcessing={isDeleteProcessing}
        isOpen={isDeleteDrawerOpen}
        onClose={() => setIsDeleteDrawerOpen(false)}
      />
      {!isLoadingCertifications && !isLoadingLocations && !isLoadingCorporations && (
        <>
          {!mediumScreen && positionTemplateId && <Detail />}

          {(isUpdateProcessing || isDeleteProcessing) && <BackdropCircularProgress />}
        </>
      )}
      <CreatePositionTemplateDrawer
        isOpen={isDrawerOpen}
        certifications={certifications}
        onClose={() => setIsDrawerOpen(false)}
        postPositionTemplate={(postPositionTemplateData) =>
          dispatch(postPositionTemplate(postPositionTemplateData))
        }
      />
    </RootGrid>
  );
};

export default PositionTemplateList;
