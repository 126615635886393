/* eslint-disable react/prop-types */
import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

import selectUser from '../../store/selectors/appSelector';
import theme, { navigationBarHeight } from '../../theme';
import {
  epochToDateInReadableFormat,
  epochToTimeInReadableFormat,
  getDiffBetweenTwoEpoch,
} from '../../utils';
import BackdropCircularProgress from '../BackdropCircularProgress';
import IconButton from '../IconButton';
import Label from '../Label';
import SwitchComponent from '../SwitchComponent/SwitchComponent';

const headerText = 'Placement Cancelled';

const rosterTypography = theme?.components?.rosterWidget?.typography;
const attendanceTheme = theme?.label?.attendance;

const keyStyle = {
  fontColor: rosterTypography?.cancelledPlacementKey?.fontColor,
  fontSize: rosterTypography?.cancelledPlacementKey?.fontSize,
  fontFamily: rosterTypography?.cancelledPlacementKey?.fontFamily,
};

const valStyle = {
  fontColor: rosterTypography?.cancelledPlacementVal?.fontColor,
  fontSize: rosterTypography?.cancelledPlacementVal?.fontSize,
  fontFamily: rosterTypography?.cancelledPlacementVal?.fontFamily,
};

const RootBox = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  padding: theme.spacing(8, 8, 0),
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));

const HeaderBoxTypography = styled(Box)(() => ({
  fontColor: rosterTypography?.cancelledPlacementHeader?.fontColor,
  fontSize: rosterTypography?.cancelledPlacementHeader?.fontSize,
  fontFamily: rosterTypography?.cancelledPlacementHeader?.fontFamily,
}));

const CancelledPlacementDetailDrawer = ({
  candidate,
  isOpen,
  onClose,
  handleUpdate,
  isCancelledPlacementUpdating,
}) => {
  const user = useSelector(selectUser);
  const absent = candidate?.calcAttendance?.absent?.generatedBySystem;
  const excusedAbsent = candidate?.calcAttendance?.absent?.excused?.time;

  const onChange = () => {
    const updateBody = {
      id: candidate?._id,
      fields: {
        calcAttendance: {
          absent: !excusedAbsent,
        },
      },
    };
    handleUpdate(updateBody);
  };
  const couldBeExcused =
    (user.role === UserRole.ADMIN || user.role === UserRole.EMPLOYER) && (absent || excusedAbsent);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{ sx: { maxWidth: '576px', paddingTop: navigationBarHeight, height: '100vh' } }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'end', padding: theme.spacing(2) }}>
        <IconButton
          iconElement={<CloseIcon />}
          onClick={onClose}
          size="small"
          sx={{ marginLeft: 0.5, marginTop: 0.5, position: 'fixed' }}
        />
      </Box>
      <RootBox>
        <Box>
          <HeaderBoxTypography>{`${headerText}`}</HeaderBoxTypography>
          <Grid container sx={{ mt: 2 }}>
            <Typography sx={keyStyle}>Cancelled by</Typography>
            <Typography sx={valStyle}> : {candidate?.cancellingUser}</Typography>
          </Grid>
          {(user.role === UserRole.ADMIN || user.role === UserRole.RECRUITER) && (
            <Grid container direction="column" sx={{ mt: 2 }}>
              <Typography sx={keyStyle}>Reason</Typography>
              <Typography sx={valStyle}>{candidate?.state?.cancelNote || '--'}</Typography>
            </Grid>
          )}
          <Grid container sx={{ mt: 2 }}>
            <Typography sx={keyStyle}>Time cancelled</Typography>
            <Typography sx={valStyle}>
              : {epochToDateInReadableFormat(candidate?.state?.timestamp)} at{' '}
              {epochToTimeInReadableFormat(candidate?.state?.timestamp)}
            </Typography>
          </Grid>
          <Grid container sx={{ mt: 2 }}>
            <Typography sx={keyStyle}>Time till job</Typography>
            <Typography sx={valStyle}>
              :{' '}
              {getDiffBetweenTwoEpoch(
                get(['state', 'timestamp'], candidate),
                get(['jobOrderStart'], candidate)
              )}
            </Typography>
          </Grid>
          <Grid container direction="column" sx={{ mt: 2 }}>
            <Typography sx={keyStyle}>Status</Typography>
            {couldBeExcused && user.role === UserRole.ADMIN && (
              <Typography sx={valStyle}>
                {LanguageConverter('attendance.status.subTitle')}
              </Typography>
            )}
          </Grid>

          <Grid container>
            {couldBeExcused && user.role === UserRole.ADMIN && (
              <SwitchComponent initialValue={!!excusedAbsent} handleOnChange={onChange} />
            )}
            {(excusedAbsent && (
              <Label
                sx={{
                  backgroundColor: attendanceTheme?.bgColor?.excusedAbsent,
                  color: attendanceTheme?.color?.excusedAbsent,
                  width: '150px',
                }}
                text={LanguageConverter('attendance.status.excusedAbsent')}
                sxForText={{
                  fontFamily: attendanceTheme?.font,
                }}
              />
            )) ||
              (absent && (
                <Label
                  sx={{
                    backgroundColor: attendanceTheme?.bgColor?.absent,
                    color: attendanceTheme?.color?.absent,
                  }}
                  text={LanguageConverter('attendance.status.absent')}
                  sxForText={{
                    fontFamily: attendanceTheme?.font,
                  }}
                />
              )) || (
                <Label
                  sx={{
                    backgroundColor: attendanceTheme?.bgColor?.cancelled,
                    color: attendanceTheme?.color?.cancelled,
                  }}
                  text={LanguageConverter('attendance.status.cancelled')}
                  sxForText={{
                    fontFamily: attendanceTheme?.font,
                  }}
                />
              )}
          </Grid>
        </Box>
      </RootBox>
      {isCancelledPlacementUpdating && <BackdropCircularProgress />}
    </Drawer>
  );
};

CancelledPlacementDetailDrawer.propTypes = {
  candidate: PropTypes.shape({}),
  isOpen: PropTypes.bool,
  isCancelledPlacementUpdating: PropTypes.bool,
  onClose: PropTypes.func,
  handleUpdate: PropTypes.func,
};

export default CancelledPlacementDetailDrawer;
