import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import List from '@mui/material/List';

import selectUser from '../../../../../../store/selectors/appSelector';
import { isCandidateReadyToWork } from '../../../../../../views/Candidate/utils';

import DefaultNavMenu from './DefaultNavMenu';
import JobsMenu from './JobsMenu';

const DrawerMenus = ({ closeDrawer, isOpen, toggleDrawer }) => {
  const user = useSelector(selectUser);
  const notReadyToWork = !isCandidateReadyToWork(user);

  return (
    <List component="nav">
      <DefaultNavMenu
        closeDrawer={closeDrawer}
        isOpen={isOpen}
        url="dashboard"
        Icon={AutoAwesomeMosaicOutlinedIcon}
        label="Dashboard"
        testId="dashboard"
      />
      <JobsMenu
        closeDrawer={closeDrawer}
        isOpen={isOpen}
        toggleDrawer={toggleDrawer}
        Icon={BusinessCenterOutlinedIcon}
        testId="jobs"
      />
      <DefaultNavMenu
        closeDrawer={closeDrawer}
        isOpen={isOpen}
        url="timekeeping"
        Icon={QueryBuilderOutlinedIcon}
        label="Time Tracking"
        testId="time-tracking"
      />
      <DefaultNavMenu
        closeDrawer={closeDrawer}
        isOpen={isOpen}
        url="payment"
        Icon={CreditCardOutlinedIcon}
        label="Payment"
        testId="payment"
      />
      {notReadyToWork && (
        <DefaultNavMenu
          closeDrawer={closeDrawer}
          isOpen={isOpen}
          url="verification"
          Icon={DoneOutlinedIcon}
          label="Verification"
          testId="verification"
        />
      )}
    </List>
  );
};

DrawerMenus.propTypes = {
  closeDrawer: PropTypes.func,
  isOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
};

export default DrawerMenus;
