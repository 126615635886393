import React, { useCallback, useEffect } from 'react';
import { get } from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import searchParamOptions from '@careerstart/wae-common/src/main/constants/searchParams';
import { ONE_WEEK_IN_MS } from '@careerstart/wae-common/src/main/constants/timeConversions';
import { useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';

import BackdropCircularProgress from '../../../components/BackdropCircularProgress';
import ClockInOut from '../../../components/Candidate/ClockInOut';
import GeoLocationBanner from '../../../components/Candidate/GeoLocationBanner';
import TimeCard from '../../../components/EmployeeTimeCards/TimeCard';
import selectUser from '../../../store/selectors/appSelector';
import {
  selectIsLoadingNextShift,
  selectIsLoadingTimeCard,
  selectIsProcessingDispute,
  selectIsProcessingPunch,
  selectNextShifts,
  selectPunchError,
  selectTimeCard,
  selectTotalsData,
} from '../../../store/selectors/Candidate/timeCardSelector';
import theme, { navigationBarHeight } from '../../../theme/theme';
import { isSearchParamValid } from '../../../utils';
import { getSunday } from '../../../utils/timeConverter';
import { NEXT_SHIFT_INDEX } from '../constants';

import AlertBanner from './AlertBanner';
import NextShiftCard from './NextShiftCard';
import {
  getNextShift,
  getTimeCards,
  postDisputeTimeCard,
  postPunch,
  setPunchError,
} from './reducer';
import TotalsCard from './TotalsCard';
import WeekFilter from './WeekFilter';

const TimeTracking = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const timeCard = useSelector(selectTimeCard);
  const nextShifts = useSelector(selectNextShifts);
  const totalsData = useSelector(selectTotalsData);
  const isLoadingTimeCard = useSelector(selectIsLoadingTimeCard);
  const isProcessingPunch = useSelector(selectIsProcessingPunch);
  const isLoadingNextShift = useSelector(selectIsLoadingNextShift);
  const isProcessingDispute = useSelector(selectIsProcessingDispute);
  const punchError = useSelector(selectPunchError);
  const phoneScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamStart = searchParams.get(searchParamOptions.START);
  const selectedStart = isSearchParamValid(searchParamStart) ? searchParamStart : false;
  const startEpoch = getSunday(new Date(parseInt(selectedStart, 10))) || getSunday(new Date());

  const selectedPlacement = searchParams.get(searchParamOptions.PLACEMENT);

  const handleWeekChange = (newStartEpoch) => {
    setSearchParams({
      [searchParamOptions.START]: newStartEpoch,
    });
  };

  useEffect(() => {
    dispatch(
      getTimeCards({
        filters: [
          {
            field: 'candidate',
            operation: 'equalsID',
            value: `${user.id}`,
          },
          { operation: 'onOrAfter', field: 'start', value: startEpoch },
          { operation: 'before', field: 'start', value: startEpoch + ONE_WEEK_IN_MS },
        ],
      })
    );
  }, [dispatch, user, startEpoch]);

  useEffect(() => {
    dispatch(getNextShift({ limit: 2 }));
  }, [dispatch]);

  const handleOnDispute = (timeCardId) => {
    dispatch(postDisputeTimeCard({ placement: timeCardId }));
  };

  const isTimecardSelectedPlacement = useCallback(
    (card) => {
      if (get('placementId', card) === selectedPlacement) {
        return true;
      }
      return false;
    },
    [selectedPlacement]
  );

  return (
    <Grid
      sx={{
        height: phoneScreen ? '100vh' : '80vh',
        paddingTop: navigationBarHeight,
        overflow: 'auto',
      }}
    >
      <h1>Time Tracking</h1>
      <GeoLocationBanner />

      {get(['isActive'], nextShifts[NEXT_SHIFT_INDEX.firstShift]) ? (
        <>
          <AlertBanner
            messageText="Earnings displayed are pre-tax and do not include any withhold amounts. The final amount
          received may be different after taxes and other deductions have been taken out."
          />
          <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
            {nextShifts.map(
              (shift) =>
                get(['isActive'], shift) && (
                  <Grid
                    item
                    xs={12}
                    md={get(['isActive'], nextShifts[1]) ? 6 : 12}
                    key={get(['placementId'], shift)}
                  >
                    <ClockInOut
                      disablePunch={get(['disablePunch'], shift)}
                      corporationName={get(['corporationName'], shift)}
                      endTime={get(['endTime'], shift)}
                      hasPunches={get(['placementHasPunches'], shift)}
                      isPunchedIn={get(['candidateIsPunchedIn'], shift)}
                      isProcessingPunch={isProcessingPunch}
                      placementId={get(['placementId'], shift)}
                      postPunch={(val) => dispatch(postPunch(val))}
                      punchError={punchError}
                      setPunchError={setPunchError}
                      shiftName={get(['shiftName'], shift)}
                      startTime={get(['startTime'], shift)}
                    />
                  </Grid>
                )
            )}
          </Grid>
        </>
      ) : (
        <NextShiftCard
          corporationName={get(['corporationName'], nextShifts[NEXT_SHIFT_INDEX.firstShift])}
          endTime={get(['endTime'], nextShifts[NEXT_SHIFT_INDEX.firstShift])}
          shiftName={get(['shiftName'], nextShifts[NEXT_SHIFT_INDEX.firstShift])}
          startDate={get(['startDate'], nextShifts[NEXT_SHIFT_INDEX.firstShift])}
          startTime={get(['startTime'], nextShifts[NEXT_SHIFT_INDEX.firstShift])}
        />
      )}
      <WeekFilter
        handleWeekChange={(newStartEpoch) => handleWeekChange(newStartEpoch)}
        startEpoch={startEpoch}
      />
      <Grid container spacing={2} direction="row" paddingBottom="16px">
        <TotalsCard content={get(['shiftsThisWeek'], totalsData)} label="Shifts this Week" />
        <TotalsCard
          content={get(['hoursThisWeek'], totalsData).toFixed(2)}
          label="Hours this Week"
        />
        <TotalsCard
          content={`$${get(['earningsThisWeek'], totalsData).toFixed(2)}`}
          label="Earnings this Week"
        />
      </Grid>
      {timeCard.length > 0 && (
        <Grid container spacing={2} direction="column-reverse">
          {timeCard.map((item) => (
            <Grid
              item
              key={get(['key'], item)}
              width="100%"
              ref={(element) => {
                if (isTimecardSelectedPlacement(item) && element) {
                  element.scrollIntoView();
                }
                return null;
              }}
            >
              <TimeCard
                corporation={get(['corporation'], item)}
                date={get(['date'], item)}
                startTime={get(['startTime'], item)}
                endTime={get(['endTime'], item)}
                totalEarnings={get(['totalEarnings'], item)}
                mode={get(['mode'], item)}
                name={get(['name'], item)}
                handleOnDispute={() => handleOnDispute(get(['placementId'], item))}
                punches={get(['punches'], item)}
                totalHours={get(['totalHours'], item)}
                isCompleted={get(['isCompleted'], item)}
                status={get(['status'], item)}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {(isLoadingTimeCard || isLoadingNextShift || isProcessingDispute) && (
        <BackdropCircularProgress />
      )}
    </Grid>
  );
};

export default TimeTracking;
