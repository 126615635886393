import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import omit from 'lodash/fp/omit';

import { JOB_CATEGORY } from '@careerstart/wae-common/src/main/constants/jobInformation';
import { createSlice } from '@reduxjs/toolkit';

import { epochToDateInReadableFormat, epochToTimeInReadableFormat } from '../../../utils';

export const reformatJobEntry = (jobEntry) => {
  const rest = omit(['_id'], jobEntry);
  return {
    id: get('_id', jobEntry),
    title: get('name', jobEntry),
    dayJobInfo: {
      name: get('name', jobEntry),
      corporation: get(['corporation', 'name'], jobEntry),
    },
    longTermJobInfo: {
      address: get(['location', 'address'], jobEntry),
      description: get(['location', 'description'], jobEntry),
      name: get('jobName', jobEntry),
      corporation: get('corporationName', jobEntry),
    },
    jobPlacements: get('placementsCount', jobEntry),
    numOverstaff: jobEntry?.numOverstaff,
    address: get(['location', 'address'], jobEntry),
    startDate: epochToDateInReadableFormat(get('start', jobEntry)),
    startTime: epochToTimeInReadableFormat(get('start', jobEntry)),
    endDate: epochToDateInReadableFormat(get('end', jobEntry)),
    endTime: epochToTimeInReadableFormat(get('end', jobEntry)),
    fillRate: Math.floor((100 * get('placementsCount', jobEntry)) / get('numOpenings', jobEntry)),
    ...rest,
  };
};

const enrichRosterData = (jobOrder, placements) => ({
  employees: placements.map((placement) => ({
    id: placement.candidate,
    jobOrderStart: jobOrder?.start,
    jobOrderShiftName: jobOrder?.shiftName,
    jobOrderName: jobOrder?.name,
    ...placement,
  })),
});

const initialState = {
  corporations: [],
  selectedJobType: '',
  jobListRowData: [],
  jobRoster: {
    employees: [],
  },

  totalRowCount: 0,
  isLoading: false,
  isCreateJobsDialogOpen: false,
  isCancelProcessing: false,
  isUpdateProcessing: false,
  isCancelledPlacementUpdating: false,
  isLoadingCorporations: false,
  isLoadingJobRoster: false,
  isPostingJobRoster: false,
  isLoadingPathSelectedJob: false,
  users: [],
  positionTemplateData: [],
  isLoadingPt: false,
  groupOrders: [],
  isLoadingGroupOrders: false,
  isProcessingPutGroupOrders: false,
  isCancelPlacementProcessing: false,
  shiftGroupList: [],
  shiftGroupListCount: 0,
  isLoadingShiftGroupList: false,
  cancelledShiftCount: 0,
  isLoadingShiftGroupCancelledCount: false,
  errorPutGroupOrders: null,
  selectedJobId: null,
  isRevokingJobInvite: false,
  isSelectedJobPopped: false,
};
export const jobListSlice = createSlice({
  name: 'jobListSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */

    getJobsList: (state) => {
      state.isLoading = true;
    },
    getJobsListProcessed: (state, action) => {
      state.isLoading = false;
      const { count, documents } = action.payload.data;
      const updatedData = documents.map((jobEntry) => reformatJobEntry(jobEntry));
      state.jobListRowData = updatedData;
      state.totalRowCount = count;
    },

    getJobsListError: (state) => {
      state.isLoading = false;
      state.jobListRowData = [];
    },
    setSelectedJobType: (state, action) => {
      state.selectedJobType = action.payload;
    },
    setSelectedJob: (state, action) => {
      state.selectedJobId = action.payload;
    },
    getPathSelectedJob: (state) => {
      state.isLoadingPathSelectedJob = true;
    },
    getPathSelectedJobProcessed: (state, action) => {
      state.isLoadingPathSelectedJob = false;
      const { documents } = action.payload.data;
      const parsedJob = reformatJobEntry(documents[0]);

      // if selectedJobId is popped remove it to avoid multi popped jobs
      if (state.isSelectedJobPopped) {
        state.jobListRowData.splice(0, 1);
      }

      const jobExistsInCurrentPage = state.jobListRowData.find((j) => j.id === parsedJob.id);
      if (parsedJob?.id) {
        if (!jobExistsInCurrentPage) {
          state.jobListRowData.unshift(parsedJob);
          state.isSelectedJobPopped = true;
        } else {
          state.isSelectedJobPopped = false;
        }
        state.selectedJobId = parsedJob.id;
      }
    },

    getPathSelectedJobError: (state) => {
      state.isLoadingPathSelectedJob = false;
    },
    cancelJob: (state) => {
      state.isCancelProcessing = true;
    },
    cancelJobProcessed: (state, action) => {
      const { document } = action.payload.data;
      const updatedJob = reformatJobEntry(document);
      state.jobListRowData = state.jobListRowData.map((job) =>
        job.id === updatedJob.id ? { ...updatedJob } : job
      );
      state.isCancelProcessing = false;
    },
    cancelJobError: (state) => {
      state.isCancelProcessing = false;
    },
    cancelShiftGroup: (state) => {
      state.isCancelProcessing = true;
    },
    cancelShiftGroupProcessed: (state, action) => {
      const { document } = action.payload.data;
      const updatedJob = reformatJobEntry(document);
      state.jobListRowData = state.jobListRowData.map((job) =>
        job.id === updatedJob.id ? { ...updatedJob } : job
      );
      state.isCancelProcessing = false;
    },
    cancelShiftGroupError: (state) => {
      state.isCancelProcessing = false;
    },
    updateJob: (state) => {
      state.isUpdateProcessing = true;
    },
    updateJobProcessed: (state, action) => {
      const { document } = action.payload.data;
      const updatedJob = reformatJobEntry(document);
      state.jobListRowData = state.jobListRowData.map((job) =>
        job.id === updatedJob.id ? { ...updatedJob } : job
      );
      state.isUpdateProcessing = false;
    },

    updateJobError: (state) => {
      state.isUpdateProcessing = false;
    },
    getCorporationsForJobsList: (state) => {
      state.isLoadingCorporations = true;
    },
    getCorporationsForJobsListProcessed: (state, action) => {
      const documents = get(['payload', 'data', 'documents'], action);
      const corporations = map((corporation) => {
        const { _id, ...rest } = corporation;
        return {
          id: _id,
          ...rest,
        };
      }, documents);
      state.corporations = corporations;
      state.isLoadingCorporations = false;
    },
    getCorporationsForJobsListError: (state) => {
      state.isLoadingCorporations = false;
    },
    getJobRoster: (state) => {
      state.isLoadingJobRoster = true;
    },
    getJobRosterProcessed: (state, action) => {
      state.isLoadingJobRoster = false;
      const jobRosterResp = get(['payload', 'data', 'document'], action);
      if (jobRosterResp && jobRosterResp.placements && jobRosterResp.placements.length > 0) {
        const jobOrder = jobRosterResp?.jobOrder;
        const updatedData = {
          [jobOrder._id]: enrichRosterData(jobOrder, jobRosterResp.placements),
        };

        state.jobRoster = { ...state.jobRoster, ...updatedData };
      } else {
        state.jobRoster = {};
      }
    },
    getJobRosterError: (state) => {
      state.isLoadingJobRoster = false;
    },

    approveOrRejectPlacement: (state) => {
      state.isLoadingApproveOrRejectPlacement = true;
    },
    approveOrRejectPlacementProcessed: (state, action) => {
      state.isLoadingApproveOrRejectPlacement = false;
      const applicationResponseData = get(['payload', 'data', 'document'], action);
      const jobOrderId = get('jobOrder', applicationResponseData);
      const updatedPlacementData = {
        id: get(['candidate', 'id'], applicationResponseData),
        _id: get('_id', applicationResponseData),
        candidate: get(['candidate', 'id'], applicationResponseData),
        name: get(['candidate', 'name'], applicationResponseData),
        metadata: get('metadata', applicationResponseData),
        state: get('state', applicationResponseData),
      };

      state.jobRoster[jobOrderId].employees = state.jobRoster[jobOrderId].employees.map(
        (emp) =>
          (emp._id === get('_id', updatedPlacementData) && {
            ...emp,
            ...updatedPlacementData,
          }) ||
          emp
      );
    },
    approveOrRejectPlacementError: (state) => {
      state.isLoadingApproveOrRejectPlacement = false;
    },

    revokeJobInvitations: (state, action) => {
      state.isRevokingJobInvite = get(['payload', 'placement'], action);
    },
    revokeJobInvitationsProcessed: (state) => {
      state.isLoadingRevokeJobInvitations = false;
      state.jobRoster[state.selectedJobId].employees = state.jobRoster[
        state.selectedJobId
      ].employees.filter((item) => item._id !== state.isRevokingJobInvite);
      state.isRevokingJobInvite = false;
    },
    revokeJobInvitationsError: (state) => {
      state.isRevokingJobInvite = false;
    },

    updateJobRosterEmployee: (state) => {
      state.isPostingJobRoster = true;
    },
    updateJobRosterEmployeeProcessed: (state, action) => {
      // Note : If you will change structure of state.jobRoster in this action call, make sure you update another actions where state.jobRoster updated in this slice
      state.isPostingJobRoster = false;
      const jobRosterResp = get(['payload', 'data', 'document'], action);
      if (jobRosterResp) {
        const updatedData = {
          attendance: get('attendance', jobRosterResp),
          state: get('state', jobRosterResp),
          candidate: get('candidate', jobRosterResp),
          employerNote: get('employerNote', jobRosterResp),
          rating: get('rating', jobRosterResp),
        };
        state.jobRoster.employees = state.jobRoster.employees.map(
          (emp) => (emp.id === updatedData.candidate.id && { ...emp, ...updatedData }) || emp
        );
      }
    },
    updateJobRosterEmployeeError: (state) => {
      state.isPostingJobRoster = false;
    },
    updatePlacementCheckIn: (state) => {
      state.isPostingJobRoster = true;
    },
    updatePlacementCheckInProcessed: (state, action) => {
      state.isPostingJobRoster = false;
      const placement = action?.payload?.data?.document;

      const jobOrderId = placement.jobOrder._id;

      state.jobRoster = {
        ...state.jobRoster,
        [jobOrderId]: {
          employees: state.jobRoster?.[jobOrderId].employees.map((e) =>
            e._id === placement._id
              ? { ...e, timecard: { ...e.timecard, checkIn: placement.timecard.checkIn } }
              : e
          ),
        },
      };
    },
    updatePlacementCheckInError: (state) => {
      state.isPostingJobRoster = false;
    },

    postTimecardResolve: (state) => {
      state.isPostingJobRoster = true;
    },
    postTimecardResolveProcessed: (state, action) => {
      state.isPostingJobRoster = false;
      const placementIds = action?.payload?.data?.placements;

      const jobOrders = (
        state.selectedJobType === JOB_CATEGORY.dayJob ? state.jobListRowData : state.shiftGroupList
      ).filter((job) => job.placements.filter((p) => placementIds.includes(p._id)).length > 0);
      jobOrders.forEach((jobOrder) => {
        state.jobRoster = {
          ...state.jobRoster,
          [jobOrder?.id]: {
            employees: state.jobRoster?.[jobOrder?.id].employees.map((e) =>
              placementIds.includes(e._id)
                ? { ...e, timecard: { ...e.timecard, status: 'resolved' } }
                : e
            ),
          },
        };
      });
    },
    postTimecardResolveError: (state) => {
      state.isPostingJobRoster = false;
    },

    postTimecardApprove: (state) => {
      state.isPostingJobRoster = true;
    },
    postTimecardApproveProcessed: (state, action) => {
      state.isPostingJobRoster = false;
      const placementIds = action?.payload?.data?.placements;

      const jobOrders = (
        state.selectedJobType === JOB_CATEGORY.dayJob ? state.jobListRowData : state.shiftGroupList
      ).filter((job) => job.placements.filter((p) => placementIds.includes(p._id)).length > 0);

      jobOrders.forEach((jobOrder) => {
        state.jobRoster = {
          ...state.jobRoster,
          [jobOrder?.id]: {
            employees: state.jobRoster?.[jobOrder?.id].employees.map((e) =>
              placementIds.includes(e._id)
                ? { ...e, timecard: { ...e.timecard, status: 'approved' } }
                : e
            ),
          },
        };
      });
    },
    postTimecardApproveError: (state) => {
      state.isPostingJobRoster = false;
    },

    updateCancelledPlacement: (state) => {
      state.isCancelledPlacementUpdating = true;
    },
    updateCancelledPlacementProcessed: (state, action) => {
      state.isCancelledPlacementUpdating = false;
      const jobRosterResp = action?.payload?.data?.document;
      const jobRosterId = jobRosterResp?.jobOrder?._id;
      const calcAttendance = jobRosterResp?.calcAttendance;
      const updatedRoster = {
        ...state.jobRoster,
        [jobRosterId]: {
          ...state.jobRoster[jobRosterId],
          employees: state.jobRoster[jobRosterId].employees.map((employee) =>
            employee._id === jobRosterResp?._id ? { ...employee, calcAttendance } : employee
          ),
        },
      };
      state.jobRoster = updatedRoster;
    },
    updateCancelledPlacementError: (state) => {
      state.isCancelledPlacementUpdating = false;
    },

    updatePlacement: (state) => {
      state.isUpdateProcessing = true;
    },
    updatePlacementProcessed: (state) => {
      state.isUpdateProcessing = false;
    },

    updatePlacementError: (state) => {
      state.isUpdateProcessing = false;
    },

    cancelPlacement: (state) => {
      state.isCancelPlacementProcessing = true;
    },
    cancelPlacementProcessed: (state, action) => {
      state.isCancelPlacementProcessing = false;
      const cancelPlacementResp = action?.payload?.data?.document;
      const jobOrderId = cancelPlacementResp?.jobOrder?._id;
      state.jobRoster = {
        ...state.jobRoster,
        [jobOrderId]: {
          employees: state.jobRoster?.[jobOrderId].employees.map((e) =>
            e.id === cancelPlacementResp?.candidate?._id && e.state.status === 'active'
              ? { ...e, state: { ...cancelPlacementResp?.state } }
              : e
          ),
        },
      };
    },

    cancelPlacementError: (state) => {
      state.isCancelPlacementProcessing = false;
    },

    getPositionTemplate: (state) => {
      state.isLoadingPt = true;
    },
    getPositionTemplateProcessed: (state, action) => {
      state.isLoadingPt = false;
      state.positionTemplateData = get(['payload', 'data', 'documents'], action);
    },
    getPositionTemplateError: (state) => {
      state.isLoadingPt = false;
    },
    /* eslint-disable no-param-reassign */
    getGroupOrders: (state) => {
      state.isLoadingGroupOrders = true;
    },
    getGroupOrdersProcessed: (state, action) => {
      state.isLoadingGroupOrders = false;
      const { count, documents } = action.payload.data;
      const updatedData = documents.map((jobEntry) => reformatJobEntry(jobEntry));
      state.totalRowCount = count;
      state.groupOrders = updatedData;
    },
    getGroupOrdersError: (state) => {
      state.isLoadingGroupOrders = false;
    },

    getShiftGroupList: (state) => {
      state.isLoadingShiftGroupList = true;
    },
    getShiftGroupListProcessed: (state, action) => {
      state.isLoadingShiftGroupList = false;
      const { count, documents } = action.payload.data;
      const updatedData = documents.map((jobEntry) => reformatJobEntry(jobEntry));
      state.shiftGroupList = updatedData;
      state.shiftGroupListCount = count;
    },
    getShiftGroupListError: (state) => {
      state.isLoadingShiftGroupList = false;
    },

    getShiftGroupCancelledCount: (state) => {
      state.isLoadingShiftGroupCancelledList = true;
    },
    getShiftGroupCancelledCountProcessed: (state, action) => {
      state.isLoadingShiftGroupCancelledList = false;
      state.cancelledShiftCount = action.payload.data?.count;
    },
    getShiftGroupCancelledCountError: (state) => {
      state.isLoadingShiftGroupCancelledList = false;
    },

    putGroupOrders: (state) => {
      state.isProcessingPutGroupOrders = true;
    },
    putGroupOrdersProcessed: (state) => {
      state.isProcessingPutGroupOrders = false;
    },
    putGroupOrdersError: (state, action) => {
      state.isProcessingPutGroupOrders = false;
      const messageData =
        get(['payload', 'GLOBAL', 'messageKey'], action) || 'error.generic.something.went.wrong';
      state.errorPutGroupOrders = messageData;
    },
    clearPutGroupOrdersError: (state) => {
      state.errorPutGroupOrders = null;
    },
    setIsCreateJobsDialogOpen: (state, action) => {
      state.isCreateJobsDialogOpen = action.payload;
    },
  },
});

export const jobListReducer = jobListSlice.reducer;

export const {
  setSelectedJob,
  setSelectedJobType,
  getJobsList,
  getJobsListError,
  getJobsListProcessed,
  getPathSelectedJob,
  getPathSelectedJobError,
  getPathSelectedJobProcessed,
  cancelJob,
  cancelJobError,
  cancelJobProcessed,
  cancelShiftGroup,
  cancelShiftGroupError,
  cancelShiftGroupProcessed,
  updateJob,
  updateJobProcessed,
  updateJobError,
  getCorporationsForJobsList,
  getCorporationsForJobsListProcessed,
  getCorporationsForJobsListError,
  getJobRoster,
  getJobRosterProcessed,
  getJobRosterError,
  approveOrRejectPlacement,
  approveOrRejectPlacementProcessed,
  approveOrRejectPlacementError,
  revokeJobInvitations,
  revokeJobInvitationsProcessed,
  revokeJobInvitationsError,
  updatePlacementCheckIn,
  updatePlacementCheckInProcessed,
  updatePlacementCheckInError,
  postTimecardResolve,
  postTimecardResolveProcessed,
  postTimecardResolveError,
  postTimecardApprove,
  postTimecardApproveProcessed,
  postTimecardApproveError,
  updateJobRosterEmployee,
  updateJobRosterEmployeeProcessed,
  updateJobRosterEmployeeError,
  updateCancelledPlacement,
  updateCancelledPlacementProcessed,
  updateCancelledPlacementError,
  getGroupOrders,
  getGroupOrdersProcessed,
  getGroupOrdersError,
  updatePlacement,
  updatePlacementProcessed,
  updatePlacementError,
  cancelPlacement,
  cancelPlacementProcessed,
  cancelPlacementError,
  getPositionTemplate,
  getPositionTemplateProcessed,
  getPositionTemplateError,
  getShiftGroupList,
  getShiftGroupListProcessed,
  getShiftGroupListError,
  getShiftGroupCancelledCount,
  getShiftGroupCancelledCountProcessed,
  getShiftGroupCancelledCountError,
  putGroupOrders,
  putGroupOrdersProcessed,
  putGroupOrdersError,
  clearPutGroupOrdersError,
  setIsCreateJobsDialogOpen,
} = jobListSlice.actions;
