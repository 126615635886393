import React, { useCallback, useEffect, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { first, get } from 'lodash/fp';
import map from 'lodash/fp/map';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import PostPTRequestSchema from '@careerstart/wae-common/schema/positionTemplates/create.post.req.json';
import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import Drawer from '@mui/material/Drawer';

import { isGreaterThanDate } from '../../../components/Form/formLevelValidations';
import { validateSchema } from '../../../components/Form/validations';
import WorkFlow from '../../../components/WorkFlow';
import selectUser from '../../../store/selectors/appSelector';
import { selectCorporations } from '../../../store/selectors/corporationListSelectors';
import {
  selectIsProcessingPostPT,
  selectPostPTError,
} from '../../../store/selectors/createPositionTemplateSelectors';
import launchDarklyToggles from '../../../utils/launchDarklyToggles';
import { getCorporationsList } from '../../corporations/corporationList';

import {
  PositionTemplateFormFieldDataForAdminAndRecruiter,
  PositionTemplateFormFieldDataForEmployer,
  readOnlyValues,
} from './FormFiedData/FormFieldData';
import { clearPostPTError } from './reducer';

const CreatePositionTemplateDrawer = ({
  isOpen,
  onClose,
  flags,
  certifications,
  postPositionTemplate,
}) => {
  const [createPositionTemplateValues, setCreatePositionTemplateValues] = useState({});
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const employerCorpID = get(['employer', 'corporation'], user);
  const corp = useSelector(selectCorporations);
  const isProcessingPostPT = useSelector(selectIsProcessingPostPT);
  const postPTError = useSelector(selectPostPTError);
  const role = get('role', user);
  const employerCorporationName = get(['name'], first(corp));

  useEffect(() => {
    dispatch(getCorporationsList());
  }, [dispatch]);

  const formFieldDataByType = () => {
    switch (role) {
      case UserRole.ADMIN:
      case UserRole.RECRUITER:
        return PositionTemplateFormFieldDataForAdminAndRecruiter({
          createPositionTemplateValues,
          certifications,
          gpsEnforcement: launchDarklyToggles(flags, 'isGPSEnforcementPageInPTCreateDrawerOn'),
          applications: launchDarklyToggles(flags, 'isJobApplicationSystemEnabled'),
        });
      case UserRole.EMPLOYER:
        return PositionTemplateFormFieldDataForEmployer({
          corporationID: employerCorpID,
          gpsEnforcement: launchDarklyToggles(flags, 'isGPSEnforcementPageInPTCreateDrawerOn'),
          applications: launchDarklyToggles(flags, 'isJobApplicationSystemEnabled'),
        });

      default:
        return [];
    }
  };
  const handleOnClose = useCallback(() => {
    onClose();
    dispatch(clearPostPTError());
  }, [dispatch, onClose]);

  const workFlowValuesUpdated = (values) => {
    setCreatePositionTemplateValues(values);
  };

  const handleSubmitValues = (values) => {
    const industry = get('industry', values);
    const getIndustryValue = () => {
      let result;
      if (industry) {
        Object.keys(industry).forEach((key) => {
          if (industry[key]) {
            result = key;
          }
        });
      }

      return result;
    };
    const submittedCerts = get('certifications', values);
    const submittedSkills = get('skills', values);
    const locations = get('location', values);
    const locationsID = (locationsObj) => {
      const selectedLocations = [];
      map((loc) => selectedLocations.push(loc._id), locationsObj);
      return selectedLocations;
    };
    const maxPunchDistance = get('punchDistance', values);

    const postPositionTemplateDataForAdminAndRecruiter = {
      fields: {
        allowEmployerApproval: !!get('allowEmployerApproval', values),
        corporation: get(['corporation', '_id'], values),
        departments: get(['department'], values).map((dep) => dep._id),
        description: get('positionDescriptionValue', values),
        name: get('positionName', values),
        industry: getIndustryValue(),
        billRateDT: 0,
        billRateOT: 0,
        billRateReg: 0,
        branch: 'Rochester General Staffing',
        certifications: submittedCerts ? submittedCerts.map((cert) => get('_id', cert)) : [],
        gps: {
          maxDistance: parseInt(maxPunchDistance, 10) || 25,
          strategy: get('gpsStrategyValue', values) || 'unenforced',
        },
        locations: locationsID(locations),
        payRateDT: 0,
        payRateOT: 0,
        payRateReg: 0,
        saleRep: 'wae@waeapp.com',
        workersCompCode: 1212,
        shifts: get('shifts', values),
        skills: submittedSkills ? submittedSkills.map((skill) => get('_id', skill)) : [],
      },
    };

    const postPositionTemplateDataForEmployer = {
      fields: {
        allowEmployerApproval: !!get('allowEmployerApproval', values),
        departments: get(['department'], values).map((dep) => dep._id),
        description: get('positionDescriptionValue', values),
        name: get('positionName', values),
        industry: getIndustryValue(),
        billRateDT: 0,
        billRateOT: 0,
        billRateReg: 0,
        branch: 'Rochester General Staffing',
        gps: {
          maxDistance: parseInt(maxPunchDistance, 10) || 25,
          strategy: get('gpsStrategyValue', values) || 'unenforced',
        },
        locations: locationsID(locations),
        payRateDT: 0,
        payRateOT: 0,
        payRateReg: 0,
        saleRep: 'wae@waeapp.com',
        workersCompCode: 1212,
        shifts: get('shifts', values),
        skills: submittedSkills ? submittedSkills.map((skill) => get('_id', skill)) : [],
      },
    };

    const requestBody =
      role === UserRole.EMPLOYER
        ? postPositionTemplateDataForEmployer
        : postPositionTemplateDataForAdminAndRecruiter;

    if (get('FORM_ERROR', validateSchema(requestBody, PostPTRequestSchema))) {
      return false;
    }

    postPositionTemplate(requestBody);
    return true;
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleOnClose}
      PaperProps={{
        sx: {
          maxWidth: '576px',
        },
      }}
    >
      <WorkFlow
        confirmationPageButtonText={LanguageConverter('button.return.positions')}
        formSpec={formFieldDataByType()}
        onClose={handleOnClose}
        readOnlyValues={readOnlyValues(createPositionTemplateValues, employerCorporationName)}
        workFlowValuesUpdatedCallBack={(values) => workFlowValuesUpdated(values)}
        validateForm={(values) => {
          const dateRangeValidate = isGreaterThanDate('startDate', 'endDate', {
            endDate: 'End date must be after the start date',
            startDate: 'End date must be after the start date',
          })(values);

          let industryValidate = {};
          if (values.industry) {
            industryValidate =
              Object.keys(values.industry).filter((key) => values.industry[key] === true).length ===
              0
                ? {
                    industry: 'This field is required',
                  }
                : {};
          }

          let gpsStrategyValidate = {};
          if (values.gpsStrategy) {
            gpsStrategyValidate =
              Object.keys(values.gpsStrategy).filter((key) => values.gpsStrategy[key] === true)
                .length === 0
                ? {
                    gpsStrategy: 'This field is required',
                  }
                : {};
          }

          return {
            ...dateRangeValidate,
            ...industryValidate,
            ...gpsStrategyValidate,
          };
        }}
        attemptToHandleAPICall={(values) => handleSubmitValues(values)}
        isApiCallProcessing={isProcessingPostPT}
        processingMessage="message.generic.processing"
        successMessage="success.position.template.post"
        errorMessage={postPTError}
      />
    </Drawer>
  );
};

CreatePositionTemplateDrawer.propTypes = {
  certifications: PropTypes.arrayOf(PropTypes.shape({})),
  isOpen: PropTypes.bool,
  flags: PropTypes.shape({}),
  onClose: PropTypes.func,
  postPositionTemplate: PropTypes.func,
};

export default withLDConsumer()(CreatePositionTemplateDrawer);
