import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { batch, useDispatch, useSelector } from 'react-redux';

import { JOB_CATEGORY } from '@careerstart/wae-common/src/main/constants/jobInformation';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import BackdropCircularProgress from '../../../components/BackdropCircularProgress';
import WaeButton, { BUTTON_VARIANT } from '../../../components/Button';
import PaginatedDataGrid from '../../../components/DataGrid/PaginatedDataGridV2';
import DetailView from '../../../components/DetailView/DetailView2';
import DateRangePickerFilter from '../../../components/Filters/DateRangePickerFilter';
import Tab from '../../../components/Tab';
import selectUser from '../../../store/selectors/appSelector';
import {
  selectGroupOrders,
  selectIsCancelProcessing,
  selectIsLoading,
  selectIsLoadingGroupOrders,
  selectIsLoadingPt,
  selectIsUpdateProcessing,
  selectPositionTemplateData,
  selectShiftGroupCancelledCount,
  selectShiftGroupList,
  selectTotalRowCount,
} from '../../../store/selectors/jobListSelectors';
import theme, {
  dataGridFiltersHeight,
  listHeaderHeight,
  navigationBarHeight,
} from '../../../theme';
import launchDarklyToggles from '../../../utils/launchDarklyToggles';
import { showSnackbar } from '../../app';
import CreateJobsModal from '../createJobs';

import CreateJobDrawer from './CreateJobDrawer/CreateJobDrawer';
import Overview from './LongTermJobDetailView/Overview';
import ShiftDetails from './LongTermJobDetailView/ShiftDetails';
import ShiftRoster from './LongTermJobDetailView/ShiftRoster';
import CancelJobModal from './CancelJobModal';
import filters from './filters';
import getJobListColumnData from './getJobListColumnData';
import JobTypeTab from './JobTypeTab';
import {
  getCorporationsForJobsList,
  getGroupOrders,
  getPositionTemplate,
  getShiftGroupCancelledCount,
  getShiftGroupList,
  setSelectedJobType,
  updateJob,
} from './reducer';

const RootGrid = styled(Grid)(() => ({
  height: `calc(100vh - ${navigationBarHeight})`,
  position: 'relative',
  top: navigationBarHeight,
  padding: theme.spacing(2, 0),
}));

const LongTermJobsList = ({ flags }) => {
  const dispatch = useDispatch();
  const muiTheme = useTheme();
  const user = useSelector(selectUser);
  const isLoading = useSelector(selectIsLoading);
  const isCancelProcessing = useSelector(selectIsCancelProcessing);
  const totalRowCount = useSelector(selectTotalRowCount);
  const isUpdateProcessing = useSelector(selectIsUpdateProcessing);
  const positionTemplateData = useSelector(selectPositionTemplateData);
  const isLoadingPt = useSelector(selectIsLoadingPt);
  const groupOrders = useSelector(selectGroupOrders);
  const shiftGroupList = useSelector(selectShiftGroupList);
  const shiftGroupCancelledCount = useSelector(selectShiftGroupCancelledCount);
  const isLoadingGroupOrders = useSelector(selectIsLoadingGroupOrders);

  const mediumScreen = useMediaQuery(muiTheme.breakpoints.up('md'));
  const jobListColumnData = useMemo(
    () => getJobListColumnData(user.role, 'LONG_TERM_JOBS'),
    [user]
  );
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(10);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [createJobType, setCreateJobType] = useState(null);
  const additiveFilters = [
    {
      customFilter: {
        field: { start: 'start', end: 'end' },
        operation: { start: 'onOrAfter', end: 'onOrBefore' },
        placeholder: 'Date Range',
        type: DateRangePickerFilter,
      },
    },
  ];

  const [customFilters, setCustomFilters] = useState({
    // initial display joblist is upcoming joblist
    filterModel: {
      items: filters.UPCOMING_JOBS,
    },
  });

  const [selectedShiftGroup, setSelectedShiftGroup] = useState(null);
  const [openCreateJobModal, setOpenCreateJobModal] = React.useState(false);
  const apiPath = 'job-orders/read';
  const longTermToggle = launchDarklyToggles(flags, 'isLongTermJobCreationEnabled');

  const handleDayJobClick = () => {
    if (positionTemplateData.length === 0) {
      dispatch(showSnackbar({ message: 'Please Create Position Template' }));
    } else {
      setOpenCreateJobModal(true);
    }
  };

  const handleLongTermJobClick = () => {
    if (positionTemplateData.length === 0) {
      dispatch(showSnackbar({ message: 'Please Create Position Template' }));
    } else {
      setIsDrawerOpen(true);
      setCreateJobType(JOB_CATEGORY.longTermJob);
    }
  };

  const handleJobUpdate = useCallback(
    (formValues) => {
      dispatch(
        updateJob({
          id: get(['id'], selectedShiftGroup),
          fields: { numOpenings: formValues.numOpenings },
        })
      );
    },
    [dispatch, selectedShiftGroup]
  );

  const onSelectionModelChange = useCallback(
    (job) => {
      setSelectedShiftGroup(job);
      if (job) {
        dispatch(
          getShiftGroupList({
            filters: [{ field: 'shiftGroup', value: get(['id'], job), operation: 'equalsID' }],
            page: 0,
            sortBy: [{ field: 'start', ascending: true }],
          })
        );
        dispatch(
          getShiftGroupCancelledCount({
            filters: [
              { field: 'shiftGroup', value: get(['id'], job), operation: 'equalsID' },
              { field: 'cancelled', value: true, operation: 'equals' },
            ],
            page: 0,
            limit: 1,
          })
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    batch(() => {
      dispatch(setSelectedJobType(JOB_CATEGORY.longTermJob));
      dispatch(getCorporationsForJobsList());
      dispatch(getPositionTemplate());
    });
  }, [dispatch]);

  const jobsListPaginationQuery = React.useCallback(
    (params) => {
      dispatch(getGroupOrders(params));
    },
    [dispatch]
  );

  const tabData = [
    {
      tabLabel: 'Upcoming',
    },
    {
      tabLabel: 'Completed',
    },
  ];

  const detailTabData = (container) => [
    {
      tabContent: (
        <Overview
          container={container}
          initialValues={selectedShiftGroup}
          user={user}
          jobType={JOB_CATEGORY.longTermJob}
          shiftGroupList={shiftGroupList}
          shiftGroupCancelledCount={shiftGroupCancelledCount}
        />
      ),
      tabLabel: 'Overview',
    },
    {
      tabContent: <ShiftDetails shiftGroupList={shiftGroupList} />,
      tabLabel: 'Shift Details',
    },
    {
      tabContent: <ShiftRoster shiftGroupList={shiftGroupList} />,
      tabLabel: 'Roster',
    },
  ];

  const onTabChange = (newVal) => {
    if (newVal === 0) {
      setCustomFilters({
        // upcoming
        filterModel: {
          items: filters.UPCOMING_JOBS,
        },
      });
    }
    if (newVal === 1) {
      setCustomFilters({
        // completed
        filterModel: {
          items: filters.COMPLETED_JOBS,
        },
      });
    }
  };

  return (
    <RootGrid container>
      <Slide
        direction="right"
        unmountOnExit
        in={!(!mediumScreen && !!selectedShiftGroup)}
        timeout={10}
        easing={{ enter: 'step-end', exit: 'step-start' }}
      >
        <Grid
          item
          container
          md
          xs
          sm
          sx={{
            height: `calc(100% - ${listHeaderHeight} - ${dataGridFiltersHeight} )`,
            padding: theme.spacing(0, 0, 1, 0),
            marginRight: !!selectedShiftGroup && 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <JobTypeTab initVal="longTermJobs" isDetailOpen={!!selectedShiftGroup} />
            <Box
              sx={{
                display: 'flex',
                height: 'auto',
                justifyContent: 'space-between',
              }}
            >
              {!isLoadingPt && (
                <WaeButton
                  variant={BUTTON_VARIANT.OUTLINED}
                  text="New Bulk Order"
                  onClick={handleDayJobClick}
                  sx={{ marginLeft: 1 }}
                />
              )}

              {!isLoadingPt && (
                <WaeButton
                  text="New Job Order"
                  onClick={handleLongTermJobClick}
                  disabled={!longTermToggle}
                  sx={{ marginLeft: 1 }}
                />
              )}
            </Box>
          </Box>

          <Grid sx={{ width: '100%', height: `calc(100% - ${listHeaderHeight})` }} container>
            <>
              <PaginatedDataGrid
                additiveFilters={additiveFilters}
                apiPath={apiPath}
                columnData={jobListColumnData}
                customFilter={customFilters}
                FilterLeftComponent={
                  <Tab data={tabData} onChange={onTabChange} background="default" />
                }
                isLoading={isLoading || isLoadingGroupOrders}
                onSelectionModelChange={onSelectionModelChange}
                pageSize={pageSize}
                page={page}
                paginatedData={groupOrders}
                pagination
                paginationQuery={jobsListPaginationQuery}
                setPageSize={setPageSize}
                setPage={setPage}
                setRowCount={setRowCount}
                getRowHeight={() => 'auto'}
                sx={{
                  border: 0,
                  width: '100%',
                  paddingLeft: 1,
                  fontFamily: `${theme.dataGrid.default.fontFamily}`,
                  color: `${theme.dataGrid.default.textColor}`,
                  '& .MuiDataGrid-columnHeaders': {
                    fontFamily: `${theme.dataGrid.default.headerFont}`,
                  },
                }}
                totalRowCount={totalRowCount}
                rowCount={rowCount}
              />
              {!!selectedShiftGroup && (
                <DetailView
                  close={() => setSelectedShiftGroup(null)}
                  tabData={detailTabData}
                  onUpdate={handleJobUpdate}
                />
              )}
            </>
          </Grid>
        </Grid>
      </Slide>
      {!mediumScreen && !!selectedShiftGroup && (
        <DetailView
          close={() => setSelectedShiftGroup(null)}
          tabData={detailTabData}
          onUpdate={handleJobUpdate}
        />
      )}
      <CancelJobModal
        selectedJob={selectedShiftGroup}
        isCancelDialogOpen={isCancelDialogOpen}
        setIsCancelDialogOpen={setIsCancelDialogOpen}
      />

      {(isCancelProcessing || isUpdateProcessing) && <BackdropCircularProgress />}
      <CreateJobDrawer
        anchor="right"
        isOpen={isDrawerOpen}
        createJobType={createJobType}
        onClose={() => setIsDrawerOpen(false)}
      />
      {openCreateJobModal && (
        <CreateJobsModal
          open={openCreateJobModal}
          handleModalClose={() => setOpenCreateJobModal(false)}
        />
      )}
    </RootGrid>
  );
};
LongTermJobsList.propTypes = {
  flags: PropTypes.shape({}),
};

export default withLDConsumer()(LongTermJobsList);
