import React, { memo } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

import theme from '../../theme';

const AlertComponent = memo(({ isOpen, message, severity }) => {
  const intl = useIntl();
  let alertBackgroundColor;
  const componentAlertPaletteBackgroundColor = [
    'components',
    'alert',
    'palette',
    'backgroundColor',
  ];
  switch (severity) {
    case 'error':
      alertBackgroundColor = get([...componentAlertPaletteBackgroundColor, 'errorBgColor'], theme);
      break;
    case 'warning':
      alertBackgroundColor = get(
        [...componentAlertPaletteBackgroundColor, 'warningBgColor'],
        theme
      );
      break;
    case 'success':
      alertBackgroundColor = get(
        [...componentAlertPaletteBackgroundColor, 'successBgColor'],
        theme
      );
      break;
    default:
      alertBackgroundColor = get([...componentAlertPaletteBackgroundColor, 'errorBgColor'], theme);
      break;
  }
  const alertSX = {
    backgroundColor: alertBackgroundColor,
    fontFamily: get(['components', 'alert', 'typography', 'fontFamily'], theme),
    fontSize: get(['components', 'alert', 'typography', 'fontSize'], theme),
    fontColor: get(['components', 'alert', 'typography', 'fontColor'], theme),
    '& 	.MuiAlert-icon': {
      color: get(['components', 'alert', 'typography', 'fontColor'], theme),
    },
    borderRadius: '16px',
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={isOpen}>
        <Alert severity={severity} sx={alertSX}>
          {message ? intl.formatMessage({ id: message }) : undefined}
        </Alert>
      </Collapse>
    </Box>
  );
});

AlertComponent.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  severity: PropTypes.string,
};
export default AlertComponent;
