import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import theme from '../../../theme';

const DefaultNavMenu = ({ closeDrawer, isOpen, url, Icon, label, testId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (location.pathname === `/${url}`) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [location, url]);
  const backgroundColor = active ? theme.components.nav.activeBackgroundColor.default : null;

  const sx = {
    pl: 2,
    margin: theme.spacing(2),
    borderRadius: '9999px',
    color: theme.components.nav.textColor.default,
    backgroundColor,
  };

  const sxForCollapsedButton = {
    pl: 2,
    margin: theme.spacing(2),
    borderRadius: '9999px',
    backgroundColor,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
  };

  const sxForIcon = {
    diplay: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '100%',
    minHeight: '36px',
  };

  if (!isOpen) {
    return (
      <List component="nav" sx={sxForCollapsedButton} data-testid={testId}>
        <ListItemIcon
          onClick={() => {
            navigate(url);
            closeDrawer();
          }}
          sx={sxForIcon}
        >
          <Icon sx={{ color: theme.components.nav.textColor.default }} fontSize="small" />
        </ListItemIcon>
      </List>
    );
  }

  return (
    <List component="nav" disablePadding data-testid={testId}>
      <ListItemButton
        onClick={() => {
          navigate(url);
          closeDrawer();
        }}
        sx={sx}
      >
        <ListItemIcon>
          <Icon sx={{ color: theme.components.nav.textColor.default }} fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </List>
  );
};

DefaultNavMenu.propTypes = {
  closeDrawer: PropTypes.func,
  isOpen: PropTypes.bool,
  label: PropTypes.string,
  url: PropTypes.string,
  Icon: PropTypes.shape({}),
  testId: PropTypes.string,
};

export default DefaultNavMenu;
