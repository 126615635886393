import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import readForJobOrderSchema from '@careerstart/wae-common/schema/placements/read-for-job-order/post.req.json';
import { JOB_CATEGORY } from '@careerstart/wae-common/src/main/constants/jobInformation';
import { Accordion } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { validateSchema } from '../../../../../components/Form/validations';
import selectUser from '../../../../../store/selectors/appSelector';
import theme from '../../../../../theme';
import {
  epochToDateInReadableFormat,
  epochToDayOfWeek,
  epochToTimeInReadableFormat,
} from '../../../../../utils';
import LinearExpandMore from '../../../../corporations/corporationList/detail/icons/LinearExpandMore';
import RosterForm from '../../detail/RosterForm'; // FIXME: This hierarchy seems wrong? This seems like its being used as a child but its nested like a sibling?
import FillRatioCell from '../../FillRatioCell';
import { getJobRoster, updateJob } from '../../reducer';

const styleForAccordion = {
  backgroundColor: theme.components.detailView.palette.sectionBackgroundColor,
  borderRadius: '16px',
  justifyContent: 'space-between',
  margin: '5px 0',
  boxShadow: 0,
  width: '100%',
};
const styleForIconButtons = {
  color: 'blue',
  fontSize: 30,
  cursor: 'pointer',
};

const styleForShortDate = {
  fontSize: theme.components.detailView.typography.semiBoldText.fontSize,
  margin: '3px',
  color: theme.components.detailView.palette.sectionTextColor,
  fontFamily: theme.components.detailView.typography.semiBoldText.fontFamily,
};

const styleForShift = {
  fontFamily: theme.components.detailView.typography.detailText.fontFamily,
  fontSize: theme.components.detailView.typography.detailText.fontSize,
  color: 'white',
};

const ShiftRosterAccordion = ({ selectedShift }) => {
  const { start, end, id, fillRatio } = selectedShift;
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  useEffect(() => {
    const query = { jobOrder: id, limit: 250 };
    if (Object.keys(validateSchema(query, readForJobOrderSchema)).length === 0) {
      dispatch(getJobRoster(query));
    }
  }, [dispatch, id]);

  const handleJobUpdate = useCallback(
    (formData) => {
      dispatch(
        updateJob({
          id,
          fields: { numOpenings: formData.numOpenings },
        })
      );
    },
    [dispatch, id]
  );

  return (
    <Accordion sx={styleForAccordion} square>
      <AccordionSummary
        expandIcon={<LinearExpandMore sx={styleForIconButtons} />}
        aria-controls="panel1a-content"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingRight: '5px',
          }}
        >
          <Box>
            <Typography sx={styleForShortDate} gutterBottom>
              {epochToDateInReadableFormat(start)}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '3px' }}>
              <Typography sx={styleForShift}>
                {epochToDayOfWeek(start)}. {epochToTimeInReadableFormat(start)}-{' '}
                {epochToTimeInReadableFormat(end)}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ color: 'white', width: '40%' }}>
            <FillRatioCell variant="determinate" id={id} value={fillRatio} />
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ margin: 0, padding: 0 }}>
        <RosterForm
          onUpdate={handleJobUpdate}
          initialValues={selectedShift}
          user={user}
          jobType={JOB_CATEGORY.shift}
        />
      </AccordionDetails>
    </Accordion>
  );
};

ShiftRosterAccordion.propTypes = {
  selectedShift: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
    id: PropTypes.string,
    status: PropTypes.string,
    fillRatio: PropTypes.number,
  }),
};

export default ShiftRosterAccordion;
