import React, { memo, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { selectFilters } from '../../store/selectors/reportingSelectors';
import { getMomentInEpoch } from '../Candidate/utils';

import EmployeesMetrics from './employeesMetrics';
import JobOrderMetrics from './jobOrderMetrics';
import {
  getDnas,
  getDnrs,
  getDoNotReturnBreakdown,
  getEmployeeRetention,
  getEmployeesHired,
  getFillRatio,
  getJobOrderRevenue,
  getJobsCompleted,
  getJobsEntered,
  getOverallAttendance,
  getTimeBasedJobOrderNumbers,
  getUserCountByRole,
} from './reducer';
import RevenueMetrics from './revenueMetrics';
import UserMetrics from './userMetrics';

const Metrics = memo(() => {
  const dispatch = useDispatch();
  const filters = useSelector(selectFilters);
  const { start, end, corporation } = filters;

  useEffect(() => {
    batch(() => {
      dispatch(
        getUserCountByRole({
          filters: [
            start && {
              field: 'metadata.created',
              operation: 'greaterThan',
              value: start,
            },
            end && {
              field: 'metadata.created',
              operation: 'lessThan',
              value: end,
            },
            corporation && {
              field: 'corporation',
              operation: 'equalsID',
              value: corporation,
            },
          ].filter((i) => i && i),
        })
      );
      dispatch(
        getJobsEntered({
          limit: 1,
          page: 0,
          filters: [
            start && {
              field: 'metadata.created',
              operation: 'greaterThan',
              value: start,
            },
            end && {
              field: 'metadata.created',
              operation: 'lessThan',
              value: end,
            },
            corporation && {
              field: 'corporation',
              operation: 'equalsID',
              value: corporation,
            },
          ].filter((i) => i && i),
        })
      );
      dispatch(
        getJobsCompleted({
          limit: 1,
          page: 0,
          filters: [
            {
              field: 'cancelled',
              operation: 'equals',
              value: false,
            },
            {
              field: 'end',
              operation: 'lessThan',
              value: getMomentInEpoch(),
            },
            start && {
              field: 'metadata.created',
              operation: 'greaterThan',
              value: start,
            },
            end && {
              field: 'metadata.created',
              operation: 'lessThan',
              value: end,
            },
            corporation && {
              field: 'corporation',
              operation: 'equalsID',
              value: corporation,
            },
          ].filter((i) => i && i),
        })
      );
      dispatch(
        getFillRatio({
          filters: [
            start && {
              field: 'start',
              operation: 'onOrAfter',
              value: start,
            },
            end && {
              field: 'start',
              operation: 'onOrBefore',
              value: end,
            },
            corporation && {
              field: 'corporation',
              operation: 'equalsID',
              value: corporation,
            },
          ].filter((i) => i && i),
        })
      );
      dispatch(
        getTimeBasedJobOrderNumbers({
          filters: [
            start && {
              field: 'metadata.created',
              operation: 'onOrAfter',
              value: start,
            },
            end && {
              field: 'metadata.created',
              operation: 'onOrBefore',
              value: end,
            },
            corporation && {
              field: 'corporation',
              operation: 'equalsID',
              value: corporation,
            },
          ].filter((i) => i && i),
        })
      );

      dispatch(
        getJobOrderRevenue({
          filters: [
            start && {
              field: 'start',
              operation: 'onOrAfter',
              value: start,
            },
            end && {
              field: 'start',
              operation: 'onOrBefore',
              value: end,
            },
            corporation && {
              field: 'corporation',
              operation: 'equalsID',
              value: corporation,
            },
          ].filter((i) => i && i),
        })
      );
      dispatch(
        getEmployeesHired({
          filters: [
            start && {
              field: 'metadata.created',
              operation: 'onOrAfter',
              value: start,
            },
            end && {
              field: 'metadata.created',
              operation: 'onOrBefore',
              value: end,
            },
            corporation && {
              field: 'jobOrder.corporation',
              operation: 'equalsID',
              value: corporation,
            },
            // TODO: Commented code below is in documentation but no filters in UI
            // role && {
            //   field: 'jobOrder.corporation',
            //   operation: 'equalsID',
            //   value: role,
            // },
          ].filter((i) => i && i),
        })
      );
      dispatch(
        getDnrs({
          filters: [
            start && {
              field: 'DNR.date',
              operation: 'onOrAfter',
              value: start,
            },
            end && {
              field: 'DNR.date',
              operation: 'onOrBefore',
              value: end,
            },
            corporation && {
              field: '_id',
              operation: 'equalsID',
              value: corporation,
            },
          ].filter((i) => i && i),
        })
      );
      dispatch(
        getDnas({
          filters: [
            start && {
              field: 'candidate.DNA.time',
              operation: 'onOrAfter',
              value: start,
            },
            end && {
              field: 'candidate.DNA.time',
              operation: 'onOrBefore',
              value: end,
            },
            // TODO: Commented code below is in documentation but no filters in UI
            // corporation && {
            //   field: 'candidate.DNA',
            //   operation: 'exists',
            //   value: true,
            // },
          ].filter((i) => i && i),
        })
      );
      dispatch(
        getOverallAttendance({
          filters: [
            start && {
              field: 'jobOrder.start',
              operation: 'onOrAfter',
              value: start,
            },
            end && {
              field: 'jobOrder.start',
              operation: 'onOrBefore',
              value: end,
            },
            corporation && {
              field: 'jobOrder.corporation',
              operation: 'equalsID',
              value: corporation,
            },
          ].filter((i) => i && i),
        })
      );
      dispatch(
        getDoNotReturnBreakdown({
          filters: [
            start && {
              field: 'DNR.timestamp',
              operation: 'onOrAfter',
              value: start,
            },
            end && {
              field: 'DNR.timestamp',
              operation: 'onOrBefore',
              value: end,
            },
            corporation && {
              field: '_id',
              operation: 'equalsID',
              value: corporation,
            },
          ].filter((i) => i && i),
        })
      );
      dispatch(
        getEmployeeRetention({
          filters: [
            start && {
              field: 'start',
              operation: 'onOrAfter',
              value: start,
            },
            end && {
              field: 'start',
              operation: 'onOrBefore',
              value: end,
            },
            corporation && {
              field: 'corporation',
              operation: 'equalsID',
              value: corporation,
            },
          ].filter((i) => i && i),
        })
      );
    });
  }, [dispatch, start, end, corporation]);

  return (
    <>
      <UserMetrics />
      <JobOrderMetrics />
      <RevenueMetrics />
      <EmployeesMetrics />
    </>
  );
});

export default Metrics;
