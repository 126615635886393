import * as React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';

import theme from '../../theme';
import Button, { BUTTON_VARIANT } from '../Button';
import { handleCSVConversion } from '../ExportView/Helpers';

const generateRows = (data, corporation) => {
  const rows = [];

  data.map((employee) => {
    const row = [];
    const favorite = corporation.favorite.find((f) => f.waeID === employee.id) ? 'Favorite' : '-';
    const attendance = employee.attendance ? employee.attendance : 'Missing attendance data';
    const dnr = corporation.DNR.find((d) => d.waeID === employee.id) ? 'DNR' : '-';
    const note = employee.employerNote || '-';

    row.push(employee.name);
    row.push(attendance);
    row.push(favorite);
    row.push(dnr);
    row.push(note);

    rows.push(row);
    return employee;
  });

  return rows;
};

const ExportRosterToCSV = ({ roster, corporation }) => {
  const handleClick = () => {
    const generatedRows = generateRows(roster, corporation);
    const headerRow = ['Name', 'Attendance', 'Favorite', 'DNR', 'Employer note'];

    const rows = [headerRow, ...generatedRows];
    const fileName = 'Job Roster';
    handleCSVConversion({ rows, fileName });
  };

  return (
    <Button
      text={LanguageConverter('button.export.roster')}
      onClick={handleClick}
      variant={BUTTON_VARIANT.OUTLINED}
      sx={{ padding: theme.spacing(0.67, 2) }}
    />
  );
};

ExportRosterToCSV.propTypes = {
  corporation: PropTypes.shape({}),
  roster: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ExportRosterToCSV;
