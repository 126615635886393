import React, { useEffect, useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { SearchSharp } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, Grid, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import asyncService from '../../../../../../datahub/asyncService';
import { WaeChip } from '../../../../../components/Chip/Chip';
import selectUser from '../../../../../store/selectors/appSelector';
import theme from '../../../../../theme/theme';

const SEARCHBAR_BACKGROUND = {
  DARK: 'dark',
  DEFAULT: 'default',
  LIGHT: 'light',
};

const SearchBarWithListComponent = ({
  background,
  disabled,
  handleDeselectionClick,
  handleSelectionClick,
  onSubmitApiCallData,
  optionDisplayField,
  placeholder,
  preDefinedOptionText,
  preDefinedOptions,
  searchBarSelectionLength,
  selectedTerms,
  sx,
}) => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const token = get('token', user);
  const refreshToken = get('refreshToken', user);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(open && options.length === 0);
  const [searchTerm, setSearchTerm] = useState('');

  let searchBackgroundColor;
  let textFieldBackground;
  let textFieldPrimaryLabel;
  let textFieldFocusedBorder;
  let textFieldBackgroundHover;
  let textFieldErrorBorder;
  let iconColor;
  let iconSize;
  let preDefinedOptionsTextFontFamily;
  let preDefinedOptionsTextFontSize;
  let preDefinedOptionsTextFontColor;

  switch (background) {
    case SEARCHBAR_BACKGROUND.DARK:
      searchBackgroundColor = theme.searchBox.bgColor.darkBkColor;
      textFieldBackground = theme.searchBox.textBox.darkBkColor;
      textFieldBackgroundHover = theme.palette.secondary.light;
      textFieldFocusedBorder = theme.textField.borderColor.focused;
      textFieldErrorBorder = theme.textField.borderColor.error;
      textFieldPrimaryLabel = theme.textField.inputLabel.primary.light;
      iconColor = theme.textField.inputLabel.primary.light;
      iconSize = theme.chip.default.iconSize;
      preDefinedOptionsTextFontFamily = theme.searchBox.font.preDefinedTextFontFamily;
      preDefinedOptionsTextFontSize = theme.searchBox.font.preDefinedTextFontSize;
      preDefinedOptionsTextFontColor = theme.searchBox.font.preDefinedTextFontColor;
      break;
    case SEARCHBAR_BACKGROUND.LIGHT:
      searchBackgroundColor = theme.searchBox.bgColor.lightBkColor;
      textFieldBackground = theme.textField.inputLabel.primary.light;
      textFieldBackgroundHover = theme.textField.background.light;
      textFieldFocusedBorder = theme.textField.borderColor.focused;
      textFieldErrorBorder = theme.textField.borderColor.error;
      textFieldPrimaryLabel = theme.textField.inputLabel.primary.focused;
      iconColor = theme.textField.inputLabel.primary.focused;
      iconSize = theme.chip.default.iconSize;
      preDefinedOptionsTextFontFamily = theme.searchBox.font.preDefinedTextFontFamily;
      preDefinedOptionsTextFontSize = theme.searchBox.font.preDefinedTextFontSize;
      preDefinedOptionsTextFontColor = theme.searchBox.font.preDefinedTextFontColor;
      break;
    default:
      searchBackgroundColor = theme.searchBox.bgColor.lightBkColor;
      textFieldBackground = theme.textfield.inputLabel.primary.light;
      textFieldBackgroundHover = theme.textField.background.light;
      textFieldFocusedBorder = theme.textField.borderColor.focused;
      textFieldErrorBorder = theme.textField.borderColor.error;
      textFieldPrimaryLabel = theme.textField.inputLabel.primary.focused;
      iconColor = theme.textField.inputLabel.primary.focused;
      iconSize = theme.chip.default.iconSize;
      preDefinedOptionsTextFontFamily = theme.searchBox.font.preDefinedTextFontFamily;
      preDefinedOptionsTextFontSize = theme.searchBox.font.preDefinedTextFontSize;
      preDefinedOptionsTextFontColor = theme.searchBox.font.preDefinedTextFontColor;
      break;
  }

  const styleForTextField = {
    width: '448px',
    '& .MuiOutlinedInput-root': {
      height: '40px',
      background: textFieldBackground,
      borderRadius: '40px',
      padding: theme.spacing(0, 1),
      fontFamily: 'Barlow',
      '&:hover': {
        background: textFieldBackgroundHover,
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      border: `2px solid ${textFieldFocusedBorder}`,
      background: textFieldBackground,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      border: `2px solid ${textFieldErrorBorder}`,
    },
    '& .MuiInputLabel-root': {
      top: '-8px',
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1, 7, 1, 0),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .MuiFormLabel-asterisk.Mui-error': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputBase-root': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputBase-root.Mui-focused': {
      fontWeight: 500,
    },
    paddingBottom: '16px',
    ...sx,
  };

  const styleForGrid = {
    backgroundColor: searchBackgroundColor,
    borderRadius: '16px',
  };

  const styleForIcon = {
    color: iconColor,
  };
  const searchBoxFont = ['searchBox', 'font'];
  const sectionHeaderStyle = {
    fontColor: get([...searchBoxFont, 'sectionHeaderFontColor'], theme),
    fontSize: get([...searchBoxFont, 'sectionHeaderFontSize'], theme),
    fontFamily: get([...searchBoxFont, 'sectionHeaderFontFamily'], theme),
    margin: theme.spacing(0, 0, 5, 0),
  };

  const styleForDropDownPaper = {
    padding: theme.spacing(2),
    backgroundColor: searchBackgroundColor,
  };

  const styleForPreDefinedOptions = {
    justifyContent: 'flex-start',

    display: 'flex',

    flexDirection: 'row',

    paddingBottom: '16px',
  };

  const styleForPreDefinedOptionsText = {
    fontFamily: preDefinedOptionsTextFontFamily,
    fontSize: preDefinedOptionsTextFontSize,
    fontColor: preDefinedOptionsTextFontColor,
    margin: theme.spacing(5, 0, 2.5, 0),
  };

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      const requestBody = {
        filters: [
          { operation: 'icontains', field: 'name', value: searchTerm },
          { operation: 'equals', field: 'role', value: 'candidate' },
        ].filter((i) => i),
      };

      asyncService({
        ...onSubmitApiCallData,
        data: requestBody,
        onSuccess: (d) => {
          const apiResultDocuments = get(['data', 'documents'], d);
          setOptions(apiResultDocuments);
          setLoading(false);
        },
        onError: () => {
          setOptions([]);
          setLoading(false);
        },
        dispatch,
        token,
        refreshToken,
      });
    }, 750);
    return () => clearTimeout(delayedSearch);
  }, [searchTerm, dispatch, token, loading, onSubmitApiCallData, refreshToken]);

  return (
    <Grid container direction="column" key="searchbarWithList">
      <Grid sx={styleForGrid} item>
        <Autocomplete
          multiple
          disabled={disabled}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          loading={loading}
          options={options || []}
          getOptionLabel={(option) => option[optionDisplayField] || ''}
          PaperComponent={({ children }) => <Paper sx={styleForDropDownPaper}>{children}</Paper>}
          renderOption={(props, option) => (
            <li key={option._id}>
              <WaeChip
                sx={{ marginBottom: '2px' }}
                background={background}
                key={option._id}
                label={
                  <Box>
                    {option[optionDisplayField]}
                    <span> </span>
                    <AddIcon fontSize={iconSize} />
                  </Box>
                }
                onClick={() => handleSelectionClick(option)}
              />
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={styleForTextField}
              placeholder={placeholder}
              type="text"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchSharp sx={styleForIcon} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <Box>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </Box>
                ),
              }}
              onChange={(e) => {
                setOptions([]);
                setSearchTerm(e.target.value);
              }}
            />
          )}
        />
        <Typography
          sx={sectionHeaderStyle}
        >{`Selected Employees (${searchBarSelectionLength})`}</Typography>
        <Grid sx={styleForPreDefinedOptions} container item spacing={1} aria-label="options">
          {selectedTerms.map((term) => (
            <WaeChip
              sx={{ marginBottom: '5px', marginLeft: '10px' }}
              background={background}
              key={term._id}
              label={
                <Box>
                  {term[optionDisplayField]}
                  <span> </span>
                  <ClearIcon fontSize={iconSize} />
                </Box>
              }
              onClick={() => handleDeselectionClick(term)}
            />
          ))}
        </Grid>

        {preDefinedOptionText && (
          <Typography sx={styleForPreDefinedOptionsText}>{preDefinedOptionText}</Typography>
        )}
        {preDefinedOptions && preDefinedOptions.length > 0 && (
          <Grid sx={styleForPreDefinedOptions} container item spacing={0.5} aria-label="options">
            {preDefinedOptions.map((e) => (
              <Grid key={e._id} container item xs="auto">
                <WaeChip
                  background={background}
                  key={e._id}
                  label={e[optionDisplayField]}
                  onClick={() => handleSelectionClick(e)}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

SearchBarWithListComponent.propTypes = {
  background: PropTypes.string,
  data: PropTypes.shape({}),
  disabled: PropTypes.bool,
  dropDownOptions: PropTypes.arrayOf(PropTypes.shape([])),
  handleDeselectionClick: PropTypes.func,
  handleSelectionClick: PropTypes.func,
  onSubmitApiCallData: PropTypes.shape({}),
  optionDisplayField: PropTypes.string,
  placeholder: PropTypes.string,
  preDefinedOptions: PropTypes.arrayOf(PropTypes.shape({})),
  preDefinedOptionText: PropTypes.string,
  searchTerm: PropTypes.string,
  searchBarSelectionLength: PropTypes.number,
  selectedTerms: PropTypes.arrayOf(PropTypes.shape({})),
  sx: PropTypes.shape({}),
};

export default SearchBarWithListComponent;
