import * as React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import CircleAlert from '../../assets/icons/CircleAlert.svg';
import { isToday, weekDays } from '../../utils/timeConverter';

import getActiveTimeCardStyles from './styles';
import isValidDisputeTimeRange from './utils';

const TimeCard = ({
  corporation,
  date,
  startTime,
  endTime,
  totalEarnings,
  mode,
  name,
  handleOnDispute,
  punches = [],
  totalHours,
  isCompleted,
  status,
}) => {
  const {
    sxForShiftDate,
    sxForShiftTimeAndEarnings,
    sxForPositionName,
    sxForCompanyName,
    sxForPunches,
    sxForCard,
    sxForDisputeButtonText,
    sxForNoPunchMessage,
    sxForDisputeButton,
    sxForDisputeWarningCard,
    sxForDisputeWarningText,
    sxForDisputeWarningStatusText,
  } = getActiveTimeCardStyles(mode);

  const PunchesTypography = ({ text }) => <Typography sx={sxForPunches}>{text}</Typography>;
  PunchesTypography.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };
  const d = new Date(date);
  const dayName = weekDays[d.getDay()];

  return (
    <Card sx={sxForCard}>
      <CardContent>
        {status === 'disputed' && (
          <Card sx={{ borderRadius: '8px', marginBottom: '16px', ...sxForDisputeWarningCard }}>
            <Grid container alignItems="center" sx={{ height: '48px' }}>
              <Box
                component="img"
                sx={{
                  height: '20px',
                  width: '20px',
                  margin: '8px',
                }}
                alt="CircleAlert"
                src={CircleAlert}
              />
              <Typography sx={{ paddingRight: '8px', ...sxForDisputeWarningStatusText }}>
                Pending Dispute
              </Typography>
              <Typography sx={sxForDisputeWarningText}>
                A recruiter will contact you about this dispute.
              </Typography>
            </Grid>
          </Card>
        )}
        <Grid container paddingBottom="8px" columnSpacing={4}>
          <Grid item xs={12} sm>
            <Typography sx={sxForShiftDate}>
              {isToday(d)
                ? `Today ${startTime}-${endTime}`
                : `${dayName}, ${date}, ${startTime}-${endTime}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Typography sx={sxForShiftTimeAndEarnings}>{`Shift Earnings: $ ${totalEarnings.toFixed(
              2
            )}`}</Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Typography sx={sxForShiftTimeAndEarnings}>{`Shift Hours: ${totalHours.toFixed(
              2
            )}`}</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} sm="auto" paddingBottom="8px">
            {mode === 'missedPunch' && (
              <Typography sx={sxForNoPunchMessage}>
                No time has been logged for this shift.
              </Typography>
            )}
            <Typography sx={sxForPositionName}>{name}</Typography>
            <Typography sx={sxForCompanyName}>{corporation}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {punches.map((item) => (
              <Grid key={item.key} container paddingBottom="8px">
                <Grid item xs={4.5}>
                  <PunchesTypography text={get(['in', 'name'], item)} />
                  <PunchesTypography text={get(['out', 'name'], item)} />
                </Grid>
                <Grid item xs={4.5}>
                  <PunchesTypography text={get(['in', 'time'], item)} />
                  <PunchesTypography text={get(['out', 'time'], item)} />
                </Grid>
                <Grid item container justifyContent="flex-end" xs={3}>
                  <PunchesTypography text={get(['hours'], item)} />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        {(status === 'approved' || status === 'pending') &&
          isCompleted &&
          isValidDisputeTimeRange({ date, endTime, startTime }) && (
            <Grid container paddingTop="8px">
              <Button variant="contained" sx={sxForDisputeButton} onClick={handleOnDispute}>
                <Typography sx={sxForDisputeButtonText}>
                  {LanguageConverter('buttonText.disputeTime')}
                </Typography>
              </Button>
            </Grid>
          )}
      </CardContent>
    </Card>
  );
};

TimeCard.propTypes = {
  corporation: PropTypes.string,
  date: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  totalEarnings: PropTypes.number,
  mode: PropTypes.string,
  name: PropTypes.string,
  handleOnDispute: PropTypes.func,
  punches: PropTypes.arrayOf(PropTypes.shape({})),
  totalHours: PropTypes.number,
  isCompleted: PropTypes.bool,
  status: PropTypes.string,
};

export default TimeCard;
