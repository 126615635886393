import React from 'react';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Tooltip } from '@mui/material';

import ConflictCirclePill from '../../../../assets/icons/ConflictCirclePill.svg';
import theme from '../../../../theme';

const PlacementsHasConflictIcon = () => {
  const sxForWarningTooltip = {
    borderRadius: '4px',
    bgcolor: theme?.candidate?.components?.shiftGroupCard?.timeConflictWarningTooltip?.bgColor,
  };
  return (
    <Tooltip
      placement="right-start"
      componentsProps={{
        tooltip: {
          sx: sxForWarningTooltip,
        },
      }}
      title={LanguageConverter('warning.candidate.placement.timeConflict')}
    >
      <Box
        component="img"
        sx={{
          height: 24,
          width: 24,
          marginLeft: 1,
        }}
        alt="ConflictCirclePill"
        src={ConflictCirclePill}
      />
    </Tooltip>
  );
};

export default PlacementsHasConflictIcon;
