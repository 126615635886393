import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { JOB_CATEGORY } from '@careerstart/wae-common/src/main/constants/jobInformation';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import theme from '../../theme';
import { approveOrRejectPlacement } from '../../views/jobs/jobList/reducer';
import WaeButton, { BUTTON_VARIANT } from '../Button';

const styleForCandidateName = {
  color: theme.components.detailView.typography.locationText.fontColor,
  fontFamily: theme.components.detailView.typography.locationText.fontFamily,
};

const ApplicantsTab = ({ jobApplicants, jobType, full }) => {
  const styleForApplicantCard = {
    borderRadius: '16px',
    padding: theme.spacing(1, 0, 1, 0),
    justifyContent: 'space-between',
    margin: 0,
    boxShadow: 0,
    width: '100%',
    height: '100px',
    marginTop: '10px',
    '&:hover, &.Mui-hovered': {
      backgroundColor:
        jobType === JOB_CATEGORY.dayJob
          ? `${theme.palette.background.black}`
          : `${theme.palette.background.dark}`,
    },
  };

  const dispatch = useDispatch();
  const handleApproveClick = (id) => {
    dispatch(
      approveOrRejectPlacement({
        fields: {
          placement: id,
          approved: true,
        },
      })
    );
  };

  const handleDenyClick = (id) => {
    dispatch(
      approveOrRejectPlacement({
        fields: {
          placement: id,
          approved: false,
        },
      })
    );
  };

  return (
    <Box>
      {jobApplicants &&
        jobApplicants.length > 0 &&
        jobApplicants.map((jobApplicant) => {
          const placementId = get('_id', jobApplicant);
          return (
            <ListItem key={jobApplicant.id}>
              <Box sx={styleForApplicantCard} data-testid={`application-card-${jobApplicant.id}`}>
                <Box sx={{ padding: theme.spacing(1.5, 2) }}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography sx={styleForCandidateName}>{get('name', jobApplicant)}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      padding: theme.spacing(1, 0, 0, 0),
                    }}
                  >
                    <Box>
                      <WaeButton
                        disabled={full}
                        text={LanguageConverter('buttonText.approve')}
                        size="small"
                        onClick={() => handleApproveClick(placementId)}
                      />
                    </Box>
                    <Box sx={{ padding: theme.spacing(0, 1, 0, 2) }}>
                      <WaeButton
                        text={LanguageConverter('buttonText.deny')}
                        variant={BUTTON_VARIANT.OUTLINED}
                        sx={{ width: '87.14px' }}
                        onClick={() => handleDenyClick(placementId)}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </ListItem>
          );
        })}
    </Box>
  );
};

ApplicantsTab.propTypes = {
  full: PropTypes.bool,
  jobApplicants: PropTypes.arrayOf(PropTypes.shape({})),
  jobType: PropTypes.string,
};

export default ApplicantsTab;
