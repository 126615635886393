import { get } from 'lodash/fp';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';

import FormSelectPillPicker from '../../../../../components/Form/FormSelectPillPicker';
import FormShiftSelector from '../../../../../components/Form/FormShiftSelector/FormShiftSelector';
import FormSwitch from '../../../../../components/Form/FormSwitch';
import FormTextField from '../../../../../components/Form/FormTextField';
import FormTypographyBox from '../../../../../components/Form/FormTypographyBox';
import {
  composeValidators,
  isGreaterThan,
  isGreaterThanOrEqual,
  isInteger,
  isShiftSelectorEmpty,
} from '../../../../../components/Form/validations';
import SearchBox, { SEARCHBAR_BACKGROUND } from '../../../../../components/SearchBar';
import SearchBoxV2 from '../../../../../components/SearchBarV2';
import { PRIMARY_FONT } from '../../../../../theme/fontConstants';
import theme from '../../../../../theme/theme';
import JobRangeForForm from '../CustomComponents/JobRangeForForm';

const componentWorkFlowFont = ['components', 'workflow', 'typography'];
const workFlowHeaderStyling = {
  fontSize: get([...componentWorkFlowFont, 'defaultTitleFontSize'], theme),
  fontFamily: get([...componentWorkFlowFont, 'boldFont'], theme),
  color: get([...componentWorkFlowFont, 'defaultLastPageFontColor', 'default'], theme),
  lineHeight: get([...componentWorkFlowFont, 'titleLineHeight'], theme),
  width: '448px',
};
const workFlowSubHeaderStyling = {
  fontSize: '16px',
  fontFamily: PRIMARY_FONT[400],
  color: '#333357',
  margin: theme.spacing(-1, 0, 2, 0),
};

const FormHeaderStyle = {
  fontSize: '14px',
  fontFamily: PRIMARY_FONT[400],
  color: '#000033',
};

const FormValueStyle = {
  fontSize: '16px',
  fontFamily: PRIMARY_FONT[400],
  color: '#000033',
  marginTop: '-20px',
};

const HeaderStyling = {
  fontSize: '18px',
  fontFamily: PRIMARY_FONT[700],
  color: '#000033',
};

export const doesWorkDayHaveAnyTruthy = (value) =>
  Object.values(value).some((val) => val === true) ? undefined : 'One Workday must be selected.';

export const positionTemplateSection = (createJobValues, employerCorporationName) => [
  {
    ComponentType: FormTypographyBox,
    key: 'positionTemplateHeader',
    name: 'positionTemplateHeader',
    sx: workFlowHeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'positionTemplateSubHeader',
    name: 'positionTemplateSubHeader',
    sx: workFlowSubHeaderStyling,
  },
  {
    ComponentType: SearchBox,
    background: SEARCHBAR_BACKGROUND.LIGHT,
    preDefinedOptions: [],
    data: {
      filters: employerCorporationName
        ? [
            {
              field: 'corporation.name',
              value: employerCorporationName,
              operation: 'contains',
            },
            {
              field: 'approved',
              value: true,
              operation: 'equals',
            },
          ]
        : [
            {
              field: 'corporation.name',
              value: get(['corporation', 'name'], createJobValues),
              operation: 'contains',
            },
            {
              field: 'approved',
              value: true,
              operation: 'equals',
            },
          ],
    },
    name: 'positionTemplate',
    multiSelect: false,
    required: true,
    variant: 'standard',
    optionLabel: 'name',
    placeholder: 'Enter Position Template',
    fullWidth: true,
    onSubmitApiCallData: {
      httpMethod: 'POST',
      route: 'position-templates/read',
    },
    optionDisplayField: 'name',
  },
];

export const locationSection = (
  createJobValues,
  predefinedLocationOptions,
  employerCorporationId
) => [
  {
    ComponentType: FormTypographyBox,
    key: 'locationSubHeader',
    name: 'locationSubHeader',
    sx: workFlowSubHeaderStyling,
  },

  {
    ComponentType: SearchBoxV2,
    background: SEARCHBAR_BACKGROUND.LIGHT,
    dropDownOptions: predefinedLocationOptions,
    data: null,
    name: 'location',
    multiSelect: false,
    required: true,
    variant: 'standard',
    optionLabel: 'name',
    placeholder: 'Search Locations',
    selectedSectionTitle: 'Selected Location',
    fullWidth: true,
    optionDisplayField: 'description',
    label: 'Location',
  },
  {
    ComponentType: SearchBoxV2,
    background: SEARCHBAR_BACKGROUND.LIGHT,
    data: {
      corporation: get(['corporation', '_id'], createJobValues)
        ? `${get(['corporation', '_id'], createJobValues)}`
        : `${employerCorporationId}`,
      filters: [
        {
          field: '_id',
          operation: 'idContainedIn',
          value: (get(['positionTemplate', 'departments'], createJobValues) || []).map((dept) =>
            get(['_id'], dept)
          ),
        },
      ],
    },
    name: 'department',
    required: true,
    variant: 'standard',
    optionLabel: 'name',
    placeholder: 'Search Departments',
    fullWidth: true,
    onSubmitApiCallData: {
      httpMethod: 'POST',
      route: 'corporations/departments/read',
    },
    optionDisplayField: 'name',
    key: '_id',
    multiSelect: false,
    label: 'Department',
    selectedSectionTitle: 'Selected Depeartment',
  },
];

export const shiftSection = (createJobValues) => [
  {
    ComponentType: FormTypographyBox,
    key: 'shiftHeader',
    name: 'shiftHeader',
    sx: workFlowHeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'shiftSubHeader',
    name: 'shiftSubHeader',
    sx: workFlowSubHeaderStyling,
  },
  {
    ComponentType: FormShiftSelector,
    label: 'Shift',
    name: 'shift',
    shiftTitle: 'Assigned Shifts',
    initialShifts: get(['positionTemplate', 'shifts'], createJobValues),
    validate: isShiftSelectorEmpty,
    required: true,
  },
];

export const dateSection = (createJobValues) => [
  {
    ComponentType: FormTypographyBox,
    key: 'dateHeader',
    name: 'dateHeader',
    sx: workFlowHeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'calendarDate',
    name: 'calendarDate',
    sx: HeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'calendarDateDescription',
    name: 'calendarDateDescription',
    sx: workFlowSubHeaderStyling,
  },
  {
    ComponentType: JobRangeForForm,
    key: 'jobRangeForForm',
    name: 'jobRange',
    createJobValues,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'workDayHeader',
    name: 'workDayHeader',
    sx: HeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'workDaySubHeader',
    name: 'workDaySubHeader',
    sx: workFlowSubHeaderStyling,
  },
  {
    ComponentType: FormSelectPillPicker,
    name: 'daysOfWeek',
    required: true,
    validate: doesWorkDayHaveAnyTruthy,
    data: [
      {
        label: 'Su',
        value: 'sunday',
      },
      {
        label: 'Mo',
        value: 'monday',
      },
      {
        label: 'Tu',
        value: 'tuesday',
      },
      {
        label: 'We',
        value: 'wednesday',
      },
      {
        label: 'Th',
        value: 'thursday',
      },
      {
        label: 'Fr',
        value: 'friday',
      },
      {
        label: 'Sa',
        value: 'saturday',
      },
    ],
    sx: { justifyContent: 'center' },
  },
];

const requiredApprovalFields = [
  {
    ComponentType: FormTypographyBox,
    key: 'requireApprovalOptionHeader',
    name: 'requireApprovalOptionHeader',
    sx: HeaderStyling,
    xs: 10,
  },
  {
    ComponentType: FormSwitch,
    name: 'approvalRequired',
    xs: 2,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'requireApprovalOptionSubHeader',
    name: 'requireApprovalOptionSubHeader',
    sx: workFlowSubHeaderStyling,
    xs: 10,
  },
];

export const singleEmployeeSection = (JobCreateShiftGroupToggle, createJobValues) => [
  {
    ComponentType: FormTypographyBox,
    key: 'shiftRequirementsHeader',
    name: 'shiftRequirementsHeader',
    sx: workFlowHeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'shiftRequirementsSubHeader',
    name: 'shiftRequirementsSubHeader',
    sx: workFlowSubHeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'singleEmployeeOptionHeader',
    name: 'singleEmployeeOptionHeader',
    sx: HeaderStyling,
    xs: 10,
  },
  {
    ComponentType: FormSwitch,
    name: 'singleEmployeeShift',
    disabled: !JobCreateShiftGroupToggle,
    xs: 2,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'singleEmployeeOptionSubHeader',
    name: 'singleEmployeeOptionSubHeader',
    sx: workFlowSubHeaderStyling,
    xs: 10,
  },
  ...(get(['positionTemplate', 'allowEmployerApproval'], createJobValues)
    ? requiredApprovalFields
    : []),
];

const OverstaffOpeningsValidator = (value) => {
  // when user does not enter anything.
  // we consider this is a valid input cause Overstaff Openings is not required
  if (value === null || value === undefined || value === '') {
    // if the value is empty this is good consider this is a valid input
    // because this input area accept empty
    return undefined;
  }
  // when user input something, we check this input is an int or >=0
  return composeValidators(isInteger, isGreaterThanOrEqual(0))(value);
};

export const overStaffOpeningsSection = () => [
  {
    ComponentType: FormTypographyBox,
    key: 'overStaffOpeningsSubHeader',
    name: 'overStaffOpeningsSubHeader',
    sx: workFlowSubHeaderStyling,
  },
  {
    ComponentType: FormTextField,
    label: LanguageConverter('jobOrder.overStaff.openings'),
    name: 'numOverstaff',
    fullWidth: true,
    placeholder: LanguageConverter('jobOrder.enter'),
    validate: OverstaffOpeningsValidator,
  },
];

export const numOpeningsSection = (role) => [
  {
    ComponentType: FormTypographyBox,
    key: 'numOpeningHeader',
    name: 'numOpeningHeader',
    sx: workFlowHeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'numOpeningSubHeader',
    name: 'numOpeningSubHeader',
    sx: workFlowSubHeaderStyling,
  },
  {
    ComponentType: FormTextField,
    label: LanguageConverter('jobOrder.openings'),
    name: 'numOpenings',
    validate: composeValidators(isInteger, isGreaterThan(0)),
    required: true,
    fullWidth: true,
  },
  ...(role === UserRole.ADMIN ? overStaffOpeningsSection() : []),
];

export const OverstaffConfirmation = [
  {
    ComponentType: FormTypographyBox,
    key: 'overStaffTitle',
    name: 'overStaffTitle',
    sx: FormHeaderStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'overStaffValue',
    name: 'overStaffValue',
    sx: FormValueStyle,
  },
];

export const confirmationSection = (role) => [
  {
    ComponentType: FormTypographyBox,
    key: 'confirmationPageHeader',
    name: 'confirmationPageHeader',
    sx: workFlowHeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'confirmationPageSubHeader',
    name: 'confirmationPageSubHeader',
    sx: FormValueStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'corporationTitle',
    name: 'corporationTitle',
    sx: FormHeaderStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'corporationValue',
    name: 'corporationValue',
    sx: FormValueStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'positionTitle',
    name: 'positionTitle',
    sx: FormHeaderStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'positionValue',
    name: 'positionValue',
    sx: FormValueStyle,
  },

  {
    ComponentType: FormTypographyBox,
    key: 'corporationLocationTitle',
    name: 'corporationLocationTitle',
    sx: FormHeaderStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'corporationLocationValue',
    name: 'corporationLocationValue',
    sx: FormValueStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'corporationDepartmentTitle',
    name: 'corporationDepartmentTitle',
    sx: FormHeaderStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'corporationDepartmentValue',
    name: 'corporationDepartmentValue',
    sx: FormValueStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'jobDateTitle',
    name: 'jobDateTitle',
    sx: FormHeaderStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'jobDateValue',
    name: 'jobDateValue',
    sx: FormValueStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'workdayLabelValue',
    name: 'workdayLabelValue',
    sx: FormValueStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'shiftTitle',
    name: 'shiftTitle',
    sx: FormHeaderStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'shiftValue',
    name: 'shiftValue',
    sx: FormValueStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'openingsTitle',
    name: 'openingsTitle',
    sx: FormHeaderStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'openingsValue',
    name: 'openingsValue',
    sx: FormValueStyle,
  },
  ...(role === UserRole.ADMIN ? OverstaffConfirmation : []),
  {
    ComponentType: FormTypographyBox,
    key: 'requireApprovalTitle',
    name: 'requireApprovalTitle',
    sx: FormHeaderStyle,
    xs: 10,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'requireApprovalValue',
    name: 'requireApprovalValue',
    sx: FormValueStyle,
    xs: 10,
  },
];
