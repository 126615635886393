import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import Grid from '@mui/material/Grid';

import ListHeader from '../../components/ListHeader';
import selectUser from '../../store/selectors/appSelector';
import { navigationBarHeight } from '../../theme';

import Metrics from './Metrics';
import MetricsFilters from './metricsFilters';

const Reporting = memo(() => {
  const user = useSelector(selectUser);

  return (
    <Grid container spacing={2} marginTop={navigationBarHeight}>
      <ListHeader headerText="Reporting" />
      {user.role !== UserRole.EMPLOYER && <MetricsFilters />}
      <Metrics />
    </Grid>
  );
});

export default Reporting;
