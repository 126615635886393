import React, { useCallback, useState } from 'react';
import findIndex from 'lodash/fp/findIndex';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import Button from '../../../../../components/Button';
import theme from '../../../../../theme';
import { showSnackbar } from '../../../../app';

import BasicDrawer from './BasicDrawer';
import FavoriteListComponent from './FavoriteListComponent';
import SearchBarWithListComponent from './SearchBarWithListComponent';

const InviteEmployeeDrawer = ({
  corporation,
  jobOrderId,
  isOpen,
  onClose,
  handleInviteEmployees,
}) => {
  const favoriteList = get('favorite', corporation);

  const [selectedTerms, setSelectedTerms] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const dispatch = useDispatch();
  const searchBoxFont = ['searchBox', 'font'];
  const sectionHeaderStyle = {
    fontColor: get([...searchBoxFont, 'sectionHeaderFontColor'], theme),
    fontSize: get([...searchBoxFont, 'sectionHeaderFontSize'], theme),
    fontFamily: get([...searchBoxFont, 'sectionHeaderFontFamily'], theme),
    margin: theme.spacing(0, 0, 2, 0),
  };

  const subSectionStyle = {
    fontColor: get([...searchBoxFont, 'subSectionFontColor'], theme),
    fontSize: get([...searchBoxFont, 'sectionHeaderFontSize'], theme),
    fontFamily: get([...searchBoxFont, 'subSectionFontFamily'], theme),
  };

  const header = 'Invite Employees';
  const subHeader =
    ' Assign employees to this job order: Search from the list or select from your favorites.';
  const inviteEmployeeOnClick = () => {
    const combinedOptions = [...favorites, ...selectedTerms.map((opt) => opt._id)];
    if (combinedOptions.length <= 0) {
      dispatch(showSnackbar({ message: 'error.jobOrderInvite.zeroCandidates' }));
      return;
    }
    handleInviteEmployees({
      jobOrder: jobOrderId,
      candidates: combinedOptions,
    });
    onClose();
    setFavorites([]);
    setSelectedTerms([]);
  };

  const handleOverLimitOptionValueValidation = useCallback(
    (searchbarSelection, favoriteSelection) => {
      if (searchbarSelection.length + favoriteSelection.length >= 100) {
        dispatch(showSnackbar({ message: 'error.jobOrderInvite.tooManyCandidates' }));
        return true;
      }
      return false;
    },
    [dispatch]
  );

  const handleSearchBarSelectionClick = useCallback(
    (option) => {
      const foundTerm = findIndex((st) => st._id === option._id, selectedTerms) < 0;
      const newSelectedValue = (foundTerm && [...selectedTerms, option]) || selectedTerms;
      if (handleOverLimitOptionValueValidation(selectedTerms, favorites)) {
        return;
      }

      if (!selectedTerms.some((opt) => opt === option._id)) {
        setSelectedTerms(newSelectedValue);
      }
    },
    [favorites, handleOverLimitOptionValueValidation, selectedTerms, setSelectedTerms]
  );

  const handleSearchbarDeselectionClick = (option) => {
    setSelectedTerms(selectedTerms.filter((opt) => opt._id !== option._id));
  };

  const favoriteOnChange = (favorite) => {
    if (favorites.some((fav) => fav === get('waeID', favorite))) {
      setFavorites(favorites.filter((f) => f !== get('waeID', favorite)));
      return;
    }
    if (handleOverLimitOptionValueValidation(selectedTerms, favorites)) {
      return;
    }
    setFavorites([...favorites, get('waeID', favorite)]);
  };

  const Content = () => (
    <Box
      sx={{
        width: '100%',
        overflowY: 'auto',
        '::-webkit-scrollbar': {
          width: '0.4em',
        },
        '::-webkit-scrollbar-track': {
          background: theme.components.scrollBar.trackColor,
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: theme.components.scrollBar.thumbColor,
          borderRadius: '99px',
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: theme.components.scrollBar.thumbHover,
        },
      }}
    >
      <SearchBarWithListComponent
        background="light"
        preDefinedOptions={[]}
        data={{}}
        handleSelectionClick={handleSearchBarSelectionClick}
        handleDeselectionClick={handleSearchbarDeselectionClick}
        searchBarSelectionLength={selectedTerms.length}
        variant="standard"
        optionLabel="name"
        placeholder="Search employees"
        onSubmitApiCallData={{
          httpMethod: 'POST',
          route: 'users/read',
        }}
        optionDisplayField="name"
        selectedTerms={selectedTerms}
        sx={{ width: '100%' }}
      />

      <Typography sx={{ ...sectionHeaderStyle, margin: theme.spacing(5, 0, 2, 0) }}>
        {`Favorite Employees (${favorites.length})`}
      </Typography>

      <FavoriteListComponent
        favoriteList={favoriteList}
        favorites={favorites}
        onChange={favoriteOnChange}
      />
      <Grid
        container
        item
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        sx={{ margin: theme.spacing(2, 0, 0, 0) }}
      >
        <Typography sx={{ ...subSectionStyle, marginRight: '1px' }}>
          Job Placements: {selectedTerms.length + favorites.length}/100
        </Typography>
      </Grid>
    </Box>
  );

  const ActionButton = () => (
    <Button
      onClick={inviteEmployeeOnClick}
      text={LanguageConverter('button.employees.invite')}
      sx={{
        marginLeft: '5px',
      }}
    />
  );

  return (
    <BasicDrawer
      header={header}
      subHeader={subHeader}
      content={<Content />}
      onClose={onClose}
      isOpen={isOpen}
      actionButton={<ActionButton />}
    />
  );
};

InviteEmployeeDrawer.propTypes = {
  corporation: PropTypes.shape({}),
  jobOrderId: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  handleInviteEmployees: PropTypes.func,
};
export default InviteEmployeeDrawer;
