import React, { useState } from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';

import Button from '../../../../../components/Button';
import theme from '../../../../../theme';

import BasicDrawer from './BasicDrawer';
import FavoriteCard from './FavoriteCard';
import SearchEmployeeBar from './SearchEmployeeBar';

const AddEmployeeDrawer = ({ corporation, jobOrderId, isOpen, onClose, handleAddEmployees }) => {
  const [value, setValue] = useState(null);
  const favoriteList = get('favorite', corporation) || [];
  const header = 'Add Employees';
  const subHeader = ' Select one employee or bulk select multiple employees';
  const addEmployeeClick = () => {
    handleAddEmployees({ fields: { jobOrders: [jobOrderId], candidate: value._id } });
    onClose();
    setValue(null);
  };

  const Content = () => (
    <Grid sx={{ minWidth: 448 }} container item direction="column">
      <SearchEmployeeBar value={value} setValue={(val) => setValue(val)} />
      <Box
        sx={{
          display: 'flex',
          marginTop: '50px',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          sx={{
            fontFamily: theme.components.detailView.typography.sectionHeader.fontFamily,
            fontSize: theme.components.detailView.typography.sectionHeader.fontSize,
            fontColor: theme.components.detailView.palette.sectionBackgroundColor,
          }}
        >
          {' '}
          Favorite Employees
        </Typography>
        <Button disabled text={LanguageConverter('button.favorite.addAll')} />
      </Box>
      <Box
        sx={{
          marginTop: '20px',
          maxHeight: '30vh',
          overflowY: 'auto',
          '::-webkit-scrollbar': {
            width: '0.4em',
          },
          '::-webkit-scrollbar-track': {
            background: theme.components.scrollBar.trackColor,
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: theme.components.scrollBar.thumbColor,
            borderRadius: '99px',
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: theme.components.scrollBar.thumbHover,
          },
        }}
      >
        {favoriteList.map((f) => (
          <ListItem key={f._id} sx={{ padding: '4px' }}>
            <FavoriteCard fav={f} />
          </ListItem>
        ))}
      </Box>
    </Grid>
  );
  const ActionButton = () => (
    <Button
      onClick={addEmployeeClick}
      text={LanguageConverter('buttonText.addEmployees')}
      sx={{
        marginLeft: '5px',
      }}
    />
  );
  return (
    <BasicDrawer
      header={header}
      subHeader={subHeader}
      content={<Content />}
      onClose={onClose}
      isOpen={isOpen}
      actionButton={<ActionButton />}
    />
  );
};

AddEmployeeDrawer.propTypes = {
  corporation: PropTypes.shape({}),
  jobOrderId: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  handleAddEmployees: PropTypes.func,
};
export default AddEmployeeDrawer;
