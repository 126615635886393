import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';

import theme from '../../../../theme';

import PlacementsForm from './SubComponents/PlacementsForm';

const ShiftDetails = ({ initialValues, onUpdate, user }) => {
  const { startDate, endDate, startTime, endTime, shiftName } = initialValues;

  const detailViewTypographySectionHeader = [
    'components',
    'detailView',
    'typography',
    'sectionHeader',
  ];
  const detailViewTypographySubSectionHeader = [
    'components',
    'detailView',
    'typography',
    'subSectionHeader',
  ];
  const detailViewTypographyLocationText = [
    'components',
    'detailView',
    'typography',
    'locationText',
  ];
  const detailViewTypographyShiftName = ['components', 'detailView', 'typography', 'shiftName'];

  return (
    <Box
      sx={{
        width: '100%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
        maxHeight: '80vh',
      }}
    >
      <Box
        color="white"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        {' '}
        <Box
          sx={{
            fontSize: get([...detailViewTypographySectionHeader, 'fontSize'], theme),
            fontFamily: get([...detailViewTypographySectionHeader, 'fontFamily'], theme),
            color: get([...detailViewTypographySectionHeader, 'fontColor'], theme),
          }}
        >
          Shift Duration
        </Box>
        <Box
          sx={{
            marginTop: '12px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
                fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
                color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
              }}
            >
              Start Date{' '}
            </Typography>{' '}
            <Typography>{startDate} </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
                fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
                color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
              }}
            >
              End Date{' '}
            </Typography>
            {endDate}{' '}
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: '12px',
            fontSize: get([...detailViewTypographySectionHeader, 'fontSize'], theme),
            fontFamily: get([...detailViewTypographySectionHeader, 'fontFamily'], theme),
            color: get([...detailViewTypographySectionHeader, 'fontColor'], theme),
          }}
        >
          Shift Details
        </Box>
        <Box
          sx={{
            marginTop: '12px',
            display: 'flex',
            padding: '12px 16px',
            justifyContent: 'space-between',
            flexDirection: 'column',
            width: '100%',
            borderRadius: '16px',
            backgroundColor: `${theme.components.detailView.palette.sectionBackgroundColor}`,
          }}
        >
          <Box
            sx={{
              color: get([...detailViewTypographyShiftName, 'fontColor'], theme),
              fontFamily: get([...detailViewTypographyShiftName, 'fontFamily'], theme),
              fontSize: get([...detailViewTypographyShiftName, 'fontSize'], theme),
            }}
          >
            {shiftName}
          </Box>
          <Box
            sx={{
              fontSize: get([...detailViewTypographyLocationText, 'fontSize'], theme),
              fontFamily: get([...detailViewTypographyLocationText, 'fontFamily'], theme),
              color: get([...detailViewTypographyLocationText, 'fontColor'], theme),
            }}
          >
            {startTime} ~ {endTime}{' '}
          </Box>
        </Box>
      </Box>
      <PlacementsForm initialValues={initialValues} onUpdate={onUpdate} user={user} />
    </Box>
  );
};
ShiftDetails.propTypes = {
  initialValues: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    shiftName: PropTypes.string,
  }),
  onUpdate: PropTypes.func,
  user: PropTypes.shape({}),
};

export default ShiftDetails;
