import React, { useState } from 'react';
import dayjs from 'dayjs';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Typography } from '@mui/material';
import { DateRangePickerDay, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';

import FormDatePickerField from '../../../../../components/Form/FormDatePickerField';
import { buildFormField } from '../../../../../components/Form/formFieldFactory';
import {
  composeValidators,
  isDateAndTimeEqualOrGreaterThanNow,
  isDateEqualOrGreaterThanToday,
  isValidDate,
} from '../../../../../components/Form/validations';
import selectUser from '../../../../../store/selectors/appSelector';
import theme from '../../../../../theme/theme';
import { reformatDateMMMMDDYYYY } from '../../../../../utils';

const WhenFieldChanges = ({ field, set }) => (
  <Field name={set} subscription={{}}>
    {(
      // No subscription. We only use Field to get to the change function
      { input: { onChange } }
    ) => (
      <OnChange name={field}>
        {(value) => {
          onChange(set === 'startDate' ? value[0].toDate() : value[1].toDate());
        }}
      </OnChange>
    )}
  </Field>
);

WhenFieldChanges.propTypes = {
  field: PropTypes.shape({}),
  set: PropTypes.shape({}),
};

const JobRangeForForm = ({ createJobValues, input }) => {
  const intl = useIntl();
  const user = useSelector(selectUser);

  const tomorrow = dayjs().add(1, 'day');
  const dayIn7Days = tomorrow.add(7, 'day');
  const defaultExtraDay =
    createJobValues.shift.start > createJobValues.shift.end ? dayIn7Days.add(1, 'day') : null;

  const [values, setValues] = useState([tomorrow, dayIn7Days]);
  const [extraDay, setExtraDay] = useState(defaultExtraDay);

  const backgroundColor = get(['jobRangePicker', 'bgColor', 'light'], theme);
  const warningColor = get(['jobRangePicker', 'warning', 'backgroundColor'], theme);
  const borderRadius = get(['jobRangePicker', 'borderRadius'], theme);
  const fontFamily = get(['jobRangePicker', 'font', 'defaultFont'], theme);
  const dateFontColor = get(['jobRangePicker', 'font', 'date', 'fontColor'], theme);
  const dayFontSize = get(['jobRangePicker', 'font', 'date', 'fontSize'], theme);
  const dateFontSize = get(['jobRangePicker', 'font', 'day', 'fontSize'], theme);
  const dateBackgroundColor = get(['jobRangePicker', 'dateBgColor', 'light'], theme);
  const dayFontColor = get(['jobRangePicker', 'font', 'day', 'fontColor'], theme);
  const selectedBackgroundColor = get(
    ['jobRangePicker', 'selected', 'backgroundColor', 'light'],
    theme
  );
  const headerFontSize = get(['jobRangePicker', 'font', 'headerFontSize'], theme);
  const headerFontFamily = get(['jobRangePicker', 'font', 'headerFontSize'], theme);
  const iconButtonColor = get(['jobRangePicker', 'iconButton', 'light', 'color'], theme);

  const extraDayHeaderSx = {
    fontSize: get(['jobRangePicker', 'extraDay', 'typography', 'fontSize'], theme),
    fontFamily: get(['jobRangePicker', 'extraDay', 'typography', 'boldFont'], theme),
    color: get(['jobRangePicker', 'extraDay', 'typography', 'fontColor'], theme),
  };
  const extraDayBodySx = {
    fontSize: get(['jobRangePicker', 'extraDay', 'typography', 'fontSize'], theme),
    fontFamily: get(['jobRangePicker', 'extraDay', 'typography', 'font'], theme),
    color: get(['jobRangePicker', 'extraDay', 'typography', 'fontColor'], theme),
  };

  const styledCalenderSx = {
    '& .MuiPickerStaticWrapper-content': {
      backgroundColor,
      borderRadius,
    },
    '& .MuiDayPicker-slideTransition': {
      width: '100%',
      minHeight: '280px',
    },
    '& .MuiPickersDay-dayWithMargin': {
      color: dateFontColor,
      backgroundColor: dateBackgroundColor,
      fontFamily,
      fontSize: dateFontSize,
    },
    '& .MuiPickersDay-root.Mui-selected': {
      backgroundColor: selectedBackgroundColor,
      '&:hover': {
        backgroundColor: selectedBackgroundColor,
      },
      '&:focus': {
        backgroundColor: selectedBackgroundColor,
      },
    },
    '& .MuiDayPicker-weekDayLabel': {
      color: dayFontColor,
      backgroundColor: dateBackgroundColor,
      fontFamily,
      fontSize: dayFontSize,
    },
    '& .MuiPickersCalendarHeader-switchViewIcon': {
      display: 'none',
    },
    '& .MuiPickersCalendarHeader-switchViewButton': {
      display: 'none',
    },
    '& .MuiPickersCalendarHeader-labelContainer': {
      fontFamily: headerFontFamily,
      fontSize: headerFontSize,
    },
    '& .MuiPickersArrowSwitcher-root': {
      display: 'flex',
    },
    '& .MuiPickersArrowSwitcher-button': {
      color: iconButtonColor,
    },
    '& .MuiPaper-root': {
      boxShadow: 0,
    },
  };

  const onDatePick = (eventValue) => {
    if (eventValue[0] && eventValue[0] !== null && eventValue[1] && eventValue[1] !== null) {
      setValues([dayjs(eventValue[0]), dayjs(eventValue[1])]);
      if (createJobValues.shift.start > createJobValues.shift.end) {
        setExtraDay(dayjs(eventValue[1]).add(1, 'day'));
      } else {
        setExtraDay(null);
      }
      input.onChange([dayjs(eventValue[0]), dayjs(eventValue[1])]);
    }
  };

  const datePickerSx = {
    width: '47%',
    borderRadius: '99999px',
    '& .MuiIconButton-root': {
      color: iconButtonColor,
    },
    '& .MuiInputBase-inputAdornedEnd': {
      paddingRight: 0,
    },
  };

  const extraDayBoxSx = {
    padding: theme.spacing(1.5),
    backgroundColor: warningColor,
    borderRadius: theme.spacing(3),
    margin: theme.spacing(2, 0, 2, 0),
  };

  const validators =
    user.role === UserRole.ADMIN
      ? composeValidators(isValidDate)
      : composeValidators(
          isValidDate,
          isDateEqualOrGreaterThanToday,
          isDateAndTimeEqualOrGreaterThanNow(
            get(['shift', 'start'], createJobValues),
            intl.formatMessage({ id: 'error.jobCreate.StartDateAndTime.invalid' })
          )
        );

  const generateFieldTemplate = ({ isStartField, value }) => ({
    ComponentType: FormDatePickerField,
    value,
    minDate: new Date(),
    name: isStartField ? 'startDate' : 'endDate',
    readOnlyFormat: (val) => reformatDateMMMMDDYYYY(val),
    required: true,
    validate: validators,
    xs: 6,
    readOnly: true,
    sx: datePickerSx,
  });

  return (
    <Box>
      <WhenFieldChanges field="jobRange" set="startDate" />
      <WhenFieldChanges field="jobRange" set="endDate" />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexWrap: 'nowrap',
          marginBottom: theme.spacing(2),
          justifyContent: 'space-between',
        }}
      >
        {buildFormField(generateFieldTemplate({ isStartField: true, value: values[0] }))}
        {buildFormField(generateFieldTemplate({ isStartField: false, value: values[1] }))}
      </Box>

      <Box sx={styledCalenderSx}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDateRangePicker
            disablePast={user.role !== UserRole.ADMIN}
            showActionBar={false}
            showToolbar={false}
            sx={styledCalenderSx}
            value={values}
            onChange={onDatePick}
            componentsProps={{
              actionBar: { actions: [], hidden: true },
              sx: styledCalenderSx,
            }}
            renderDay={(day, selectedDate) => {
              const sx =
                extraDay && extraDay.isSame(day, 'day') ? { backgroundColor: warningColor } : null;

              return <DateRangePickerDay day={day} {...selectedDate} sx={sx} />;
            }}
          />
          {extraDay && (
            <Box sx={extraDayBoxSx}>
              <Typography sx={extraDayHeaderSx}>Why am I seeing an extra day?</Typography>
              <Typography sx={extraDayBodySx}>
                The calendar will display an additional day to account for an overnight shift.
              </Typography>
            </Box>
          )}
        </LocalizationProvider>
      </Box>
    </Box>
  );
};
JobRangeForForm.propTypes = {
  createJobValues: PropTypes.shape({
    shift: PropTypes.shape({
      start: PropTypes.number,
      end: PropTypes.number,
    }),
  }),
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }),
};
export default JobRangeForForm;
