import React from 'react';
import PropTypes from 'prop-types';

import FavoriteIcon from '@mui/icons-material/Favorite';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import theme from '../../../../../theme';

const styleForHeart = {
  marginRight: theme.spacing(1),
  color: `${theme.components.detailView.palette.lightHeartColor}`,
};

const FavoriteCard = ({ fav, backgroundColor }) => {
  const styleForAccordian = {
    backgroundColor: backgroundColor || `${theme.palette.neutral.contrastText}`,
    borderRadius: '16px',
    padding: theme.spacing(1, 0, 0, 0),
    justifyContent: 'space-between',
    margin: 0,
    boxShadow: 0,
    width: '100%',
  };

  return (
    <Box sx={styleForAccordian} square>
      <Box sx={{ padding: theme.spacing(0, 2) }}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex' }}>
            <FavoriteIcon sx={styleForHeart} />

            <Typography
              sx={{
                color: theme.components.detailView.palette.employeesNameColor,
                fontFamily: theme.components.detailView.typography.locationText.fontFamily,
              }}
            >
              {fav.name}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

FavoriteCard.propTypes = {
  fav: PropTypes.shape({
    name: PropTypes.string,
  }),
  backgroundColor: PropTypes.string,
};

export default FavoriteCard;
