import React, { memo } from 'react';
import { get } from 'lodash/fp';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';

import {
  selectLoadingUserCount,
  selectUsersMetrics,
} from '../../../store/selectors/reportingSelectors';
import theme from '../../../theme';
import { ContentLabelCard } from '../metricsCards';
import MetricsHeader from '../metricsHeader';

const roleColorPath = ['views', 'reporting', 'palette', 'role'];

const UserMetrics = memo(() => {
  const { recruiterCount, adminCount, candidateCount, employerCount } =
    useSelector(selectUsersMetrics);

  const loadingUserCount = useSelector(selectLoadingUserCount);
  return (
    <Grid container item spacing={2} paddingBottom="16px">
      <MetricsHeader item text="Users" />

      <>
        {recruiterCount !== null && (
          <ContentLabelCard
            sx={{ height: '104px' }}
            data={recruiterCount}
            loading={loadingUserCount}
            staticData={{
              color: get([...roleColorPath, 'recruiter', 'secondary'], theme),
              label: 'Total Recruiters',
            }}
          />
        )}
        {adminCount !== null && (
          <ContentLabelCard
            sx={{ height: '104px' }}
            data={adminCount}
            loading={loadingUserCount}
            staticData={{
              color: get([...roleColorPath, 'admin', 'secondary'], theme),
              label: 'Total Admin',
            }}
          />
        )}
        {candidateCount !== null && (
          <ContentLabelCard
            sx={{ height: '104px' }}
            data={candidateCount}
            loading={loadingUserCount}
            staticData={{
              color: get([...roleColorPath, 'default', 'secondary'], theme),
              label: 'Total Candidate',
            }}
          />
        )}
        {employerCount !== null && (
          <ContentLabelCard
            sx={{ height: '104px' }}
            data={employerCount}
            loading={loadingUserCount}
            staticData={{
              color: get([...roleColorPath, 'customer', 'secondary'], theme),
              label: 'Total Employer',
            }}
          />
        )}
      </>
    </Grid>
  );
});

export default UserMetrics;
