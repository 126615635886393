import React from 'react';
import PropTypes from 'prop-types';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import DatePickerField from '../DatePicker/DatePickerField';

import { errorHandler } from './formUtils';

const FormDatePickerField = ({
  fieldErrorData,
  fullWidth,
  input,
  label,
  meta,
  minDate,
  readOnly,
  renderInput,
  required,
  sx,
  variant,
}) => {
  const error = errorHandler(input, meta, fieldErrorData);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePickerField
        desktopModeMediaQuery="@media (min-width: 900px)" // TODO: HB-952 discuss min-width
        error={!!error}
        fullWidth={fullWidth}
        helperText={error || null}
        label={label}
        minDate={minDate}
        name={input.name}
        onChange={input.onChange}
        components={{
          RightArrowIcon: ArrowForwardIcon,
          LeftArrowIcon: ArrowBackIcon,
        }}
        readOnly={readOnly}
        renderInput={renderInput}
        required={required}
        sx={sx}
        variant={variant}
        value={input.value}
        views={['year', 'month', 'day']}
      />
    </LocalizationProvider>
  );
};
FormDatePickerField.propTypes = {
  fieldErrorData: PropTypes.string,
  fullWidth: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }),
  label: PropTypes.string,
  meta: PropTypes.shape(),
  minDate: PropTypes.instanceOf(Date),
  readOnly: PropTypes.bool,
  renderInput: PropTypes.func,
  required: PropTypes.bool,
  sx: PropTypes.shape({}),
  variant: PropTypes.string,
  value: PropTypes.instanceOf(Date),
};
export default FormDatePickerField;
